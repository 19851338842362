import type { Store } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';

import type { ActionType } from './Actions';
import { reducers } from './reducers';
import type {
    AppMetaState,
    AuthenticationState,
    ConfigurationState,
    FeatureflagState,
} from './states';

export interface AppStore {
    auth: AuthenticationState;
    config: ConfigurationState;
    authZ: AppMetaState;
    featureManagement: FeatureflagState;
}

const store: Store<AppStore, ActionType> = process.env.NODE_ENV === 'development'
    ? configureStore({
        reducer: reducers,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    })
    : configureStore({ reducer: reducers });

export { store };

export default store;
