import React from 'react';

import UserManagementComponent from './Base';

const TenantLevel: React.FC = () => (
    <UserManagementComponent
        level="TENANT"
    />
);

export default TenantLevel;
