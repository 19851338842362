import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React from 'react';

const AccentRoot = styled('span')(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForegroundHigh,
}));

interface AccentProps {
    children: React.ReactNode;
}

export const Accent: React.FC<AccentProps> = ({ children }) =>
    <AccentRoot>
        {children}
    </AccentRoot>;
