import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    styled,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { WithVisibility } from '../../../components/WithVisibility';

const HeaderBox = styled(Box)(({ theme }) => ({
    margin: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
}));

const TaskText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
}));

const TaskList = styled(List)(({ theme }) => ({ margin: theme.spacing(1) }));

interface TaskListItemProps {
    label: any;
    option: string;
    handleOpen?: () => void;
    handleDelete?: () => void;
    isTabEditable: boolean;
}

const TaskListItems: React.FC<TaskListItemProps> = ({
    label, option, handleOpen, handleDelete, isTabEditable,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <TaskList
                data-testid='task-list-items'>
                <HeaderBox
                    key={uuid()}>
                    <TaskText>
                        {`${label['@_name']}`}
                    </TaskText>
                    <WithVisibility visible={isTabEditable}>
                        <Box>
                            <IconButton
                                data-testid='delete-task-btn'
                                color="primary"
                                aria-label={t('a11y_delete_task')}
                                component="span"
                                onClick={handleDelete}>
                                <DeleteIcon color='primary' />
                            </IconButton>
                            <IconButton
                                data-testid='edit-task-btn'
                                color="primary"
                                aria-label={t('a11y_edit_task')}
                                component="span"
                                onClick={handleOpen}>
                                <EditIcon color='primary' />
                            </IconButton>
                        </Box>
                    </WithVisibility>
                </HeaderBox>

                {label[`${option}`]?.map((singleLabel: any) => (
                    <ListItem
                        key={uuid()}
                        data-testid='attr-list-item'>
                        <ListItemText >
                            <Typography>
                                {singleLabel['@_value']}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </TaskList>
        </>
    );
};

export default TaskListItems;
