import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
    IconButton,
    styled,
} from '@mui/material';
import {
    MaterialDesignContent,
    SnackbarProvider,
} from 'notistack';
import React, {
    useCallback,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

const notificationDuration = {
    NOTIFICATION_DURATION: 3000,
    TRANSITION_DURATION: 500,
};

const SnackBarProviderContent = styled(MaterialDesignContent)(({ theme }) => ({
    '&.notistack-MuiContent': {
        color: `${theme.palette.semantic.colorForegroundInverse} !important`,
        backgroundColor: `${theme.palette.semantic.colorBackgroundInverse} !important`,
        maxWidth: '600px',
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.semantic.colorIconInvertedDefault,
    '&:hover': {
        color: `${theme.palette.semantic.colorForeground} !important`,
        background: `${theme.palette.semantic.colorBackgroundSelected} !important`,
    },
}));

const IconWrapper = styled('div')({
    paddingRight: '5px',
    paddingTop: '2px',
});

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({ color: theme.palette.semantic.colorInfoForegroundInverse }));
const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({ color: theme.palette.semantic.colorErrorForegroundInverse }));
const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({ color: theme.palette.semantic.colorWarningForegroundInverse }));
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({ color: theme.palette.semantic.colorSuccessForegroundInverse }));

const ToastNotificationProvider = (props: any) => {
    const { t } = useTranslation();
    const notistackRef = useRef<any>();

    const onClickDismiss = useCallback(
        (key: any) => () => {
            notistackRef.current.closeSnackbar(key);
        },
        [ notistackRef ],
    );

    const renderSnackbarAction = useCallback((key: any) => (
        <CloseButton
            onClick={onClickDismiss(key)}
            aria-label={t('close_notification')}
        >
            <CloseIcon />
        </CloseButton>
    ), [ onClickDismiss, t ]);

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={5}
            Components={{
                default: SnackBarProviderContent,
                error: SnackBarProviderContent,
                info: SnackBarProviderContent,
                success: SnackBarProviderContent,
                warning: SnackBarProviderContent,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            autoHideDuration={notificationDuration.NOTIFICATION_DURATION}
            iconVariant={{
                default: (
                    <IconWrapper>
                        <StyledInfoIcon />
                    </IconWrapper>
                ),
                success: (
                    <IconWrapper>
                        <SuccessIcon />
                    </IconWrapper>
                ),
                error: (
                    <IconWrapper>
                        <StyledErrorIcon />
                    </IconWrapper>
                ),
                warning: (
                    <IconWrapper>
                        <StyledWarningIcon />
                    </IconWrapper>
                ),
            }}
            action={renderSnackbarAction}
            transitionDuration={{ enter: notificationDuration.TRANSITION_DURATION }}
        >
            {props.children}
        </SnackbarProvider>
    );
};

export default ToastNotificationProvider;
