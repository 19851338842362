import type { Theme } from '@mui/material/styles';
import {
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import {
    apolloMaterialUiThemeDark,
    apolloMaterialUiThemeLight,
    apolloMaterialUiThemeLightHC,
} from '@uipath/apollo-mui5';
import * as React from 'react';
import { connect } from 'react-redux';

import { getBrowserTheme } from '../../utils/themeUtils';
import { RouterHost } from '../routeHost/RouterHost';

interface ThemeChooser {
    [k: string]: Theme;
}

const themeChooser: ThemeChooser = {
    dark: apolloMaterialUiThemeDark,
    light: apolloMaterialUiThemeLight,
    'light-hc': apolloMaterialUiThemeLightHC,
};

themeChooser.autoTheme = themeChooser[getBrowserTheme()];

interface ThemeFCProps {
    theme: string;
}

const ThemeHostFC: React.FC<ThemeFCProps> = ({ theme }) => {
    const matTheme = themeChooser[theme] || themeChooser.light;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={matTheme}>
                <RouterHost />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ThemeHost = connect((state: any) => ({ theme: state.config.theme || 'autoTheme' }))(ThemeHostFC);
