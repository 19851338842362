export enum Scope {
    Core = 'CORE',
    Http = 'HTTP',
    Projects = 'PRJ',
}

export enum Service {
    JS = 'JS',
    CLOUDFLARE = 'CLOUDFLARE',
    HELPER = 'HLPR',
    IDENTITY = 'IDP',
    DEPLOYER = 'DPLYR',
    PKGMANAGER = 'PKGMGR',
    TRAINER = 'TRNR',
}

export enum Origin {
    BOOTSTRAPROVIDER = '0',
    PROJECTLIST = '1',
    AUTHENTICATION = '2',
    AUTHENTICATION_MISMATCH = '3',
    HTTP_INTERCEPTOR = '3',
    TABLE = '4',
}
