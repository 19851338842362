import {
    Button,
    CircularProgress,
    DialogActions,
    DialogTitle as MuiDialogTitle,
    FormControl,
    InputLabel,
    Select,
    styled,
    TableCell,
    TableRow,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';

import AttributeTextField from './AttributeTextField';

// Dialog Title
export const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
    padding: '36px 0  0 24px',
    width: '440px',
    maxHeight: '72px',
    overflow: 'hidden',

    '&.MuiTypography-h6': {
        fontFamily: Tokens.FontFamily.FontNormal,
        fontSize: Tokens.FontFamily.FontHeader4Size,
        lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
        fontStyle: 'normal',
        fontWeight: 600,
        alignItems: 'center',
        maxWidth: '95%',
        color: theme.palette.semantic.colorForeground,
    },

    '& .closeButton': {
        position: 'absolute',
        right: theme.spacing(1),
        marginTop: '0px',
        top: theme.spacing(1),
        color: theme.palette.semantic.colorForeground,
    },
}));

// Dialog Action Button
export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    '& .MuiButton-root': { minWidth: '88px' },

    '& .MuiButton-containedSecondary': {
        fontFamily: Tokens.FontFamily.FontNormal,
        color: theme.palette.primary.contrastText,
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        fontStyle: 'normal',
        fontWeight: '600',
        alignItems: 'center',
        textalign: 'center',

        '& .container': {
            display: 'flex',
            alignItems: 'center',
        },

        '& .primary-button-and-loading-bar-container': {
            position: 'relative',

            '& .MuiCircularProgress-colorPrimary': { color: theme.palette.semantic.colorForegroundLink },
        },
    },
}));

export const StyledCircularProgressButton = styled(CircularProgress)({
    color: '#0000ff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
});

// Send to Labellers Dialog
export const SendToLabellersDialogForm = styled('form')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',

    '& .inner-form': {
        '& .formTitle': {
            margin: '0px',
            fontFamily: Tokens.FontFamily.FontNormal,
            fontSize: Tokens.FontFamily.FontHeader4Size,
            lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },

        '& .MuiFormHelperText-root.Mui-error': { color: theme.palette.semantic.colorErrorText },

        '& .makeStyles-tsErrorMessage': { color: theme.palette.semantic.colorErrorText },

        '& .tsErrorMessage': { color: theme.palette.semantic.colorErrorText },
    },
}));

export const SendToLabellersDialogFooterButtons = styled('div')({
    flexGrow: 0,
    position: 'relative',
    marginBottom: 'auto',

    '& div': {
        height: '70px',
        lineHeight: '70px',
        float: 'right',
    },

    '& .group': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '24px',
    },

    '& .buttons': { flexGrow: 0 },
});

// Running Logs Screen
export const RunningLogsScreenRoot = styled('div')(({ theme }) => ({
    maxWidth: '846px',

    '& .data': {
        backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        paddingLeft: '10px',
        maxWidth: '757px',
        minWidth: '420px',
        height: 'auto',
        overflowY: 'auto',

        '& .error': { color: theme.palette.semantic.colorErrorText },
    },
}));

// General Dialog
export const DialogPopupContent = styled('div')(({ theme }) => ({
    fontWeight: 300,
    width: '456px',
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForeground,

    '& .info': { margin: 0 },
    '& .warning': { margin: 0 },
    '& .confirm': { margin: 0 },

    '& .dialogText': {
        color: theme.palette.semantic.colorForeground,
        marginBlockStart: 0,
        marginBlockEnd: '10px',
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        fontStyle: 'normal',
        fontWeight: 'normal',
    },
}));

export const SkillUpdateContainer = styled('div')(({ theme }) => ({
    margin: Tokens.Spacing.SpacingS,
    fontWeight: 600,
    fontSize: Tokens.FontFamily.FontMSize,
    fontStyle: 'normal',
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForeground,

    '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
        marginTop: '10px',
    },

    '& .detail': {
        width: '100%',
        marginTop: '15px',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'row',

        '& .header': {
            width: '30%',
            lineHeight: Tokens.FontFamily.FontMLineHeight,
        },

        '& .publicSkillHeader': {
            width: '40%',
            lineHeight: Tokens.FontFamily.FontMLineHeight,
        },

        '& .value': {
            lineHeight: Tokens.FontFamily.FontLLineHeight,
            marginLeft: '20px',
            color: theme.palette.semantic.colorForeground,
        },

        '& p': {
            margin: 0,
            fontWeight: 300,
        },

        'replicaError': {
            '& p': {
                color: '#a6040a',
                fontWeight: 400,
                fontSize: '0.875rem',
            },
        },
    },
}));

export const StyledMLPackageVersionDetailsDialog = styled('div')(({ theme }) => ({
    fontWeight: Tokens.FontFamily.FontWeightSemibold,
    fontSize: Tokens.FontFamily.FontMSize,
    fontStyle: 'normal',
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForeground,

    '& .staticContent': {

        '& p': {
            display: 'flex',
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
        },

        '& span': {
            margin: '0 10px',
            fontWeight: Tokens.FontFamily.FontWeightLight,
        },
    },

    '& .dynamicContent': {
        width: '100%',

        '& h4': {
            marginBottom: '0px',
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
        },

        '& p': {
            marginTop: '0px',
            fontWeight: Tokens.FontFamily.FontWeightLight,
            fontStyle: 'normal',
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            fontSize: Tokens.FontFamily.FontMSize,
        },
    },
}));

export const StyledMLPackageVersionPublicUpdateContainer = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: Tokens.FontFamily.FontMSize,
    fontStyle: 'normal',
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForeground,

    '& .detail': {
        width: '100%',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'row',

        '& .header': {
            width: '60%',
            lineHeight: Tokens.FontFamily.FontMLineHeight,
        },

        '& p': {
            margin: 0,
            fontWeight: 300,
        },
    },
}));

export const StyledTooltipPopup = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForeground,

    '& .description': {
        marginTop: '15px',

        '& p': {
            margin: 0,
            fontWeight: 300,
        },
    },

    '& .inputType': {
        marginLeft: '5px',
        fontWeight: 300,
    },

    '& .MuiSvgIcon-root': {
        fontSize: Tokens.FontFamily.FontMSize,
        padding: 0,
    },

    '& .icons-label-span': {
        width: '150px',
        display: 'inline-block',
    },

    '& .spaceBefore': { marginBottom: '15px' },

    '& .spaceAfter': { marginBottom: '20px' },
}));

// Task Editor Styles
export const StyledTaskEditorAttributeHeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

export const StyledDropDownFormControl = styled(FormControl)<{ isClassificationInputControl: boolean }>(({
    theme, isClassificationInputControl,
}) => ({
    ...(isClassificationInputControl ? {
        minWidth: '25%',
        marginRight: theme.spacing(1),
    } : {
        minWidth: '50%',
        marginRight: theme.spacing(1),
    }),
}));

export const StyledClassificationInputTypeDropdownInputLabel = styled(InputLabel)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    fontSize: '14px',
}));

export const AddAttributeButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),

    '& .icon-cell': { minHeight: theme.spacing(8) },
}));

export const StyledAttributeTextField = styled(AttributeTextField)<{ isClassificationAttribute: boolean }>(({
    theme, isClassificationAttribute,
}) => ({
    ...(isClassificationAttribute ? {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minHeight: theme.spacing(8),
    } : {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        minHeight: theme.spacing(8),
    }),
}));

export const StyledEntityColorDropDownFormControl = styled(FormControl)(({ theme }) => ({
    minWidth: theme.spacing(16),
    marginRight: theme.spacing(1),
    height: theme.spacing(8),
}));

export const TaskEditorFooterButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',

    '& .footer-button': { margin: theme.spacing(1) },
}));

// Environment Variables Popup
export const StyledEnvironmentVariablesAddButton = styled(Button)({
    textTransform: 'none',
    fontSize: Tokens.FontFamily.FontMSize,
    fontWeight: Tokens.FontFamily.FontWeightDefault,
    margin: 0,
    marginTop: '7px',
});

export const StyledEnvironmentVariablesFooterButton = styled(Button)(({ theme }) => ({ margin: theme.spacing(1) }));

// Table Row Styles
export const StyledTableRow = styled(TableRow)({
    '& .MuiTableCell-root': {
        verticalAlign: 'top',

        '& .MuiTextField-root': { height: '56px' },

        '&.header': { width: '12rem' },

        '&.isEditableRow': {
            '& .MuiTextField-root': { height: 'initial' },

            '&.leftAlign': { paddingLeft: '28px' },

            '& .leftAlignRevert': { marginLeft: '-16px' },
        },

        '&.value': { width: '12rem' },
    },
});

export const StyledRightAlignedTableCell = styled(TableCell)({
    textAlign: 'right',
    verticalAlign: 'middle !important',
    padding: '0',

    '& button': { '&.verticalFix': { marginBottom: '1.8rem' } },
});

export const StyledSelect = styled(Select)({ width: '100%' });
