import {
    CircularProgress,
    Grid,
    styled,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { ReactElement } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledGrid = styled(Grid)({
    width: '300px',
    height: '250px',
    textAlign: 'center',

    '& .item': {
        width: '150px',
        height: '175px',
    },
});

const ItemsContainer = styled('div')({ transform: 'translateX(0)' });

const CircularProgressTop = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.secondary.main,
    opacity: 0.1,
    position: 'fixed',
    left: 0,
}));

const CircularProgressBottom = styled(CircularProgress)({
    position: 'fixed',
    left: 0,
});

const CircleTextGrid = styled(Grid)(({ theme }) => ({
    position: 'fixed',
    width: '150px',
    height: '150px',

    '& .inner-number': {
        color: theme.palette.secondary.main,
        fontSize: Tokens.FontFamily.FontHeader3Size,
    },

    '& .inner-text': { color: theme.palette.grey[500] },
}));

interface GaugeProps {
    used?: number;
    allowed?: number;
    descriptionKey: string;
}

export default function Gauge(props: GaugeProps): ReactElement {
    const { t } = useTranslation();

    return (
        <StyledGrid
            container
            direction="column"
            justifyContent='center'
            alignItems='center'
        >
            <Grid
                item
                className="item">
                <ItemsContainer>
                    <CircularProgressTop
                        variant="determinate"
                        value={100}
                        size="150px"
                    />
                    <CircularProgressBottom
                        variant="determinate"
                        value={(props.used && props.allowed) ? props.used * 100 / props.allowed : 0}
                        size="150px"
                        color="secondary"
                    />
                    <CircleTextGrid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid item>
                            <span className="inner-number">
                                {props.used ? props.used : 0}
                            </span>
                        </Grid>
                        <Grid item>
                            <span className="inner-text">
                                {`${t('licenses_label_of')} ${props.allowed ? props.allowed : 0}`}
                            </span>
                        </Grid>
                    </CircleTextGrid>
                </ItemsContainer>
            </Grid>
            <Grid item>
                {t(props.descriptionKey, {
                    a: props.used ? props.used : 0,
                    b: props.allowed ? props.allowed : 0,
                })}
            </Grid>
        </StyledGrid>
    );
}
