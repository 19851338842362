import { LOCALSTORAGE_SHARED_KEY_TENANT_ID } from '../../constants/AiappConstants';
import {
    AppMetaActions,
    ConfigurationActions,
} from '../Actions';
import { store } from '../store';

export interface MLSkillInactivePeriodThreshold {
    tenantType: string;
    inactivePeriodThresholds: InactivePeriodThreshold[];
    defaultThreshold: number;
}

interface InactivePeriodThreshold {
    name: string;
    value: number;
}

export const UpdateAccountCreds = (accountId: string, tenantId: string, groupIds: Set<string>) => {
    localStorage.setItem(LOCALSTORAGE_SHARED_KEY_TENANT_ID, tenantId);
    store.dispatch({
        type: AppMetaActions.ACCOUNTCREDS_UPDATE,
        payload: {
            accountId,
            tenantId,
            groupIds,
        },
    });
};

export const permissionsFailed = (status: string, backendCode: string) => {
    store.dispatch({
        type: AppMetaActions.FAIL,
        payload: {
            status,
            backendCode,
        },
    });
};

export const markPublic = () => {
    store.dispatch({
        type: ConfigurationActions.MARK_PUBLIC,
        payload: null,
    });
};

export const updateTenantType = (params: MLSkillInactivePeriodThreshold): void => {
    store.dispatch({
        type: ConfigurationActions.UPDATE_TENANT_TYPE,
        payload: {
            tenantType: params.tenantType,
            inactivePeriodThresholds: params.inactivePeriodThresholds,
            defaultThreshold: params.defaultThreshold,
        },
    });
};

export const setTenantMigrated = (isTenantMigrated: boolean) => {
    store.dispatch({
        type: ConfigurationActions.UPDATE_TENANT_MIGRATION_STATUS,
        payload: { isTenantMigrated },
    });
};
