import React from 'react';
import { connect } from 'react-redux';

import { WithVisibility } from '../../components/WithVisibility';
import ProjectListLegacy from './ProjectListLegacy';
import ProjectListRBAC from './ProjectListRBAC';

const ProjectList: React.FC<{ isTenantMigrated: boolean }> = ({ isTenantMigrated }) => (
    <>
        <WithVisibility visible={isTenantMigrated === true} >
            <ProjectListRBAC />
        </WithVisibility>
        <WithVisibility visible={isTenantMigrated === false} >
            <ProjectListLegacy />
        </WithVisibility>
    </>
);

export default connect((state: any) => ({ isTenantMigrated: state.config.isTenantMigrated }))(ProjectList);
