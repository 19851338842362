export enum UserActions {
    INITIATED = 'LOGIN_TASK_INITIATED',
    LOGGEDIN = 'LOGGEDIN',
    AUTHTOKEN = 'AUTHTOKEN',
    LOGGEDOUT = 'LOGGEDOUT',
    USERDATA_UPDATE = 'USERDATA_UPDATE',
    LOGIN_INITIATED = 'LOGIN_INITIATED',
    LOGOUT_INITIATED = 'LOGOUT_INITIATED',
}

export enum AppMetaActions {
    EMPTY = 'PERMISSIONS_EMPTY',
    INITIATED = 'PERMISSIONS_FETCH_INITIATED ',
    LOADED = 'PERMISSIONS_LOADED',
    ACCOUNTCREDS_UPDATE = 'ACCOUNTCREDS_UPDATE',
    FAIL = 'PERMISSIONS_FAILED',
}

export enum ConfigurationActions {
    EMPTY = 'CONFIGURATION EMPTY',
    INITIATED = 'CONFIGURATION_FETCH_INITIATED',
    LOADED = 'CONFIGURATION_LOADED',
    APPREADY = 'APP_READY',
    NETWORK_ISSUE = 'NETWORK_ISSUE',
    NETWORK_ISSUE_UNAUTHORIZED = 'NETWORK_ISSUE_UNAUTHORIZED',
    MARK_PUBLIC = 'MARK_PUBLIC',
    UPDATE_TENANT_TYPE = 'UPDATE_TENANT_TYPE',
    UPDATE_THEME = 'UPDATE_THEME',
    UPDATE_TENANT_MIGRATION_STATUS = 'UPDATE_MIGRATION_STATUS',
}

export enum FeatureFlagActions {
    INITIALIZED = 'INITIALIZED',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export interface ActionType {
    type: UserActions | AppMetaActions | ConfigurationActions | FeatureFlagActions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any;
}
