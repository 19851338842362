import {
    IconButton,
    Menu,
    MenuItem,
    styled,
} from '@mui/material';
import { PortalCard } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-keycode-enum';

import { bindKeyTo } from '../utils/a11y';
import type { ContextMenuItem } from './ContextPopup';

const Root = styled('div')<{ hover?: boolean }>(({
    theme, hover,
}) => ({
    position: 'relative',
    border: hover ? `solid 1px ${theme?.palette?.background?.paper}` : 'none',

    '&:hover': {
        border: `solid 1px ${theme?.palette?.semantic?.colorForegroundLink}`,
        borderRadius: 3,
        cursor: 'pointer',
    },

    '&:focus': {
        border: `solid 1px ${theme?.palette?.semantic?.colorForegroundLink}`,
        borderRadius: 3,
        cursor: 'pointer',
    },
}));

const StyledIconButton = styled('div')(({ theme }) => ({
    margin: 0,
    '& button': { '& i': { color: theme?.palette?.semantic?.colorForeground } },
}));

const Footer = styled('div')(() => ({
    left: 0,
    right: 18,
    bottom: 18,
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'absolute',
}));

const StyledMenu = styled(Menu)(() => ({
    marginTop: 0,
    marginLeft: 8,
}));

interface CardProps {
    hover?: boolean;
    onClick?: (event: any) => void;
    avatar?: string;
    title?: string;
    contextMenuItems?: ContextMenuItem[];
    description?: string;
    children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
    hover, children, onClick, avatar, title, contextMenuItems, description,
}) => {
    const portalCardRef = useRef<HTMLPortalCardElement | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const [ anchorEl, setAnchorEl ] = React.useState<EventTarget & HTMLButtonElement | null>(null);
    const { t } = useTranslation();

    const handleMoreIconClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleContextPopupClose = (event: React.MouseEvent): void => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const cb = useCallback((event: any) => {
        if (onClick) {
            onClick(event);
        }
    }, [ onClick ]);

    React.useEffect(() => {
        if (portalCardRef.current) {
            portalCardRef.current?.addEventListener('cardClicked', (event) => {
                cb(event);
            });
        }
    }, [ portalCardRef, cb ]);

    const keyInputHandler = useCallback(bindKeyTo({
        ref,
        traps: {
            [Key.Enter]: onClick,
            [Key.Space]: onClick,
        },
    }), [ ref ]);
    return (
    // This on click for div will never work because portal card traps events and won't bubble up, however in test cases
    // portal elements will never render. So we need an onClick there. This onClick below exists solely for us to be able
    // to test click events on card in test env
        <>
            <Root
                tabIndex={0}
                data-testid="portal-card-wrapper"
                onClick={onClick}
                onKeyDown={keyInputHandler}
                ref={ref}
                hover={hover}>
                <PortalCard
                    data-testid="portal-card"
                    ref={portalCardRef}
                    icon={avatar}
                    header={title}
                    description={description}>
                    {children}
                </PortalCard>
                <Footer>
                    {(contextMenuItems && contextMenuItems.length) ? (<StyledIconButton>
                        <IconButton
                            className='context-menu'
                            size="small"
                            data-testid="context-menu"
                            aria-label={t('a11y_project_options')}
                            role="menu"
                            onClick={handleMoreIconClick}
                            data-cy="edit-action-menu">
                            <i className="pt-material-icons-outlined material-icons-outlined">
                                more_vert
                            </i>
                        </IconButton>
                    </StyledIconButton>) : null}
                </Footer>
            </Root>
            {(contextMenuItems && contextMenuItems.length) ? (
                <StyledMenu
                    id="project-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    autoFocus={false}
                    open={Boolean(anchorEl)}
                    anchorReference="anchorEl"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={handleContextPopupClose}>
                    {
                        contextMenuItems.map((item: ContextMenuItem, key: number) => (
                            <MenuItem
                                key={key}
                                role="link"
                                tabIndex={0}
                                onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
                                    if (item.click) {
                                        item.click(event, {});
                                    }
                                    handleContextPopupClose(event);
                                    event.stopPropagation();
                                }}>
                                {t(item.text)}
                            </MenuItem>
                        ))
                    }
                </StyledMenu>) : null}
        </>
    );
};

export default Card;
