import logger from '../../utils/Logging';
import type FileToUpload from './FileToUpload';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ParseFolder(dataTransferItems: any[]): Promise<FileToUpload[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function traverseFileTreePromise(item: any): Promise<FileToUpload[]> {
        return new Promise((resolve) => {
            if (item.isFile) {
                item.file((file: File) => {
                    resolve([ {
                        // use substring because it has an extra "/" at the beginning
                        path: item.fullPath[0] === '/' ? item.fullPath.substring(1) : item.fullPath,
                        file,

                    } ] as FileToUpload[]);
                });
            } else {
                const dirReader = item.createReader();
                readDir(dirReader).then(files => resolve(files))
                    .catch((error: Error) => {
                        logger.error({
                            identifier: 'Parse Folder',
                            message: 'Error reading directory (traverse)',
                            error,
                        });
                    });
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function readDir(dirReader: any): Promise<FileToUpload[]> {
        return new Promise(resolve => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dirReader.readEntries((entries: any[]) => {
                if (entries.length) {
                    ParseFolder(entries).then(res =>
                    // eslint-disable-next-line promise/no-nesting
                        readDir(dirReader).then(files => resolve(res.concat(files)))
                            .catch((error: Error) => {
                                logger.error({
                                    identifier: 'Parse Folder',
                                    message: 'Error reading directory',
                                    error,
                                });
                            }),
                    )
                        .catch((error: Error) => {
                            logger.error({
                                identifier: 'Parse Folder',
                                message: 'Error parsing directory',
                                error,
                            });
                        });
                } else {
                    resolve([]);
                }
            });
        });
    }

    return new Promise((resolve) => {
        const entriesPromises = [];
        for (let i = 0; i < dataTransferItems.length; i++) {
            entriesPromises.push(traverseFileTreePromise(dataTransferItems[i]));
        }
        Promise.all(entriesPromises).then(entries => {
            resolve(entries.reduce((prev, current) => prev.concat(current), []));
            return true;
        })
            .catch((error: Error) => {
                logger.error({
                    identifier: 'Parse Folder',
                    message: 'Error reducing directory',
                    error,
                });
            });
    });
}
