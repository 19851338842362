import { TextField } from '@mui/material';
import { isValidCron } from 'cron-validator';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import type { CronDialogFooterProps } from './CronInputFooter';
import { CronDialogFooter } from './CronInputFooter';

interface AdvancedCronBuilderProps extends CronDialogFooterProps {
    cronExp: string;
    onChange: (_: any) => void;
}

export const AdvancedCronBuilder: React.FC<AdvancedCronBuilderProps> = ({
    cronExp,
    callback,
    closeDialog,
    onChange,
}) => {
    const { t } = useTranslation();
    const [ errorText, setErrorText ] = useState('');

    useEffect(() => {
        if (cronExp.startsWith('*')) {
            setErrorText(t('invalid_cron_starting_char'));
        } else if (!isValidCron(cronExp)) {
            setErrorText(t('invalid_cron_expression'));
        } else {
            setErrorText('');
        }
    }, [ cronExp ]);

    const onSubmit = useCallback(() => {
        if (cronExp.startsWith('*') || !isValidCron(cronExp)) {
            return;
        }
        callback();
    }, [ callback, cronExp ]);

    return (
        <>
            <div className="flexInputContainer">
                <div className="cronExpression">
                    <TextField
                        className="cronExpressionTextField"
                        data-testid="cron-adv-input"
                        error={cronExp.startsWith('*') || !isValidCron(cronExp)}
                        label={t('label_cron_expression_hint')}
                        variant="filled"
                        value={cronExp}
                        onChange={onChange}
                        size="small"
                        color="secondary"
                        name="cronExpression"
                        autoComplete="off"
                        helperText={errorText}
                        InputProps={{ 'inputProps': { 'aria-label': t('label_cron_expression_hint') } }}
                    />
                </div>
            </div>
            <CronDialogFooter
                callback={onSubmit}
                closeDialog={closeDialog} />
        </>
    );
};
