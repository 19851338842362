import { styled } from '@mui/material';
import type { Row } from '@tanstack/react-table';
import type {
    AppDto,
    DatasetDto,
} from '@uipath/aifabric';
import type { CancelTokenSource } from 'axios';
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import { deleteApp } from '../../../api/client/appManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import { CustomDialog } from '../../../components/Dialog';
import type { ColumnDefinition } from '../../../components/Table/BaseTable/types';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../../config/URLManager';
import { AppPermissions } from '../../../enums/Authorization';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { getAccountAndTenantFromCannonicalPath } from '../../../route/routeHelper';
import { RoutePath } from '../../../route/routeMap';
import {
    dataFormatter,
    extractErrorMessage,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import type { Log } from '../../../utils/Logging';
import logger from '../../../utils/Logging';
import { loadDataset } from '../LoadDataset';

const WarnText = styled('span')({
    color: 'red',
    fontWeight: 'bold',
});

interface DataLabelingSessionListProps {
    appsLaunchUrlTemplate: string;
    permissions: AppPermissions[];
}
export const DataLabelingSessionList: React.FC<DataLabelingSessionListProps> = ({
    appsLaunchUrlTemplate,
    permissions,
}) => {
    const {
        t, i18n,
    } = useTranslation();
    const feedback = useFeedback();

    const [ datasets, setDatasets ] = useState<DatasetDto[]>([]);
    const [ cancelToken, setCancelToken ] = React.useState<CancelTokenSource | null>(null);
    const [ loadingState, setLoadingState ] = React.useState(true);
    const [ open, setOpen ] = React.useState(false);

    const getDatasetName = (data: { row: { original: { exportedDatasetId: string } } }): string => {
        const datasetId = data?.row?.original?.exportedDatasetId;
        if (datasets && datasetId) {
            const dataset: DatasetDto = datasets.find(value => value.id === datasetId) as DatasetDto;
            if (dataset) {
                return dataset.name ? dataset.name : '';
            }
        }
        return '';
    };

    const getAppStatusLabel = (data: { row: { original: AppDto } }): React.ReactElement => {
        const app: AppDto = data?.row?.original;
        const migrateWarningStatus: string = t('dl_session_migration_status_text');
        return (
            <div>
                {
                    dataFormatter(t(`Data_Labeling_${app?.appStatus}`), i18n.language)
                }
                {
                    app?.type === 'DATA_MANAGER' ? <WarnText>
                        {' '}
                        {migrateWarningStatus}
                    </WarnText> : ''
                }
            </div>
        );
    };

    const dataMapper: ColumnDefinition[] = [
        {
            header: `${t('common_name_label')}`,
            accessorKey: 'name',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => {
                const value = getValue();
                return !value || value.length <= 0 ? '' : value;
            },
        },
        {
            header: `${t('dl_session_list_export_dataset_header')}`,
            accessorKey: 'exportedDatasetId',
            enableSorting: true,
            cell: getDatasetName,
        },
        {
            header: `${t('common_status_label')}`,
            accessorKey: 'appStatus',
            cell: getAppStatusLabel,
        },
        {
            header: `${t('common_created_label')}`,
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
        },
    ];

    const { state } = useContext(ProjectsContext);
    const currentProject = state.currentProject;

    const loading = (datasets.length === 0 ? true : false) && (loadingState === true ? true : false);
    const [ isDatasetLoadingInProgress, setIsDatasetLoadingInProgress ] = React.useState(false);
    const [ activeDataLabelSession, setActiveDataLabelSession ] = React.useState<Row<any> | undefined>(undefined);
    const [ itemDeletedFlag, setItemDeletedFlag ] = React.useState(false);
    const [ showDeleteErrorDuringDeploying, setShowDeleteErrorDuringDeploying ] = React.useState(false);

    useEffect(() => {
        if (loading === true && isDatasetLoadingInProgress === false) {
            setLoadingState(true);
            loadDataset({
                projectId: currentProject?.id || '',
                cancelTokenCallback: setCancelToken,
                loadingInProgress: setIsDatasetLoadingInProgress,
                thenCallback: setDatasets,
                catchCallback: handleDatasetLoadingError,
                finalCallback: handleFinalDatasetLoadingState,
            });
            return function cleanup(): void {
                loadDataset.cancel();
                if (cancelToken) {
                    cancelToken.cancel();
                }
            };
        }
    });

    const handleDatasetLoadingError = (error: any) => {
        logger.error({
            identifier: 'DataLabeling Session Create',
            message: 'Error getting datasets',
            error,
        });
        feedback.enqueueError(extractErrorMessage(error, t('feedback_datasets_loading_error')));
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleFinalDatasetLoadingState = () => {
        setLoadingState(false);
        setIsDatasetLoadingInProgress(false);
    };

    const getAppLaunchUrl = (appDto: AppDto): string => {
        const accessUri = appDto.accessUri;
        const {
            account, tenant,
        } = getAccountAndTenantFromCannonicalPath();
        let url = appsLaunchUrlTemplate.replace(':account', account).replace(':tenant', tenant) + accessUri;
        if (appDto.type === 'DATA_MANAGER_SAAS') {
            url = url.replace('/aifabric_', '');
        }
        return url;
    };

    const history = useHistory();

    const handleAppClicked = useCallback((rowInfo: any): void => {
        const app: AppDto = rowInfo.original as AppDto;
        if (app.type === 'LABEL_BOX') {
            history.push(generatePath(RoutePath.LABELING_SESSION_DASHBOARD, { projectName: currentProject?.name }), {
                appId: app?.id,
                projectId: app?.projectId,
                datasetId: app?.exportedDatasetId,
            });
            return;
        }
        if (app.appStatus === 'AVAILABLE') {
            const url = getAppLaunchUrl(app);
            const log: Log = {
                identifier: app.id || '',
                message: `Opened ${app.type} Data labeling session`,
                payload: {
                    'appId': app.id,
                    'tenantId': app.tenantId,
                },
            };
            logger.track('DataLabelling.Opened', log);
            window.open(url, '_blank');
        }
    }, []);

    const rowTooltip = useCallback((rowInfo: any): string => {
        const app: AppDto = rowInfo.original as AppDto;
        let tooltip = '';
        if (app.type === 'DATA_MANAGER') {
            switch (app.appStatus) {
                case 'STOPPED': {
                    tooltip = t('dl_session_deploy_tooltip_text');
                    break;
                }
                case 'FAILED':
                case 'ERROR': {
                    tooltip = t('dl_session_deploy_error_tooltip_text');
                    break;
                }
            }
            tooltip = t('dl_session_deprecate_tooltip_text') + tooltip;
        }
        return tooltip;
    }, []);

    const servicesUrl = React.useMemo(() => URLManager.url().apiAppManager + '/app?sortBy=createdOn&sortOrder=DESC&projectId=' + currentProject?.id, [ currentProject ]);

    const menuItems = [];
    if (permissions.find(perm => perm === AppPermissions.DataLabeling_Delete)) {
        menuItems.push({
            text: 'dl_session_delete_label',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, rowInfo: Row<any>) => {
                if (rowInfo.original.appStatus.toLowerCase() === 'deploying') {
                    openDeleteErrorDiaglog();
                } else {
                    setActiveDataLabelSession(rowInfo);
                    setOpen(true);
                }
            },
        });
    }

    const closeDialog = (): void => {
        setOpen(false);
    };

    const openDeleteErrorDiaglog = (): void => {
        setShowDeleteErrorDuringDeploying(true);
    };

    const closeDeleteErrorDiaglog = (): void => {
        setShowDeleteErrorDuringDeploying(false);
    };

    const deleteSelectedDataLabelingSession = (): void => {
        if (activeDataLabelSession) {
            deleteApp(activeDataLabelSession.original.id, currentProject?.id as string)
                .then(() => {
                    closeDialog();
                    feedback.enqueueSuccess(t('feedback_delete_success_datalabel'));
                    /* Refresh Table */
                    setItemDeletedFlag(prev => !prev);
                    return true;
                })
                .catch((error: any) => {
                    closeDialog();
                    logger.error({
                        identifier: 'DataLabel page',
                        message: 'Error deleting data label session',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(error, t('feedback_delete_error_datalabel')));
                });
        }
    };

    return currentProject?.id ? (
        <>
            <CustomDialog
                title={t('dl_delete_dialog_title_text')}
                open={open}
                handleClose={closeDialog}
                closeIconButton
                infoText={t('dl_delete_dialog_info_text')}
                warningText={t('dl_delete_dialog_warning_text', { exportedDataset: getDatasetName({ row: { original: { exportedDatasetId: activeDataLabelSession?.original.exportedDatasetId as string } } }) })}
                confirmationText={t('dl_delete_dialog_confirmation_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={deleteSelectedDataLabelingSession}
                secondarybuttonCallback={closeDialog}
            />
            <CustomDialog
                title={t('dl_delete_dialog_title_text')}
                open={showDeleteErrorDuringDeploying}
                handleClose={closeDeleteErrorDiaglog}
                closeIconButton
                infoText={t('dl_delete_failed_while_deploying')}
            />
            <ServerSideTable
                url={servicesUrl}
                totalKey="data.totalCount"
                dataKey="data.dataList"
                mapper={dataMapper}
                onTableCellClicked={handleAppClicked}
                contextMenuItems={menuItems}
                itemDeletedFlag={itemDeletedFlag}
                onRowHoverTooltip={rowTooltip}
                keyColumnIndex={0}
                level="datalabelling_pagesize"
            />
        </>
    ) : null;
};
