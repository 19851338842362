export const INFINITE_SCROLLER_PAGE_NUM = 1;
/* Lets keep it 50 to avoid resolution error */
export const INFINITE_SCROLLER_PAGE_SIZE = 50;
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const LOCALSTORAGE_SHARED_KEY_TENANT_ID = 'aic_shared_tenant_id';
export const GPU_AIUNITS = 20;
export const CPU_AIUNITS = 6;
export const DATASET_CREATE_DUPLICATE_SUCCESS_RESP = '208';
export const DATASET_CREATE_DUPLICATE_ERROR_CODE = 10006;

/**
 * Requested memory only supports the following values currently
 */
export enum RequestedMemory {
    Two = 2,
    Four = 4,
    Eight = 8,
    Sixteen = 16,
    TwentyFour = 24,
}

/**
 * Resource per replica configuration mapping where the key represents te requested memory possible values
 */
export const RESOURCE_PER_REPLICA_CONFIG: Record<RequestedMemory, MlPackageTier> = {
    [RequestedMemory.Two]: {
        cpu: 0.5,
        memory: 2,
        resource: '0.5 CPU 2 GB RAM',
        unitvalue: 1,
    },
    [RequestedMemory.Four]: {
        cpu: 1,
        memory: 4,
        resource: '1 CPU 4 GB RAM',
        unitvalue: 2,
    },
    [RequestedMemory.Eight]: {
        cpu: 2,
        memory: 8,
        resource: '2 CPU 8 GB RAM',
        unitvalue: 4,
    },
    [RequestedMemory.Sixteen]: {
        cpu: 4,
        memory: 16,
        resource: '4 CPU 16 GB RAM',
        unitvalue: 8,
    },
    [RequestedMemory.TwentyFour]: {
        cpu: 6,
        memory: 24,
        resource: '6 CPU 24 GB RAM',
        unitvalue: 12,
    },
};

export const MAX_SUPPORTED_REPLICA_COUNT = 20;
export const DEFAULT_REPLICA_COUNT = 1;

export const DEFAULT_RESOURCE_PER_REPLICA_CONFIG = RESOURCE_PER_REPLICA_CONFIG[RequestedMemory.Two];
export const DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG = RESOURCE_PER_REPLICA_CONFIG[RequestedMemory.Eight];

export interface MlPackageTier {
    resource: string;
    unitvalue: number;
    cpu?: number;
    memory?: number;
}

export const COLOUR_LIST = [
    'blue',
    'green',
    'orange',
    'yellow',
    'purple',
    'brown',
    'gray',
    'red',
    'olive',
    'maroon',
    'violet',
    'magenta',
    'tan',
    'teal',
    'black',
    'coral',
    'lavender',
    'gold',
    'pink',
    'silver',
    'cyan',
];

export const DEFAULT_PAGE_SIZE = 20;
