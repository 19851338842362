import type { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import URLManager from '../config/URLManager';
import { PERMISSIONS_FAILED_MSG } from '../constants/TelemetryConstants';
import { AppPermissions } from '../enums/Authorization';
import {
    Origin,
    Scope,
    Service,
} from '../enums/ClientErrorStrings';
import { http } from '../http';
import type { AppStore } from '../state-management/store';
import { getDisplayErrorCode } from '../utils/CommonUtils';
import logger from '../utils/Logging';
import { ProjectsContext } from './ProjectsProvider';

export enum PermissionState {
    EMPTY = 'PERMISSIONS_EMPTY',
    INITIATED = 'PERMISSIONS_FETCH_INITIATED',
    LOADED = 'PERMISSIONS_LOADED',
    FAILED = 'PERMISSIONS_FAILED',
}

export const initialPermissionsState: PermissionsState = {
    tenantData: {
        permissionSet: [] as AppPermissions[],
        permissionState: PermissionState.EMPTY,
        failureCode: null,
        backendCode: null,
    },
    projectData: {},
};

export const PermissionsContext = createContext<{
    state: PermissionsState;
}>({ state: initialPermissionsState });

export enum FEToBEMapper {
    'ML_PACKAGES' = 'MLPackages',
    'DATASET' = 'MLStorage',
    'PIPELINES' = 'MLPipeline',
    'ML_LOGS' = 'MLLogs',
    'DATA_LABELING' = 'DataLabeling',
    'ML_SKILLS' = 'MLSkills',
    'SETTINGS' = 'Settings',
    'PROFILE' = 'Profile',
    'LICENSES' = 'Licenses',
    'ROLES' = 'Roles',
    'USERS' = 'Users',
    'PROJECT' = 'MLProjects',
    'OOB_UPLOAD' = 'OOBUpload',
}

export enum PossiblePermissions {
    'canCreate' = 'canCreate',
    'canEdit' = 'canEdit',
    'canDelete' = 'canDelete',
    'canView' = 'canView',
}

export type PermissionData = {
    permissionSet: AppPermissions[];
    permissionState: PermissionState;
    failureCode?: null | number | string;
    backendCode?: undefined | null | string;
};

export type PermissionsState = {
    tenantData: PermissionData;
    projectData: { [projectId: string]: PermissionData };
};

interface PermissionsProviderProps {
    children: React.ReactNode;
}

export const PermissionsStateProvider: React.FC<PermissionsProviderProps> = ({ children }) => {
    const [ state, setState ] = useState<PermissionsState>(initialPermissionsState);
    const permissionFields = Object.keys(PossiblePermissions);

    const convertToAppPermission = (responseData: any): AppPermissions[] => Object.keys(responseData).reduce((combined: AppPermissions[], serviceName: any) => {
        const serviceNameMapped = FEToBEMapper[serviceName as keyof typeof FEToBEMapper];
        const servicePermission = responseData[serviceName];

        const sub = Object.keys(servicePermission).reduce((acc: AppPermissions[], field: any) => {
            if (servicePermission[field]) {

                const field_ = _.capitalize(field.substring(3));
                const permissionName = serviceNameMapped + '_' + field_;
                return [ ...acc, AppPermissions[permissionName as keyof typeof AppPermissions] ];

            } return [ ...acc ];
        }, []);

        return combined.concat(sub);
    }, []);

    const getPermissionsFromAIC = async (url: string): Promise<AppPermissions[]> => {
        const response: AxiosResponse = await http.get(url);
        const responseData: any = response.data.data;

        return convertToAppPermission(responseData.reduce(
            (accumulator: any, curr: any) => ({
                ...accumulator,
                [curr['permission']]: _.pick(curr, permissionFields),
            }), {}));
    };

    const forceRefreshTenant = async (): Promise<void> => {
        try {
            const tenantPermissionUrl = `${URLManager.url().apiHelper}/permissions/users/tenant/`;
            const tenantPermissionSet = await getPermissionsFromAIC(tenantPermissionUrl);
            setState(oldState => (
                {
                    ...oldState,
                    tenantData: {
                        ...oldState.tenantData,
                        permissionSet: tenantPermissionSet,
                        permissionState: PermissionState.LOADED,
                    },
                }
            ));
        } catch (error: any) {
            const backendCode = getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.BOOTSTRAPROVIDER, error, error.response?.status);
            logger.error({
                identifier: Scope.Core,
                message: PERMISSIONS_FAILED_MSG,
                error,
                backendCode,
            });

            setState(oldState => (
                {
                    ...oldState,
                    tenantData: {
                        ...oldState.tenantData,
                        permissionState: PermissionState.FAILED,
                        backendCode: getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.PROJECTLIST, error, error.response?.status),
                    },
                }
            ));
        }
    };

    const forceRefreshProject = async (projectId?: string): Promise<void> => {
        try {
            const projectPermissionUrl = `${URLManager.url().apiHelper}/permissions/users/project/?project-id=${projectId}`;
            const projectPermissionSet = await getPermissionsFromAIC(projectPermissionUrl);

            setState(oldState => (
                {
                    ...oldState,
                    projectData: {
                        ...oldState.projectData,
                        [projectId || 'undefined']: {
                            permissionSet: projectPermissionSet,
                            permissionState: PermissionState.LOADED,
                        },
                    },
                }
            ));

        } catch (error: any) {
            const backendCode = getDisplayErrorCode(Scope.Projects, Service.HELPER, Origin.BOOTSTRAPROVIDER, error, error.response?.status);
            logger.error({
                identifier: Scope.Projects,
                message: `${PERMISSIONS_FAILED_MSG} - ${projectId}`,
                error,
                backendCode,
            });

            setState(oldState => (
                {
                    ...oldState,
                    projectData: {
                        ...oldState.projectData,
                        [projectId || 'undefined']: {
                            permissionSet: [],
                            permissionState: PermissionState.FAILED,
                            backendCode: getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.PROJECTLIST, error, error.response?.status),
                        },
                    },
                }
            ));
        }
    };

    const { apiHelper } = useSelector((state: AppStore) => state.config.paths);
    useEffect(() => {
        if (apiHelper) {
            forceRefreshTenant();
        }
    }, [ apiHelper ]);

    const { currentProjectId } = useContext(ProjectsContext).state;
    useEffect(() => {
        forceRefreshProject(currentProjectId);
    }, [ currentProjectId, apiHelper ]);

    return (
        <PermissionsContext.Provider value={{ state }}>
            {children}
        </PermissionsContext.Provider>
    );
};
