import { styled } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';

import AccessibleTooltip from './AccessibleTooltip';

const TooltipContainerRoot = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

interface TooltipContainerProps {
    title: string;
    customClass?: string;
    customStyle?: React.CSSProperties;
    children?: ReactNode;
}

const TooltipContainer: React.FC<TooltipContainerProps> = ({
    children, title, customClass, customStyle,
}) => (
    <TooltipContainerRoot>
        {children}
        <AccessibleTooltip
            title={title}
            customStyle={customStyle}
            customClass={customClass} />
    </TooltipContainerRoot>
);

export default TooltipContainer;
