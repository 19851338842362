import {
    Menu,
    MenuItem,
} from '@mui/material';
import React from 'react';

import type { ContextMenuProps } from './index.d';

export const ContextMenu: React.FC<ContextMenuProps> = (
    {
        items, ...rest
    },
) => (
    <Menu
        {...rest}
    >
        {
            items.map((t) => (
                <MenuItem
                    aria-label={t.label}
                    onClick={t.onClick}
                    disabled={t.disabled}
                    className={t.className}
                    data-cy={t.dataCY}
                    key={t.idx}
                >
                    {t.label}
                </MenuItem>
            ))
        }
    </Menu>
);

export * from './index.d';
