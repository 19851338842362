import AddIcon from '@mui/icons-material/Add';
import {
    Box,
    IconButton,
    styled,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithVisibility } from '../../../components/WithVisibility';

interface TaskHeaderProps {
    headerText: string;
    handleOpen?: () => void;
    isTabEditable: boolean;
}

const HeaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
});

const HeaderText = styled(Typography)(({ theme }) => ({ marginTop: theme.spacing(1) }));

const TaskHeader: React.FC<TaskHeaderProps> = ({
    headerText, handleOpen, isTabEditable,
}) => {
    const { t } = useTranslation();

    return <HeaderBox>
        <HeaderText
            variant='h4'>
            {headerText}
        </HeaderText>
        <WithVisibility visible={isTabEditable}>
            <IconButton
                data-testid='add-new-task-btn'
                color="primary"
                aria-label={t('a11y_add_new_task')}
                component="span"
                onClick={handleOpen}>
                <AddIcon color='primary' />
            </IconButton>
        </WithVisibility>
    </HeaderBox>;
};

export default TaskHeader;
