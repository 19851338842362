import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { ReactNode } from 'react';
import React from 'react';

const ColumnTitleRoot = styled('span')(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    paddingRight: '8px',
    color: theme.palette.semantic.colorForeground,
}));

interface ColumnTitleProps {
    children: ReactNode;
}

export const ColumnTitle: React.FC<ColumnTitleProps> = ({ children }) => <ColumnTitleRoot>
    {children}
</ColumnTitleRoot>;
