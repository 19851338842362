import type { AutocompleteInputChangeReason } from '@mui/material';
import {
    Autocomplete,
    CircularProgress,
    styled,
    TextField,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import { fieldToTextField } from 'formik-mui';
import type { ReactElement } from 'react';
import React from 'react';

// @ts-ignore
const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    /* Makes other area click able */
    width: '340px',
    fontFamily: Tokens.FontFamily.FontNormal,
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    fontWeight: 500,
    fontStyle: 'normal',

    '& .MuiAutocomplete-inputRoot': {
        padding: 0,
        height: '40px',
    },

    /* Set the On focus color for textField */
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.semantic.colorForegroundLink,
        borderWidth: 2,
    },

    '& .MuiOutlinedInput-root': {
        padding: '12px',

        '& .MuiAutocomplete-input:first-child': { padding: 0 },
    },
}));

interface FormAutoComplete {
    [field: string]: any;
    options: any[] | [];
    loadingText: string;
    loading: boolean;
    noOptionsText: string;
    placeholder: string;
    labelKey: string;
    valueField: string;
    inputvalue?: any;
    disabled?: boolean;
    ariaLabel?: string;
    requiredField?: boolean;
    handleSelection: (input: any, fieldName: string, fieldValue: string) => void;
}

interface FormAutoCompleteClientSideProps extends FormAutoComplete {
    value: any;
}

const onSelectionChangeCore = (item: any, sendValue: any, name: any, valueField: string, handleSelection: Function): void => {
    if (item) {
        handleSelection(sendValue, name, item[valueField]);
    }
};

const onInputChangeCore = (
    reasonInput: AutocompleteInputChangeReason,
    reasonToIgnore: AutocompleteInputChangeReason,
    sendValue: any,
    name: any,
    value: string,
    handleSelection: Function,
): void => {
    if (reasonInput !== reasonToIgnore) {
        handleSelection(sendValue, name, value);
    }
};

const getOptionLabelCore = (labelKey: string) => (option: any): string => (option ? option[labelKey] : '');

/**
 * Performs client side fileatrion of list based on input provided by user
 *
 * @param FormAutoCompleteClientSideProps
 *
 */
export const FormAutoCompleteClientSide: React.FC<FormAutoCompleteClientSideProps> = (
    {
        options,
        loading,
        disabled,
        inputvalue,
        loadingText,
        noOptionsText,
        placeholder,
        labelKey,
        labelledBy,
        valueField,
        value,
        handleSelection,
        requiredField,
        ariaLabel,
        ...props
    }) => {

    const { field: { name } } = props;

    /* Handle input changed by user */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onInputChange = (event: React.ChangeEvent<{}>, changedValue: any, reason: AutocompleteInputChangeReason): void => {
        onInputChangeCore(reason, 'input', false, name, '', handleSelection);
    };

    /* Handle selection made by user */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSelectionChange = (event: React.ChangeEvent<{}>, item: any): void => {
        onSelectionChangeCore(item, item, name, valueField, handleSelection);
    };

    const getOptionLabel = React.useCallback(getOptionLabelCore(labelKey), [ labelKey ]);

    return (
        <StyledAutocomplete
            {...props}
            id={name}
            key={name}
            disabled={disabled}
            inputValue={inputvalue || undefined}
            value={value ? value : ''}
            options={options}
            loading={loading}
            loadingText={loadingText}
            noOptionsText={noOptionsText}
            clearOnEscape
            autoHighlight
            getOptionLabel={getOptionLabel}
            onInputChange={onInputChange}
            onChange={onSelectionChange}
            renderInput={(propeties): ReactElement => (
                <TextField
                    name={name}
                    {...propeties}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    {...fieldToTextField(props as any)}
                    disabled={disabled}
                    variant="outlined"
                    fullWidth={false}
                    color="secondary"
                    placeholder={placeholder}
                    autoComplete="off"
                    onChange={(): string => ''}
                    InputProps={{
                        ...propeties.InputProps,
                        'aria-describedby': `${props.field.name}-error-label`,
                        'aria-label': ariaLabel ? ariaLabel : placeholder,
                        inputProps: {
                            ...propeties.inputProps,
                            'aria-describedby': `${props.field.name}-error-label`,
                            'aria-label': ariaLabel ? ariaLabel : placeholder,
                            'aria-required': requiredField,
                            'aria-labelledby': labelledBy,
                        },
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="secondary"
                                        size={20} />
                                ) : null}
                                {propeties.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

/**
 * Performs server side fileatrion of list based on input provided by user
 *
 * @param FormAutoCompleteServerSideProps
 *
 */
export const FormAutoCompleteServerSide: React.FC<FormAutoComplete> = (
    {
        options,
        loading,
        disabled,
        inputvalue,
        loadingText,
        noOptionsText,
        placeholder,
        labelKey,
        labelledBy,
        valueField,
        handleSelection,
        requiredField,
        ariaLabel,
        ...props
    }) => {

    const { field: { name } } = props;

    /* Handle input changed by user */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onInputChange = (event: React.ChangeEvent<{}>, value: any, reason: AutocompleteInputChangeReason): void => {
        onInputChangeCore(reason, 'reset', true, name, value, handleSelection);
    };

    /* Handle selection made by user */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSelectionChange = (event: React.ChangeEvent<{}>, item: any): void => {
        onSelectionChangeCore(item, false, name, valueField, handleSelection);
    };

    const getOptionLabel = React.useCallback(getOptionLabelCore(labelKey), [ labelKey ]);
    return (

        <StyledAutocomplete
            {...props}
            id={name}
            key={name}
            disabled={disabled}
            /* Undefined set to work for all cases other than pipeline edit */
            inputValue={inputvalue || undefined}
            options={options}
            loading={loading}
            loadingText={loadingText}
            noOptionsText={noOptionsText}
            clearOnEscape
            autoHighlight
            getOptionLabel={getOptionLabel}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterOptions={(optionList): any[] => optionList}
            onInputChange={onInputChange}
            onChange={onSelectionChange}
            renderInput={(propeties): ReactElement => (
                <TextField
                    name="AutocompleteTextFeild"
                    {...propeties}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    {...fieldToTextField(props as any)}
                    disabled={disabled}
                    variant="outlined"
                    color="secondary"
                    placeholder={placeholder}
                    autoComplete="off"
                    InputProps={{
                        ...propeties.InputProps,
                        'aria-describedby': `${props.field.name}-error-label`,
                        'aria-labelledby': labelledBy,
                        'aria-label': ariaLabel ? ariaLabel : placeholder,
                        inputProps: {
                            ...propeties.inputProps,
                            'aria-describedby': `${props.field.name}-error-label`,
                            'aria-labelledby': labelledBy,
                            'aria-label': ariaLabel ? ariaLabel : placeholder,
                            'aria-required': requiredField,
                        },
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="secondary"
                                        size={15} />
                                ) : null}
                                {propeties.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
