import { SeverityLevel } from '@uipath/telemetry-client-web';
import axios from 'axios';
import stringify from 'json-stringify-safe';
import { serializeError } from 'serialize-error';

import type { CustomHttpError } from '../http/types';
import getInstance from './AppInsights';

export interface Log {
    identifier: string;
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: Error | string | any;
    raw?: boolean;
    backendCode?: string;
}

enum TrackType {
    Error = 'error',
    Track = 'track',
}

const isCloudFlareOrNetworkError = (error: CustomHttpError) => (error.isCloudFlareError || error.isNetworkError);
const isCancelRequest = (error: any) => (axios.isCancel(error));

class Logger {
    static track(type: TrackType | string, log: any): void {
        const instance = getInstance();
        instance?.trackEvent(type, log);
    }

    log(log: Log): void {
        console.log(Logger.process(log));
    }

    error(log: Log): void {
        console.error(log);
        try {
            const error = log.error as CustomHttpError;
            if (!error || (!isCloudFlareOrNetworkError(error) && !isCancelRequest(log.error))) {
                const errorToLog = log.error && log.error instanceof Error ? log.error : new Error(TrackType.Error);
                const instance = getInstance();
                instance?.trackError(errorToLog, SeverityLevel.Major, Logger.process(log));
            }
        } catch (e) {
            console.log('Log parse/report failed');
        }
    }

    customEvents(log: Log, eventName: string): void {
        Logger.track(eventName, log);
    }

    track(trackType: string | undefined, log: any): void {
        console.info(Logger.process(log));
        Logger.track(trackType || TrackType.Track, log);
    }

    static process(log: any): any {
        if (log.raw) {
            return log;
        }
        if (log.error) {
            log.error = log.error instanceof Error ? stringify(serializeError(cleanError(log.error))) : stringify(log.error);
        }

        if (log.payload) {
            log.payload = stringify(log.payload);
        }

        return log;
    }

}

function cleanError(error: any) {
    if (error.config && error.config.headers) {
        delete error.config.headers.Authorization;
    }
    return error;
}
export default new Logger();
