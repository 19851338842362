import {
    Grid,
    styled,
} from '@mui/material';
import type { LicenseDto } from '@uipath/aifabric';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getLicenseUsage } from '../../api/client/licenseManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import Gauge from '../../components/Gauge';
import { extractErrorMessage } from '../../utils/CommonUtils';
import logger from '../../utils/Logging';

const LicenseDetailContainer = styled('div')({
    display: 'block',
    padding: '24px',
    width: '100%',
});

const LicenseDetail: React.FC = () => {
    const { t } = useTranslation();

    return (
        <LicenseDetailContainer>
            <h2>
                {t('licenses_title')}
            </h2>
            <LicenseGauges />
        </LicenseDetailContainer>
    );
};

const GaugeContainerGrid = styled(Grid)({
    width: '100%',
    margin: '0px',
});

const LicenseGauges: React.FC = () => {
    const feedback = useFeedback();
    const { t } = useTranslation();

    const [ data, setData ] = React.useState<LicenseDto | undefined>(undefined);

    React.useEffect(() => {
        getLicenseUsage()
            .then(usage => {
                setData(usage);
                return true;
            })
            .catch((error) => {
                logger.error({
                    identifier: 'License Detail',
                    message: 'Error fetching license usage',
                    error,
                });

                feedback.enqueueError(extractErrorMessage(error, t('licenses_retrieval_error'), { 40801: {} }));
            });
    }, []);

    return (
        <GaugeContainerGrid
            container
            direction='row'
            justifyContent='space-evenly'
            alignItems='center'
            spacing={10}
        >
            <Grid item>
                <Gauge
                    used={data?.used?.AI_ROBOT}
                    allowed={data?.allowed?.AI_ROBOT}
                    descriptionKey="gauge_description_robot"
                />
            </Grid>
            <Grid item>
                <Gauge
                    used={data?.used?.AI_GPU}
                    allowed={data?.allowed?.AI_GPU}
                    descriptionKey="gauge_description_gpu"
                />
            </Grid>
        </GaugeContainerGrid>
    );
};

export default LicenseDetail;
