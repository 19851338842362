import {
    Breadcrumbs,
    Link,
    styled,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Crumb {
    title: string;
    link: string;
    data?: any;
}

const Root = styled('div')({ marginTop: '8px' });

export interface BreadcrumbProps {
    pageTitle: string | undefined;
    crumbs: Crumb[];
}

export const PageBreadCrumbs: React.FC<BreadcrumbProps> = ({
    pageTitle, crumbs,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Root>
            <Breadcrumbs aria-label={t('a11y_breadcrumb_text')}>
                {
                    crumbs.map((crumb: Crumb) => (
                        <Link
                            className="cursor-pointer"
                            key={crumb.link}
                            underline="hover"
                            data-testid="uipath-breadcrumb-link"
                            onClick={(): void => {
                                if (crumb.data) {
                                    history.push({
                                        pathname: crumb.link,
                                        state: { data: crumb.data },
                                    });
                                } else {
                                    history.push(crumb.link);
                                }

                            }} >
                            {crumb.title}
                        </Link>
                    ))
                }
                <Typography color="textPrimary">
                    {pageTitle}
                </Typography>
            </Breadcrumbs>
        </Root>
    );
};
