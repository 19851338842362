import type { ReactNode } from 'react';
import React from 'react';

interface VisibilityProps {
    visible: boolean;
    children: ReactNode;
}

export const WithVisibility: React.FC<VisibilityProps> = (props) => {
    if (!props.visible) {
        return null;
    }
    return <>
        {props.children}
    </>;
};
