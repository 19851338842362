import { generatePath } from 'react-router-dom';

import { AppPermissions } from '../enums/Authorization';
import CallbackPage from '../pages/CallbackPage';
import DataLabelingSessionCreate from '../pages/datalabeling/create/DataLabelingSessionCreate';
import DataLabelingSessionsPage from '../pages/datalabeling/DataLabelingSessionsPage';
import LabelingSessionDashboard from '../pages/datalabeling/LabelingSessionDashboard';
import { OOBLabelingTemplates } from '../pages/datalabeling/OOBLabelingTemplates';
import { DatasetCreate } from '../pages/datasets/create/DatasetCreate';
import { DatasetFolderPage } from '../pages/datasets/list/DatasetFoldersPage';
import { DataSetsPage } from '../pages/datasets/list/DatasetsPage';
import { UploadItems } from '../pages/datasets/upload/UploadItems';
import LicenseDetail from '../pages/license/LicenseDetail';
import MlLogsDetailsPage from '../pages/mlLogs/MlLogsDetailsPage';
import { MlLogsPage } from '../pages/mlLogs/MlLogsPage';
import MLPackageCreate from '../pages/mlpackages/create/MLPackageCreate';
import MLPackageImport from '../pages/mlpackages/import/MLPackageImport';
import MLPackageImportOOB from '../pages/mlpackages/import/MLPackageImportOOB';
import { ImportOOBLogs } from '../pages/mlpackages/import/MLPackageImportOOBLogs';
import PackageList from '../pages/mlpackages/list/MLPackagesPage';
import UiPathPackageContainer from '../pages/mlpackages/uipath/UiPathPackage';
import UiPathPackageClone from '../pages/mlpackages/uipath/UiPathPackageClone';
import UiPathPackages from '../pages/mlpackages/uipath/UiPathPackages';
import UiPathProjects from '../pages/mlpackages/uipath/UiPathProjects';
import MLPackageVersionsPage from '../pages/mlPackageVersion/MLPackageVersionsPage';
import MlSkillCreate from '../pages/mlSkill/create/MlSkillCreate';
import { MlSkillDetailsPage } from '../pages/mlSkill/details/MlSkillDetailsPage';
import { MlSkillListPage } from '../pages/mlSkill/list/MlSkillPage';
import PipelineCreate from '../pages/pipeline/create/PipelineCreate';
import PipelineListPage from '../pages/pipeline/list/PipelineList';
import PipelineDataPage from '../pages/pipelineData/PipelineDataPage';
import ProfilePage from '../pages/profilePage/ProfilePage';
import ProjectCreate from '../pages/projects/create/ProjectCreate';
import ProjectDetailsPage from '../pages/projects/ProjectDetail';
import ProjectList from '../pages/projects/ProjectList';
import ProjectUserManagement from '../pages/RBACListing/ProjectLevel';
import TenantUserManagement from '../pages/RBACListing/TenantLevel';
import ProjectRoleAssignment from '../pages/RoleManagement/Assignment/ProjectLevel';
import ProjectRoleAssignmentEdit from '../pages/RoleManagement/Assignment/ProjectLevelEdit';
import TenantRoleAssignment from '../pages/RoleManagement/Assignment/TenantLevel';
import TenantRoleAssignmentEdit from '../pages/RoleManagement/Assignment/TenantLevelEdit';
import CreateRole from '../pages/RoleManagement/CRUD/createRole';
import EditRole from '../pages/RoleManagement/CRUD/editRole';
import ViewRole from '../pages/RoleManagement/CRUD/viewRole';
import {
    getAccountAndTenantFromCannonicalPath,
    isCannonicalPath,
} from './routeHelper';

export interface NavRouteMap {
    name: string;
    path: string;
    icon?: string;
    translated?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.FC<any>;
    exact?: boolean;
    private?: boolean;
    global?: boolean;
    showOnSideNav?: boolean;
    permission?: AppPermissions[];
    featureFlag?: string;
    pageTitle?: string;
}

const APP_SUFFIX = window.location.href.indexOf('/ai-app') > -1 ? '/ai-app/' : '/';

let CANNONICAL_PREFIX = `${APP_SUFFIX}`;

if (isCannonicalPath()) {
    CANNONICAL_PREFIX = generatePath(`/:account/:tenant/aifabric_${APP_SUFFIX}`, getAccountAndTenantFromCannonicalPath());
}

export class RoutePath {
    static BASE_WITHOUT_AIFABRIC = '/:account/:tenant';
    static BASE = '/:account/:tenant/aifabric_' + APP_SUFFIX;
    static HOME = CANNONICAL_PREFIX;
    static REDIRECT_URL = CANNONICAL_PREFIX + 'callback';

    static DATASET_FOLDER_VIEW_BASE = CANNONICAL_PREFIX + 'datasets/folder';

    static CREATE_PROJECT = CANNONICAL_PREFIX + 'createProject';
    static EDIT_PROJECT_BASE = CANNONICAL_PREFIX + 'editProject';
    static EDIT_PROJECT = CANNONICAL_PREFIX + 'editProject/:projectName';
    static PROJECT_BASE = CANNONICAL_PREFIX + 'projects';
    static PROJECT = CANNONICAL_PREFIX + 'projects/:projectName';
    static PROJECT_DASHBOARD = CANNONICAL_PREFIX + 'projects/:projectName/dashboard';
    static DATASET_FOLDER_VIEW = RoutePath.PROJECT + '/datasets/folder/:datasetId/:datasetFolderName/:currentLocation';

    static PIPELINES = RoutePath.PROJECT + '/pipelines';
    static DATASETS = RoutePath.PROJECT + '/datasets';
    static DATALABELING = RoutePath.PROJECT + '/datalabel';
    static PIPELINE_DATA_BASE = RoutePath.PROJECT + '/pipelineData';
    static PIPELINE_DATA = RoutePath.PROJECT + '/pipelineData/:pipelineRunId';

    static PIPELINE = RoutePath.PROJECT + '/pipelines';

    static CREATE_MLPACKAGE = RoutePath.PROJECT + '/MLPackages/Create';
    static CREATE_DATASET = RoutePath.PROJECT + '/datasets/Create';
    static CREATE_DATASET_FROM_FOLDER = RoutePath.PROJECT + '/datasets/CreateFromFolder/:itemType';
    static EDIT_DATASET_BASE = RoutePath.PROJECT + '/datasets/edit';
    static EDIT_DATASET = RoutePath.PROJECT + '/datasets/edit/:datasetId';
    static UPLOAD_DATASET_BASE = RoutePath.PROJECT + '/datasets/UploadItems';
    static UPLOAD_ITEMS = RoutePath.UPLOAD_DATASET_BASE + '/:datasetId/:datasetFolderName/:currentLocation/:itemType';
    static CREATE_PIPELINE = RoutePath.PROJECT + '/pipelines/Create';
    static EDIT_PIPELINE_BASE = RoutePath.PROJECT + '/pipelines/edit';
    static EDIT_PIPELINE = RoutePath.PROJECT + '/pipelines/edit/:pipelineId/:mlpackageName';

    static MLPACKAGE_VERSION_BASE = RoutePath.PROJECT + '/MLPackages/Versions';
    static VERSION_MLPACKAGE_TEMPLATE = 'UploadNewVersion';
    static MLPACKAGE_VERSION = RoutePath.PROJECT + '/MLPackages/Versions/:mlpackageId/:mlpackageName';
    static NEW_VERSION_MLPACKAGE =
        RoutePath.PROJECT + '/MLPackages/Versions/:mlpackageId/:mlpackageName/' + RoutePath.VERSION_MLPACKAGE_TEMPLATE;

    static MLLOGS_DETAILS = RoutePath.PROJECT + '/MlLogs/details';
    static CREATE_STORAGE = RoutePath.PROJECT + '/storage/create';
    static UIPATH_PROJECTS = RoutePath.PROJECT + '/MLPackages/UiPathProjects';
    static UIPATH_PACKAGES = RoutePath.UIPATH_PROJECTS + '/:uiPathProjectName';
    static UIPATH_PACKAGE = RoutePath.UIPATH_PACKAGES + '/mlPackages/:uiPathPackageId';
    static UIPATH_PACKAGE_CLONE = RoutePath.UIPATH_PACKAGE + '/versions/:uipathPackageVersionId';

    static MLPACKAGES = RoutePath.PROJECT + '/MLPackages';
    static MLSKILLS = RoutePath.PROJECT + '/MLSkills';
    static CREATE_MLSKILL = RoutePath.PROJECT + '/MlSkills/Create';
    static CREATE_DATALABELING = RoutePath.DATALABELING + '/Create';
    static CHOOSE_LABELING_TEMPLATE = RoutePath.DATALABELING + '/ChooseTemplate';
    static LABELING_SESSION_DASHBOARD = RoutePath.DATALABELING + '/labeling/dashboard';
    static MLLOGS = RoutePath.PROJECT + '/MlLogs';
    static SETTINGS = RoutePath.PROJECT + '/Settings';
    static MLSKILL_DETAILS = RoutePath.PROJECT + '/MlSkills/details';

    static LICENSES_DETAILS = CANNONICAL_PREFIX + 'Licenses';
    static PROFILE_PAGE = CANNONICAL_PREFIX + 'profile';
    static IMPORT_MLPACKAGE_OOB = CANNONICAL_PREFIX + 'ImportOOB';
    static IMPORT_MLPACKAGE = RoutePath.PROJECT + '/Import';
    static IMPORT_MLPACKAGE_OOB_LOGS = CANNONICAL_PREFIX + 'Logs/ImportOOB';
    static ProjectUserManagement = CANNONICAL_PREFIX + 'project-user-management';
    static TenantUserManagement = CANNONICAL_PREFIX + 'tenant-user-management';
    static RoleCreation = CANNONICAL_PREFIX + 'role-creation';
    static RoleEdit = CANNONICAL_PREFIX + 'role-edit';
    static RoleView = CANNONICAL_PREFIX + 'role-view';
    static TenantRoleAssignment = CANNONICAL_PREFIX + 'tenant-role-assignment';
    static TenantRoleAssignmentEdit = CANNONICAL_PREFIX + 'tenant-role-assignment/:userId';
    static ProjectRoleAssignment = RoutePath.SETTINGS + '/project-role-assignment';
    static ProjectRoleAssignmentEdit = RoutePath.SETTINGS + '/project-role-assignment/:userId';
}

export default function getDefaultNavRoutes(t: Function): NavRouteMap[] {
    const GLOBAL_PAGE_TITLE = ' - AI Center';
    const DASHBOARD_PAGE_TITLE = t('nav_dashboard') + GLOBAL_PAGE_TITLE;
    const DATASETS_PAGE_TITLE = t('dataset_title') + GLOBAL_PAGE_TITLE;
    const DATA_LABELING_PAGE_TITLE = t('nav_data_labeling') + GLOBAL_PAGE_TITLE;
    const ML_PACKAGES_PAGE_TITLE = t('ml_packages_title') + GLOBAL_PAGE_TITLE;
    const PIPELINES_PAGE_TITLE = t('pipeline_list_title') + GLOBAL_PAGE_TITLE;
    const CREATE_PIPELINES_PAGE_TITLE = t('create_pipeline_title') + GLOBAL_PAGE_TITLE;
    const ML_SKILLS_PAGE_TITLE = t('ml_skills_title') + GLOBAL_PAGE_TITLE;
    const ML_LOGS_PAGE_TITLE = t('ml_logs_title') + GLOBAL_PAGE_TITLE;
    const SETTINGS_PAGE_TITLE = t('settings') + GLOBAL_PAGE_TITLE;
    const PROJECT_PAGE_TITLE = t('projects') + GLOBAL_PAGE_TITLE;
    const VIEW_PROFILE_PAGE_TITLE = t('profile_page_label') + GLOBAL_PAGE_TITLE;
    const LICENCE_USAGE_PAGE_TITLE = t('license_usage') + GLOBAL_PAGE_TITLE;
    const USER_MGMT_PAGE_TITLE = t('user_management_label') + GLOBAL_PAGE_TITLE;
    return [
        {
            name: 'nav_dashboard',
            translated: true,
            path: RoutePath.PROJECT_DASHBOARD,
            icon: 'nfc',
            component: ProjectDetailsPage,
            exact: true,
            showOnSideNav: true,
            private: true,
            pageTitle: DASHBOARD_PAGE_TITLE,
        },
        {
            name: 'dataset_title',
            translated: true,
            path: RoutePath.DATASETS,
            icon: 'nfc',
            component: DataSetsPage,
            exact: true,
            showOnSideNav: true,
            private: true,
            permission: [ AppPermissions.MLStorage_View, AppPermissions.MLPackages ],
            pageTitle: DATASETS_PAGE_TITLE,
        },
        {
            name: 'nav_data_labeling',
            translated: true,
            path: RoutePath.DATALABELING,
            icon: 'nfc',
            component: DataLabelingSessionsPage,
            exact: true,
            showOnSideNav: true,
            private: true,
            pageTitle: DATA_LABELING_PAGE_TITLE,
            permission: [ AppPermissions.DataLabeling_View ],
        },
        {
            name: 'ml_packages_title',
            translated: true,
            path: RoutePath.MLPACKAGES,
            icon: 'nfc',
            component: PackageList,
            exact: true,
            showOnSideNav: true,
            private: true,
            permission: [ AppPermissions.MLPackages_View, AppPermissions.MLPackages ],
            pageTitle: ML_PACKAGES_PAGE_TITLE,
        },
        {
            name: 'pipeline_list_title',
            translated: true,
            path: RoutePath.PIPELINES,
            icon: 'swap_calls',
            component: PipelineListPage,
            exact: true,
            showOnSideNav: true,
            private: true,
            permission: [ AppPermissions.MLPipeline_View, AppPermissions.MLPackages ],
            pageTitle: PIPELINES_PAGE_TITLE,
        },
        {
            name: 'ml_skills_title',
            translated: true,
            path: RoutePath.MLSKILLS,
            icon: 'nfc',
            component: MlSkillListPage,
            exact: true,
            showOnSideNav: true,
            private: true,
            permission: [ AppPermissions.MLSkills_View, AppPermissions.MLSkills ],
            pageTitle: ML_SKILLS_PAGE_TITLE,
        },
        {
            name: 'ml_logs_title',
            path: RoutePath.MLLOGS,
            translated: true,
            icon: 'nfc',
            component: MlLogsPage,
            exact: true,
            showOnSideNav: true,
            private: true,
            permission: [ AppPermissions.MLLogs_View, AppPermissions.MLLogs ],
            pageTitle: ML_LOGS_PAGE_TITLE,
        },
        {
            name: 'settings',
            path: RoutePath.SETTINGS,
            translated: true,
            icon: 'nfc',
            component: ProjectUserManagement,
            exact: true,
            showOnSideNav: true,
            private: true,
            permission: [ AppPermissions.Settings_View ],
            pageTitle: SETTINGS_PAGE_TITLE,
        },
        {
            name: 'datasets folders',
            path: RoutePath.DATASET_FOLDER_VIEW,
            component: DatasetFolderPage,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLStorage_View, AppPermissions.MLPackages ],
            pageTitle: DATASETS_PAGE_TITLE,
        },
        {
            name: 'Pipeline data',
            path: RoutePath.PIPELINE_DATA,
            component: PipelineDataPage,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPipeline_View, AppPermissions.MLPackages ],
            pageTitle: PIPELINES_PAGE_TITLE,
        },
        {
            name: 'createProject',
            path: RoutePath.CREATE_PROJECT,
            component: ProjectCreate,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.MLProjects_Create, AppPermissions.MLPackages ],
            pageTitle: PROJECT_PAGE_TITLE,
        },
        {
            name: 'editProject',
            path: RoutePath.EDIT_PROJECT,
            component: ProjectCreate,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.MLProjects_Edit, AppPermissions.MLPackages ],
            pageTitle: PROJECT_PAGE_TITLE,
        },
        {
            name: 'listProject',
            path: RoutePath.HOME,
            component: ProjectList,
            exact: true,
            private: true,
            global: true,
            pageTitle: PROJECT_PAGE_TITLE,
        },
        {
            name: 'callback',
            path: RoutePath.REDIRECT_URL,
            component: CallbackPage,
            exact: true,
            global: true,
        },
        {
            name: 'createMLPackage',
            path: RoutePath.CREATE_MLPACKAGE,
            component: MLPackageCreate,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPackages_Create, AppPermissions.MLPackages ],
            pageTitle: ML_PACKAGES_PAGE_TITLE,
        },
        {
            name: 'createMLPackageVersion',
            path: RoutePath.NEW_VERSION_MLPACKAGE,
            component: MLPackageCreate,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPackages_Create, AppPermissions.MLPackages_Edit ],
            pageTitle: ML_PACKAGES_PAGE_TITLE,
        },
        {
            name: 'importOOBMLPackage',
            path: RoutePath.IMPORT_MLPACKAGE_OOB,
            component: MLPackageImportOOB,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.OOBUpload_Create ],
        },
        {
            name: 'importMLPackage',
            path: RoutePath.IMPORT_MLPACKAGE,
            component: MLPackageImport,
            exact: true,
            private: true,
            global: false,
            permission: [ AppPermissions.MLPackages_Create ],
        },
        {
            name: 'importOOBMLPackageLogs',
            path: RoutePath.IMPORT_MLPACKAGE_OOB_LOGS,
            component: ImportOOBLogs,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.OOBUpload_Create, AppPermissions.OOBUpload_View ],
        },
        {
            name: 'createPipeline',
            path: RoutePath.CREATE_PIPELINE,
            component: PipelineCreate,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPipeline_Create, AppPermissions.MLPackages ],
            pageTitle: CREATE_PIPELINES_PAGE_TITLE,
        },
        {
            name: 'editPipeline',
            path: RoutePath.EDIT_PIPELINE,
            component: PipelineCreate,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPipeline_Edit, AppPermissions.MLPackages ],
            pageTitle: PIPELINES_PAGE_TITLE,
        },
        {
            name: 'createDatasets',
            path: RoutePath.CREATE_DATASET,
            component: DatasetCreate,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLStorage_Create, AppPermissions.MLPackages ],
            pageTitle: DATASETS_PAGE_TITLE,
        },
        {
            name: 'createDatasetFromFolder',
            path: RoutePath.CREATE_DATASET_FROM_FOLDER,
            component: UploadItems,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLStorage_Create, AppPermissions.MLPackages ],
            pageTitle: DATASETS_PAGE_TITLE,
        },
        {
            name: 'editDataset',
            path: RoutePath.EDIT_DATASET,
            component: DatasetCreate,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLStorage_Edit, AppPermissions.MLPackages ],
            pageTitle: DATASETS_PAGE_TITLE,
        },
        {
            name: 'uploadFiles',
            path: RoutePath.UPLOAD_ITEMS,
            component: UploadItems,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLStorage_Edit, AppPermissions.MLPackages ],
            pageTitle: DATASETS_PAGE_TITLE,
        },
        {
            name: 'mlpackageVersions',
            path: RoutePath.MLPACKAGE_VERSION,
            component: MLPackageVersionsPage,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPackages_View, AppPermissions.MLPackages ],
            pageTitle: ML_PACKAGES_PAGE_TITLE,
        },
        {
            name: 'mlLogsDetails',
            path: RoutePath.MLLOGS_DETAILS,
            component: MlLogsDetailsPage,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLLogs_View, AppPermissions.MLLogs ],
            pageTitle: ML_LOGS_PAGE_TITLE,
        },
        {
            name: 'createMlSkills',
            path: RoutePath.CREATE_MLSKILL,
            component: MlSkillCreate,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLSkills_Create, AppPermissions.MLSkills ],
            pageTitle: ML_SKILLS_PAGE_TITLE,
        },
        {
            name: 'mlSkillsDetails',
            path: RoutePath.MLSKILL_DETAILS,
            component: MlSkillDetailsPage,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLSkills_View, AppPermissions.MLSkills ],
            pageTitle: ML_SKILLS_PAGE_TITLE,
        },
        {
            name: 'uipathProjects',
            path: RoutePath.UIPATH_PROJECTS,
            component: UiPathProjects,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPackages_View, AppPermissions.MLPackages ],
            pageTitle: ML_PACKAGES_PAGE_TITLE,
        },
        {
            name: 'uipathPackages',
            path: RoutePath.UIPATH_PACKAGES,
            component: UiPathPackages,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPackages_View, AppPermissions.MLPackages ],
        },
        {
            name: 'uipathPackage',
            path: RoutePath.UIPATH_PACKAGE,
            component: UiPathPackageContainer,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPackages_View, AppPermissions.MLPackages ],
        },
        {
            name: 'uipathPackageClone',
            path: RoutePath.UIPATH_PACKAGE_CLONE,
            component: UiPathPackageClone,
            exact: true,
            private: true,
            permission: [ AppPermissions.MLPackages_View, AppPermissions.MLPackages ],
        },
        {
            name: 'Licenses',
            path: RoutePath.LICENSES_DETAILS,
            component: LicenseDetail,
            exact: true,
            private: true,
            global: true,
            pageTitle: LICENCE_USAGE_PAGE_TITLE,
            permission: [ AppPermissions.Licenses_View ],
        },
        {
            name: 'createDatalabelingSession',
            path: RoutePath.CREATE_DATALABELING,
            component: DataLabelingSessionCreate,
            exact: true,
            private: true,
            pageTitle: DATA_LABELING_PAGE_TITLE,
            permission: [ AppPermissions.DataLabeling_Create, AppPermissions.MLPackages ],
        },
        {
            name: 'chooseLabellingTemplate',
            path: RoutePath.CHOOSE_LABELING_TEMPLATE,
            component: OOBLabelingTemplates,
            exact: true,
            private: true,
            pageTitle: DATA_LABELING_PAGE_TITLE,
            permission: [ AppPermissions.DataLabeling_Create, AppPermissions.MLPackages ],
        },
        {
            name: 'chooseLabellingTemplate',
            path: RoutePath.LABELING_SESSION_DASHBOARD,
            component: LabelingSessionDashboard,
            exact: true,
            private: true,
            pageTitle: DATA_LABELING_PAGE_TITLE,
            permission: [ AppPermissions.DataLabeling_View, AppPermissions.MLPackages ],
        },
        {
            name: 'ProfilePage',
            path: RoutePath.PROFILE_PAGE,
            component: ProfilePage,
            exact: true,
            private: true,
            global: true,
            pageTitle: VIEW_PROFILE_PAGE_TITLE,
            permission: [ AppPermissions.Profile_View ],
        },
        {
            name: 'TenantUserManagement',
            path: RoutePath.TenantUserManagement,
            component: TenantUserManagement,
            exact: true,
            private: true,
            global: true,
            pageTitle: USER_MGMT_PAGE_TITLE,
            permission: [ AppPermissions.Users_View ],
        },
        {
            name: 'RoleCreation',
            path: RoutePath.RoleCreation,
            component: CreateRole,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.Roles_Create ],
        },
        {
            name: 'RoleEdit',
            path: RoutePath.RoleEdit,
            component: EditRole,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.Roles_Edit ],
        },
        {
            name: 'RoleView',
            path: RoutePath.RoleView,
            component: ViewRole,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.Roles_View ],
        },
        {
            name: 'TenantRoleAssignment',
            path: RoutePath.TenantRoleAssignment,
            component: TenantRoleAssignment,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.Users_Edit ],
        },
        {
            name: 'TenantRoleAssignmentEdit',
            path: RoutePath.TenantRoleAssignmentEdit,
            component: TenantRoleAssignmentEdit,
            exact: true,
            private: true,
            global: true,
            permission: [ AppPermissions.Users_Edit ],
        },
        {
            name: 'ProjectRoleAssignment',
            path: RoutePath.ProjectRoleAssignment,
            component: ProjectRoleAssignment,
            exact: true,
            private: true,
            permission: [ AppPermissions.Settings_Create ],
        },
        {
            name: 'ProjectRoleAssignmentEdit',
            path: RoutePath.ProjectRoleAssignmentEdit,
            component: ProjectRoleAssignmentEdit,
            exact: true,
            private: true,
            permission: [ AppPermissions.Settings_Create ],
        },
    ];
}
