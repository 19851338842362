import HelpIcon from '@mui/icons-material/Help';
import {
    Button,
    Grid,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEventListener } from './AccessibleTooltip/AccessibleTooltip';
import TooltipContainer from './AccessibleTooltip/TooltipContainer';
import { EnvironmentVariablePopup } from './Dialog';

const EnvironmentVariablesTableContainer = styled('div')(({ theme }) => ({
    marginTop: '25px',
    backgroundColor: theme.palette.background.paper,
    width: '36rem',

    '& .MuiOutlinedInput-input': {
        padding: 0,
        paddingLeft: '12px',
        height: '30px',
    },

    '& .MuiTableCell-root': {
        verticalAlign: 'top',
        fontSize: `${Tokens.FontFamily.FontMSize} !important`,

        '&.header': { width: '12rem' },

        '&.leftAlign': {
            paddingLeft: '28px',

            '& .leftAlignRevert': { marginLeft: '-16px' },
        },

        '&.value': { width: '12rem' },
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.semantic.colorForegroundLink,
        borderWidth: 2,
    },
}));

const TitleHeader = styled(Typography)({
    fontSize: '14px',
    fontWeight: 'bold',
});

const RightAlignedTableCell = styled(TableCell)({
    textAlign: 'right',
    verticalAlign: 'middle !important',
    padding: '0',

    '& button': { '&.verticalFix': { marginBottom: '1.8rem' } },
});

const AddNewButton = styled(Button)({
    textTransform: 'none',
    fontSize: Tokens.FontFamily.FontMSize,
    fontWeight: Tokens.FontFamily.FontWeightDefault,
    margin: 0,
    marginTop: '7px',
});

export interface ParametersFileEnvironmentVariable {
    name: string;
    type: string[];
    value?: string;
    help?: string;
    options?: string[];
}

export interface EnvironmentVariable {
    name: string;
    value: string;
}

export enum ParametersFileKeys {
    PARAMS = 'params',
}

export enum ParametersFileParamsKeyEntries {
    NAME = 'name',
    VALUE = 'value',
    TYPE = 'type',
    HELP = 'help',
    OPTIONS = 'options',
}

export enum ParametersFileParamsKeyPossibleTypes {
    MLPACKAGE = 'MLPACKAGE',
    MLSKILL = 'MLSKILL',
    PIPELINE = 'PIPELINE',
}

interface EnvironmentVariablesTableProps {
    id: string;
    title: string;
    tableData: ParametersFileEnvironmentVariable[];
    setTableData: (state: ParametersFileEnvironmentVariable[]) => void;
}

export const EnvironmentVariablesTable: React.FC<EnvironmentVariablesTableProps> = (props: EnvironmentVariablesTableProps) => {

    const { t } = useTranslation();

    const [ tableData, setTableData ] = React.useState<ParametersFileEnvironmentVariable[]>(props.tableData);
    const [ open, setOpen ] = React.useState(false);
    const [ tooltipOpen, setTooltipOpen ] = React.useState(false);
    const onOpen = () => setTooltipOpen(true);
    const onClose = () => setTooltipOpen(false);

    const handler = React.useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }, []);

    useEventListener('keydown', handler);

    React.useEffect(() => {
        setTableData(props.tableData);
    }, [ props.tableData ]);

    React.useEffect(() => {
        props.setTableData(tableData);
    }, [ tableData ]);

    return (
        <EnvironmentVariablesTableContainer
            id={props.id}>
            <EnvironmentVariablePopup
                open={open}
                handleClose={(): void => setOpen(false)}
                tableData={tableData}
                setTableData={setTableData}
                title={t('pipeline_create_parameters_label')} />
            <TableContainer>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems='flex-start'>
                    <Grid item>
                        <TooltipContainer title={t('tooltip_pipelines_env_vars')}>
                            <TitleHeader
                                variant="h2">
                                {props.title}
                            </TitleHeader>
                        </TooltipContainer>
                    </Grid>
                    <Grid item>
                        <AddNewButton
                            color="secondary"
                            onClick={(): void => {
                                setOpen(true);
                            }}
                            data-testid="configure_env_variables_button"
                            aria-label={t('evtable_configure_button_label')}
                        >
                            {t('evtable_configure_button_label')}
                        </AddNewButton>
                    </Grid>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="header">
                                {t('evtable_ev_header')}
                            </TableCell>
                            <TableCell
                                className="value"
                                data-testid="evtable_val_header">
                                {t('evtable_val_header')}
                            </TableCell>
                            <TableCell className="iconsColumn" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (!tableData || !tableData.length) ? (
                                <TableRow>
                                    <TableCell
                                        className="emptyTableRow leftAlign"
                                        colSpan={3}>
                                        {t('evtable_empty_ph')}
                                    </TableCell>
                                </TableRow>
                            ) :
                                tableData.map((data, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell className="leftAlign">
                                            { data.name }
                                        </TableCell>
                                        <TableCell className="leftAlign">
                                            { data.value }
                                        </TableCell>
                                        <RightAlignedTableCell>
                                            <Tooltip
                                                title={data.help || 'Help'}
                                                open={tooltipOpen}
                                                onOpen={onOpen}
                                                onClose={onClose}
                                                // interactive
                                            >
                                                <IconButton size="small">
                                                    <HelpIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </RightAlignedTableCell>
                                    </TableRow>
                                ),
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </EnvironmentVariablesTableContainer>
    );
};
