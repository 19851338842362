import type { ActionType } from '../Actions';
import { AppMetaActions } from '../Actions';
import type { AppMetaState } from '../states.d';

export const AuthorizationReducer = (
    state: AppMetaState = {
        failureCode: null,
        backendCode: null,
    },
    action: ActionType,
): AppMetaState => {
    switch (action.type) {
        case AppMetaActions.ACCOUNTCREDS_UPDATE:
            return {
                ...state,
                accountId: action.payload.accountId,
                tenantId: action.payload.tenantId,
                groupIds: action.payload.groupIds,
            };
        case AppMetaActions.FAIL:
            return {
                ...state,
                failureCode: action.payload.status,
                backendCode: action.payload.backendCode,
            };
        default:
            return state;
    }
};
