import { TextField } from '@mui/material';
import type { FieldAttributes } from 'formik';
import { useField } from 'formik';
import React from 'react';

const AttributeTextField: React.FC<FieldAttributes<{ placeholder: string }>> = ({
    placeholder, className, ...props
}) => {
    const [ field, meta ] = useField<{ placeholder: string }>(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <div className={className}>
            <TextField
                {...field}
                helperText={errorText}
                placeholder={placeholder}
                error={!!errorText}
                variant="outlined"
                inputProps={{ 'data-testid': 'attribute-text-editor' }} />
        </div>
    );
};

export default AttributeTextField;
