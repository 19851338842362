import { ArrowDropDown } from '@mui/icons-material';
import {
    Button,
    Checkbox,
    Menu,
    MenuItem,
    Radio,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FilterOption {
    key: string;
    value: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFilterFromOptions = (options: FilterOption[], spy?: any) => (
    // @ts-ignore
    function SelectColumnFilter({
        column: {
            setFilter, apiField, Header,
        }, gotoPage,
    }: any): React.ReactElement {
        const [ fields, setFields ] = useState<{ [k: string]: boolean }>({});
        const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget);
        const { t } = useTranslation();
        const handleClose = (): void => {
            setAnchorEl(null);
            const str = (Object.keys(fields).filter(field => fields[field]))
                .join(',');
            gotoPage(0);
            setFilter({
                apiField,
                value: str,
            });
        };
        const [ isAll, setIsAll ] = useState(true);

        if (spy) {
            spy.handleClose = handleClose;
        }

        return (
            <>
                <Button
                    data-testid={`filter-btn-${apiField}`}
                    aria-controls="simple-menu"
                    aria-label={t('a11y_open_filter') + apiField}
                    aria-haspopup="true"
                    onClick={handleClick}>
                    { Header }
                    {' '}
                    <ArrowDropDown fontSize="small" />
                </Button>
                <Menu
                    id={'filter-menu-' + apiField}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem>
                        <Checkbox
                            checked={isAll}
                            data-testid={`filter-option-${apiField}-all`}
                            onChange={(event): void => {
                                if (event.target.value) {
                                    setFields({});
                                    setIsAll(true);
                                    return;
                                }
                                if (!(Object.keys(fields).filter(field => fields[field])).length) {
                                    setIsAll(true);
                                }
                            }} />
                        {t('label_all')}
                    </MenuItem>
                    {
                        options.map((option) => (<MenuItem key={`filter-option-${apiField}-${option.key}`} >
                            <Checkbox
                                data-testid={`filter-option-${apiField}-${option.key}`}
                                checked={fields[option.key]}
                                onChange={(): void => {
                                    setFields({
                                        ...fields,
                                        [option.key]: !fields[option.key],
                                    });
                                    setIsAll(false);
                                }} />
                            {option.value}
                        </MenuItem>))
                    }
                </Menu>
            </>
        );
    }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFilterRadioFromOptions = (options: FilterOption[], spy?: any) => (
    // @ts-ignore
    function SelectColumnFilter({
        column: {
            setFilter, apiField, Header,
        }, gotoPage,
    }: any): React.ReactElement {
        const [ selected, setSelected ] = useState('');
        const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
        const { t } = useTranslation();
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget);
        const handleClose = (): void => {
            setAnchorEl(null);
            gotoPage(0);
            setFilter({
                apiField,
                value: selected,
            });
        };

        if (spy) {
            spy.handleClose = handleClose;
        }

        return (
            <>
                <Button
                    data-testid={`filter-btn-${apiField}`}
                    aria-label={t('a11y_open_filter') + apiField}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    { Header }
                    {' '}
                    <ArrowDropDown fontSize="small" />
                </Button>
                <Menu
                    id={'filter-menu-' + apiField}
                    style={{ marginLeft: '10px' }}
                    key="filters-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorReference="anchorEl"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem>
                        <Radio
                            checked={selected === ''}
                            data-testid={`filter-option-${apiField}-all`}
                            onChange={(): void => setSelected('')}
                        />
                        {t('label_all')}
                    </MenuItem>
                    {
                        options.map((option) => (<MenuItem key={`menu-filter-option-${apiField}-${option.key}`}>
                            <Radio
                                data-testid={`filter-option-${apiField}-${option.key}`}
                                checked={selected === option.key}
                                onChange={(): void => setSelected(option.key)}
                            />
                            {option.value}
                        </MenuItem>))
                    }
                </Menu>
            </>
        );
    }
);
