export const initializeLabelStudio = (template: string) => {
    let instance;
    try {
        instance = new (window as any).LabelStudio('label-studio', {
            config: template,
            interfaces: [],
            task: { data: { 'text': 'To have faith is to trust yourself to the water' } },
            onLabelStudioLoad: (LS: any): void => {
                try {
                    const c = LS.annotationStore.addAnnotation({ userGenerate: true });
                    LS.annotationStore.selectAnnotation(c.id);
                } catch (e) {
                    console.error('Wrong template content');
                }
            },
        });
    } catch (e) {
        console.error('Error loading label studio');
    }
    return instance;
};
