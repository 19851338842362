import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Authentication } from '../enums/Authentication';
import { RoutePath } from '../route/routeMap';

interface CallbackPageProps {
    userState: Authentication;
    authToken: string;
}
const CallbackPage: React.FC<CallbackPageProps> = ({
    userState, authToken,
}) => {
    const history = useHistory();
    useEffect(() => {
        if (userState === Authentication.LOGGEDIN && authToken) {
            history.push(RoutePath.HOME);
        }
    }, [ userState, authToken ]);

    return <div />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    userState: state.auth.userState,
    authToken: state.auth.authToken,
}))(CallbackPage);
