import {
    Breadcrumbs,
    Link,
    styled,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import type {
    ColumnDef,
    Row,
} from '@tanstack/react-table';
import type { DatasetDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import React, {
    useCallback,
    useContext,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    connect,
    useSelector,
} from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';

import {
    deleteDatasetDownload,
    downloadDataset,
    rotateKey,
    updateDatasetAfterStructureChange,
} from '../../../api/client/datasetManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import {
    CustomDialog,
    DialogPopup,
    DialogType,
} from '../../../components/Dialog';
import Section from '../../../components/Section';
import type { SelectionItem } from '../../../components/Table/BaseTable/BaseTable';
import type { ResponsiveColumnDefinition } from '../../../components/Table/BaseTable/types';
import { getFilterRadioFromOptions } from '../../../components/Table/FilterUtils';
import ServerSideTableGCS
    from '../../../components/Table/ServerSideTable/ServerSideTableGCS';
import { WithVisibility } from '../../../components/WithVisibility';
import URLManager from '../../../config/URLManager';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../../constants/BlobStorageConstants';
import TableHeaderMappings from '../../../constants/TableHeaderMappings';
import { AppPermissions } from '../../../enums/Authorization';
import { BaseTableIcons } from '../../../enums/BaseTableIcons';
import type FeatureFlagManager from '../../../feature-flag/FeatureFlagManager';
import { http } from '../../../http';
import i18n from '../../../il8n';
import { PermissionsContext } from '../../../providers/PermissionsProvider';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    downloadContentWithSignUrl,
    extractErrorMessage,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import logger from '../../../utils/Logging';
import { ItemType } from '../upload/UploadItems';
import { DSDetailsData } from './DSDetailsData';

enum currentTab {
    DATASET,
    DOWNLOAD,
}

const DialogRootContainer = styled('div')({ fontSize: Tokens.FontFamily.FontMSize });

const DataSetFoldersPageContainer = styled('div')({
    fontSize: Tokens.FontFamily.FontMSize,
    width: '100%',
    fontFamily: Tokens.FontFamily.FontNormal,
    overflowY: 'auto',
    lineHeight: Tokens.FontFamily.FontMLineHeight,
});

const DatasetFolderPageTabContainer = styled('div')({
    width: '100%',

    '& .tabBarArea': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '15px',

        '& .tabBar': { width: '53%' },
    },
});

interface DataSetBreadCrumb {
    name: string;
    type: 'LINK' | 'TEXT';
    link: string;
    index: number;
}

interface DatasetFolderPageProps {
    isOnPrem: boolean;
    featureManager: FeatureFlagManager;
    isInstanceProfileEnabled: boolean;
    azureStorageFQDNSuffix: string;
}

export const DatasetFolderPageFC: React.FC<DatasetFolderPageProps> = ({
    isOnPrem, featureManager, isInstanceProfileEnabled, azureStorageFQDNSuffix,
}) => {

    const { t } = useTranslation();
    const dataMapper: Array<ColumnDef<any, any>> = [
        ...TableHeaderMappings.DatasetsFolderMappingsBase,
    ];
    const authToken = useSelector((state: any) => state.auth.authToken);
    const {
        datasetFolderName, datasetId, currentLocation,
    } = useParams<{ datasetId: string; datasetFolderName: string; currentLocation: string }>();
    const [ deletedFlag, setDeletedFlag ] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ selectedItems, setSelectedItems ] = React.useState<Array<Row<any>>>([]);
    const history = useHistory();
    const feedback = useFeedback();

    const generateDownloadUrl = (value: string | number | boolean) => URLManager.url().apiTrainer + '/signedURL?blobName=' + encodeURIComponent(value) + '&signingMethod=GET&urlEncoded=true';

    const dataDownloadMapper: ResponsiveColumnDefinition[] = [
        {
            header: `${i18n.t('common_created_label')}`,
            accessorKey: 'createdOn',
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
            smDown: true,
        },
        {
            header: `${i18n.t('common_status_label')}`,
            accessorKey: 'status',
            cell: ({ cell: { getValue } }): string => t(`DATASET_DOWNLOAD_STATUS_${getValue()}`),
            meta: {
                filter: getFilterRadioFromOptions([
                    {
                        key: 'DOWNLOADING',
                        value: t('DATASET_DOWNLOAD_STATUS_DOWNLOADING'),
                    },
                    {
                        key: 'COMPLETED',
                        value: t('DATASET_DOWNLOAD_STATUS_COMPLETED'),
                    },
                    {
                        key: 'FAILED',
                        value: t('DATASET_DOWNLOAD_STATUS_FAILED'),
                    },
                    {
                        key: 'DELETED',
                        value: t('DATASET_DOWNLOAD_STATUS_DELETED'),
                    },
                ]),
            },
            smDown: true,
        },
        {
            header: `${i18n.t('dataset_download_url')}`,
            accessorKey: 'downloadUrl',
            cell: ({ cell: { getValue } }) => <a
                style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'unset',
                }}
                onClick={() => {
                    downloadContentWithSignUrl(generateDownloadUrl(getValue()), isInstanceProfileEnabled, authToken, azureStorageFQDNSuffix);
                }}>
                {!getValue() ? '' : t('common_download_label')}
            </a>,
            smDown: true,
        },
    ];

    const { currentProject } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProject?.id ?? '']?.permissionSet || [];

    const blobMetadataResponse = {
        original: {
            blobType: '',
            blobName: '',
        },
    };

    const [ breadCrumbs, setBreadCrumbs ] = useState<DataSetBreadCrumb[]>(
        [
            {
                name: t('dataset_title'),
                link: '#',
                type: 'LINK',
                index: 0,
            },
            {
                name: datasetFolderName || '',
                link: '#',
                type: 'LINK',
                index: 1,
            },
        ],
    );

    const initialDSDto: DatasetDto = { encrypted: false };
    const [ dsDto, setDSDto ] = React.useState<DatasetDto>(initialDSDto);
    const [ openRotateSkillDialog, setOpenRotateSkillDialog ] = React.useState(false);

    const [ curTab, setCurTab ] = React.useState(currentTab.DATASET);
    const [ downloadUrl, setDownloadUrl ] = useState('');
    const [ itemDeletedFlag, setItemDeletedFlag ] = useState(false);
    const [ datasetDownloadEnabled, setDatasetDownloadEnabled ] = useState(false);

    const isDatasetDownloadFeatureEnabled = featureManager ? featureManager.isEnabled('dataset-download-enabled') : false;

    React.useEffect(() => {
        setDatasetDownloadEnabled(isDatasetDownloadFeatureEnabled && !dsDto.encrypted);
    }, [ dsDto ]);

    const icons = [];
    if ((permissions.indexOf(AppPermissions.MLStorage_Delete) > -1)) {
        icons.push({
            iconType: BaseTableIcons.DELETE,
            click: async (_event: any, rowInfo: any): Promise<void> => {
                await deleteDatasetDownload(rowInfo.original.id, datasetId, currentProject?.id)
                    .then(() => {
                        feedback.enqueueSuccess(t('feedback_delete_success_dataset_download'));
                        setDeletedFlag(flag => !flag);
                        return true;
                    })
                    .catch((error: any) => {
                        /** Don't do anything if request is cancelled by debounce action */
                        if (!axios.isCancel(error)) {
                            logger.error({
                                identifier: 'Download details',
                                message: 'Error while deleting dataset download',
                                error,
                            });
                            feedback.enqueueError(t('error_deleting_dataset_download'));
                        }
                    },
                    );
            },
        });
    }

    React.useEffect(() => {
        if (datasetFolderName && currentProject && currentProject.name && currentLocation) {

            const path = decodeURIComponent(currentLocation).split('/')
                .filter(p => true && p);

            breadCrumbs[0].link = generatePath(RoutePath.DATASETS, { projectName: currentProject.name });

            let curr = '';
            const crumbs = path.map((p, index) => {
                curr += p + '/';
                const link = generatePath(RoutePath.DATASET_FOLDER_VIEW, {
                    projectName: currentProject.name,
                    datasetId,
                    datasetFolderName,
                    currentLocation: encodeURIComponent(curr),
                });
                return {
                    name: p,
                    link,
                    type: 'LINK',
                    index: index + 1,
                } as DataSetBreadCrumb;
            });

            curr = curr.substring(datasetFolderName.length + 1);
            const newBreadCrumbs = [ breadCrumbs[0] ].concat(crumbs);
            newBreadCrumbs[newBreadCrumbs.length - 1].type = 'TEXT';

            setBreadCrumbs(newBreadCrumbs);
            setUrl(`${URLManager.url().apiTrainer}/datasets/listDataset?directoryName=${`${currentProject?.id + '/' + datasetId}/` + curr}&projectId=${currentProject?.id}`);
            setDownloadUrl(`${URLManager.url().apiTrainer}/datasets:download/?projectId=${currentProject?.id}&datasetId=${datasetId}`);

            http.get(`${URLManager.url().apiTrainer}/datasets/${datasetId}/?projectId=${currentProject?.id}`, {}).then((response: AxiosResponse) => {
                setDSDto(response.data.data);
                return true;
            })
                .catch((error: any) => {
                    /** Don't do anything if request is cancelled by debounce action */
                    if (!axios.isCancel(error)) {
                        logger.error({
                            identifier: 'Dataset details',
                            message: 'Error making API call',
                            error,
                        });
                        setDSDto({});
                        feedback.enqueueError(t('feedback_datasets_loading_error'));
                    }
                });
        }

    }, [ currentProject, currentLocation ]);

    const [ url, setUrl ] = useState('');
    const [ open, setOpen ] = useState(false);
    const [ currentRow, setCurrentRow ] = useState(blobMetadataResponse);

    const selectionItems: SelectionItem | undefined = (permissions.indexOf(AppPermissions.MLStorage_View) > -1) ?
        {
            text: 'dataset_delete_selected_items_text',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (data: Array<Row<any>>): void => {
                setSelectedItems(data);
                setCurrentRow(blobMetadataResponse);
                setOpen(true);
            },
        } : undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDataSetClicked = (rowInfo: any): void => {
        if (rowInfo.original.blobType === 'DIRECTORY') {
            const fromStr: string[] = rowInfo.original.blobName.split(`${datasetId}/`).pop();
            history.push(generatePath(RoutePath.DATASET_FOLDER_VIEW, {
                projectName: currentProject?.name,
                datasetId,
                datasetFolderName,
                currentLocation: datasetFolderName + '/' + fromStr,
            }));
        }
    };

    const closeDialog = (): void => {
        setOpen(false);
    };

    const deleteDatasetSelection = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const promises = selectedItems.map((item: Row<any>) => http.delete(URLManager.url().apiTrainer + '/datasets/files?blobType=' + item.original?.blobType + '&fileName=' + encodeURIComponent(item?.original.blobName) + '&projectId=' + currentProject?.id));
        Promise.all(promises)
            .then(() => {
                closeDialog();
                feedback.enqueueSuccess(t('feedback_delete_success_item'));

                // update dataset with last modified date
                return updateDatasetAfterStructureChange(datasetId, currentProject?.id);
            })
            .finally(() => {
                /* Refresh Table */
                setSelectedItems([]);
                setItemDeletedFlag(prev => !prev);
            })
            .catch(error => {
                closeDialog();
                logger.error({
                    identifier: 'Dataset folders',
                    message: 'Error deleting dataset selection',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(error, t('feedback_delete_error_item'), { 10101: {} }));
            });
    };

    const deleteDatasetFile = (): void => {
        setOpen(false);
        const name = encodeURIComponent(currentRow?.original?.blobName);
        const deleteBlobURL = URLManager.url().apiTrainer + '/datasets/files?blobType=' + currentRow?.original?.blobType + '&fileName=' + name + '&projectId=' + currentProject?.id;
        http.delete(deleteBlobURL)
            .then(() => {
                feedback.enqueueSuccess(t('feedback_delete_success_item'));

                // update dataset with last modified date
                return updateDatasetAfterStructureChange(datasetId, currentProject?.id);
            })
            .finally(() => {
                setCurrentRow(blobMetadataResponse);
                setItemDeletedFlag(prev => !prev);
            })
            .catch((error) => {
                logger.error({
                    identifier: 'Dataset',
                    message: 'Error deleting dataset file',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(error, t('feedback_delete_error_item'), { 10101: {} }));
            });
    };

    const buttonItems = (permissions.indexOf(AppPermissions.MLStorage_Edit) > -1) ? [
        {
            buttonText: 'dataset_edit_label',
            buttonCallback: (): void => history.push(generatePath(RoutePath.EDIT_DATASET, {
                projectName: currentProject?.name,
                datasetId,
            })),
        },
        {
            buttonText: 'dataset_upload_folder_label',
            buttonCallback: (): void => (
                history.push(generatePath(RoutePath.UPLOAD_ITEMS, {
                    projectName: currentProject?.name,
                    datasetId,
                    datasetFolderName,
                    currentLocation: encodeURIComponent(breadCrumbs.slice(1).reduce((prev, curr) => prev + '/' + curr.name, '')
                        .substring(1)),
                    itemType: ItemType.FOLDER,
                }))
            ),
        },
        {
            buttonText: 'dataset_upload_files_label',
            buttonCallback: (): void => (
                history.push(generatePath(RoutePath.UPLOAD_ITEMS, {
                    projectName: currentProject?.name,
                    datasetId,
                    datasetFolderName,
                    currentLocation: encodeURIComponent(breadCrumbs.slice(1).reduce((prev, curr) => prev + '/' + curr.name, '')
                        .substring(1)),
                    itemType: ItemType.FILES,
                }))
            ),
        },
    ] : undefined;

    const downloadButton = {
        buttonText: 'download_dataset',
        buttonCallback: async (): Promise<void> => {
            await downloadDataset(datasetId, currentProject?.id)
                .then(() => {
                    feedback.enqueueSuccess(t('feedback_dataset_download_success'));
                    setDeletedFlag(flag => !flag);
                    return true;
                })
                .catch((error: any) => {
                    logger.error({
                        identifier: 'Dataset Download Button',
                        message: 'Download already in progress',
                        error,
                    });
                    feedback.enqueueError(t('error_download_in_progress'));
                });
        },

    };

    if (buttonItems !== undefined && datasetDownloadEnabled) {
        buttonItems.unshift(downloadButton);
    }

    const menuItems = [];

    menuItems.push({
        text: 'dataset_download_label',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        click: (event: any, row: any): void => {
            const signedUrl = URLManager.url().apiTrainer + '/signedURL?blobName=' + encodeURIComponent(row.original.blobName) + '&signingMethod=GET&urlEncoded=true&projectId=' + currentProject?.id;
            downloadContentWithSignUrl(signedUrl, isInstanceProfileEnabled, authToken, azureStorageFQDNSuffix);
        },
    });

    if (permissions.indexOf(AppPermissions.MLStorage_Delete) > -1) {
        menuItems.push({
            text: 'dataset_delete_label',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any): void => {
                setOpen(true);
                setCurrentRow(data);
            },
        });
    }

    const rotateDatasetKey = useCallback(async (): Promise<void> => {
        await rotateKey(datasetId, currentProject?.id)
            .then(() => {
                setOpenRotateSkillDialog(false);
                feedback.enqueueSuccess(t('feedback_update_success_dataset'));
                history.push(generatePath(RoutePath.DATASETS, { projectName: currentProject?.name }));
                return true;
            })
            .catch((error: any) => {
                setOpenRotateSkillDialog(false);
                logger.error({
                    identifier: 'Dataset details',
                    message: 'Error rotating key for public dataset',
                    error,
                });
                feedback.enqueueError(
                    extractErrorMessage(
                        error,
                        t('feedback_skill_update_error'), // TODO check for correct error
                        { 40802: {} },
                    ),
                );
            });
    }, []);

    const TabDetails: React.FC<any> = () => (
        <DatasetFolderPageTabContainer>
            <div className="tabBarArea">
                <div className="tabBar">
                    <Tabs
                        value={curTab}
                        aria-label={t('a11y_dataset_download')}
                        indicatorColor="secondary"
                        textColor="secondary">
                        <Tab
                            onClick={() => setCurTab(currentTab.DATASET)}
                            value={currentTab.DATASET}
                            label={t('dl_session_list_export_dataset_header')} />
                        {datasetDownloadEnabled &&
                            <Tab
                                onClick={() => setCurTab(currentTab.DOWNLOAD)}
                                value={currentTab.DOWNLOAD}
                                label={t('dataset_download_tab_label')} />}
                    </Tabs>
                </div>
            </div>
        </DatasetFolderPageTabContainer>
    );

    return url ? (
        <DataSetFoldersPageContainer>
            <Section
                title={datasetFolderName || ''}
                buttonItems={buttonItems}>
                <DSDetailsData
                    openRotateSkillDialog={setOpenRotateSkillDialog}
                    dsDto={dsDto}
                    isOnPrem={isOnPrem}
                    featureManager={featureManager} />
                <br />
                <Breadcrumbs aria-label={t('a11y_breadcrumb')}>
                    {
                        breadCrumbs.map((breadCrumb: DataSetBreadCrumb) => {
                            if (breadCrumb.type === 'LINK') {
                                return (
                                    <Link
                                        key={`bread-crumb-link-${breadCrumb.link}`}
                                        className="cursor-pointer"
                                        underline="hover"
                                        onClick={(): void => history.push(breadCrumb.link)}
                                    >
                                        {breadCrumb.name}
                                    </Link>
                                );
                            }
                            return (
                                <Typography
                                    key={`bread-crumb-link-${breadCrumb.name}`}
                                    color="textPrimary">
                                    {breadCrumb.name}
                                </Typography>
                            );
                        })
                    }
                </Breadcrumbs>
                <br />
                <TabDetails />

                <WithVisibility visible={curTab === currentTab.DATASET}>
                    <CustomDialog
                        title={t('dataset_delete_selected_items_dialog_title')}
                        open={open}
                        handleClose={closeDialog}
                        closeIconButton
                        warningText={t('ml_package_delete_dialog_warning_text')}
                        primaryButtonText={t('dialog_button_confirm_text')}
                        secondaryButtonText={t('dialog_button_cancel_text')}
                        primarybuttonCallback={currentRow.original.blobType ? deleteDatasetFile : deleteDatasetSelection}
                        secondarybuttonCallback={closeDialog}
                    />

                    <DialogPopup
                        open={openRotateSkillDialog}
                        type={DialogType.SkillUpdate} // TODO check with Avinash
                        title={t('ml_skill_rotate_key_dialog_title_text', { mlSkillName: dsDto.name })}
                        handleClose={(): void => setOpenRotateSkillDialog(false)}
                        closeIconButton
                        primaryButtonText={t('dialog_button_confirm_text')}
                        secondaryButtonText={t('dialog_button_cancel_text')}
                        primarybuttonCallback={rotateDatasetKey}
                        secondarybuttonCallback={(): void => setOpenRotateSkillDialog(false)}
                    >
                        <DialogRootContainer>
                            {t('ml_skill_rotate_key_dialog_title_content')}
                        </DialogRootContainer>
                    </DialogPopup>

                    <ServerSideTableGCS
                        url={url}
                        totalKey="count"
                        dataKey="data.dataList"
                        mapper={dataMapper}
                        isSelectAble={(permissions.indexOf(AppPermissions.MLStorage_View) > -1)}
                        onTableCellClicked={handleDataSetClicked}
                        contextMenuItems={menuItems}
                        selectionItems={selectionItems}
                        itemDeletedFlag={itemDeletedFlag}
                        level="dataset_pagesize"
                    />
                </WithVisibility>

                <WithVisibility visible={curTab === currentTab.DOWNLOAD}>
                    <ServerSideTableGCS
                        url={downloadUrl}
                        totalKey="count"
                        dataKey="data.dataList"
                        mapper={dataDownloadMapper}
                        icons={icons}
                        itemDeletedFlag={deletedFlag}
                        level="dataset_pagesize"
                    />
                </WithVisibility>
            </Section>
        </DataSetFoldersPageContainer>
    ) : null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DatasetFolderPage = connect((state: any) => ({
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    featureManager: state.featureManagement.featureManager,
    isInstanceProfileEnabled: state.config.paths ? state.config.paths.isInstanceProfileEnabled : undefined,
    azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING,

}))(DatasetFolderPageFC);
