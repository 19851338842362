import {
    Chip,
    MenuItem,
    styled,
} from '@mui/material';
import type {
    MLPackageDto,
    MLPackageVersionDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import {
    Field,
    Formik,
} from 'formik';
import Linkify from 'linkify-react';
import React, {
    useContext,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';

import { getPublicPackageById } from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import type { BreadcrumbProps } from '../../../components/Breadcrumbs';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';

interface PackageVersion {
    id: string | undefined;
    version: string;
}

const Header = styled('h2')(({ theme }) => ({ color: theme.palette.semantic.colorForeground }));

const UiPathPackageContainer = styled('div')(({ theme }) => ({
    width: '100%',
    fontSize: Tokens.FontFamily.FontMSize,
    marginBottom: '10px',
    overflowY: 'auto',

    '& p': { color: theme.palette.semantic.colorForeground },

    '& .cardImage': {
        width: 40,
        height: 40,
        marginLeft: '50px',
        marginTop: '10px',
    },

    '& .description': {
        width: '80%',
        lineHeight: Tokens.FontFamily.FontMLineHeight,
    },

    '& .retrainable-chip': {
        backgroundColor: theme.palette.semantic.colorChipInfoBackground,
        color: theme.palette.semantic.colorForegroundLinkPressed,
    },
}));

interface UiPathPackageProps {
    isOnPrem: boolean;
}
const UiPathPackage: React.FC<UiPathPackageProps> = ({ isOnPrem }) => {
    const { t } = useTranslation();
    const feedback = useFeedback();
    const history = useHistory();
    const { state: projectState } = useContext(ProjectsContext);
    const currentProject = React.useMemo(() => projectState.currentProject, [ projectState ]);

    const {
        projectName, uiPathProjectName, uiPathPackageId,
    } = useParams<{ projectName: string; uiPathProjectName: string; uiPathPackageId: string }>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state: any = useLocation()?.state;
    const data = state.data;

    const [ uiPathPackage, setUiPathPackage ] = useState<MLPackageDto>();
    const [ inputDescription, setInputDescription ] = useState<string | undefined>('');
    const [ outputDescription, setOutputDescription ] = useState<string | undefined>('');
    const [ retrainable, setRetrainable ] = useState<boolean | undefined>(undefined);
    const [ changeLog, setChangeLog ] = useState<string | undefined>('');

    const [ packageVersions, setPackageVersions ] = useState<PackageVersion[]>([]);
    const [ currentPackageVersionId, setCurrentPackageVersionId ] = useState<string | undefined>('');
    const [ minAIFVersion, setMinAIFVersion ] = useState<string | undefined>('');

    const ML_PACKAGE_VERSION_ALLOWED_STATUSES: string[] = [ 'DEPLOYING', 'DEPLOYED', 'UNDEPLOYED' ];

    React.useEffect(() => {
        if (uiPathPackageId) {
            getPublicPackageById(uiPathPackageId, data.mlPackageOwnedByAccountId,
                data.mlPackageOwnedByTenantId, data.mlPackageOwnedByProjectId, currentProject?.id)
                .then((uipathPackage) => {
                    if (uipathPackage && uipathPackage.mlPackageVersions) {
                        setUiPathPackage(uipathPackage);
                        setMinAIFVersion(uipathPackage.mlPackageVersions[0].minAIFabricVersion);
                        const pkgVersions: PackageVersion[] = [];
                        uipathPackage.mlPackageVersions.forEach(mlPackageVersion => {
                            /* Ignore Other statuses like Validation Failed from backend and push only public packages */
                            if (mlPackageVersion.status &&
                                ML_PACKAGE_VERSION_ALLOWED_STATUSES.indexOf(mlPackageVersion.status?.toString()) > -1
                                && mlPackageVersion.isPublic === true) {
                                const pkgVersion = getMLPackageVersion(mlPackageVersion);
                                pkgVersions.push({
                                    id: mlPackageVersion.id,
                                    version: pkgVersion + '.' + mlPackageVersion.trainingVersion,
                                });
                            }
                        });
                        setCurrentPackageVersionId(pkgVersions.length ? pkgVersions[0].id : '');
                        setPackageVersions(pkgVersions);
                    }
                    return true;
                })
                .catch(error => {
                    logger.error({
                        identifier: 'Public Package',
                        message: 'Error getting public package by ID',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(
                        error,
                        t('feedback_error_loading_public_ml_package'),
                        {
                            10602: {
                                1: 'Public Package',
                                0: uiPathPackageId,
                            },
                        },
                    ));
                });
        }
    }, [ uiPathPackageId, data.mlPackageOwnedByAccountId, data.mlPackageOwnedByTenantId, data.mlPackageOwnedByProjectId ]);

    const getMLPackageVersion = (mlPackageVersion: MLPackageVersionDto): string | number | undefined => mlPackageVersion.customVersion ? mlPackageVersion.customVersion : mlPackageVersion.version;

    /* Generate breadcrum for uipath package page */
    const uipathPackageBreadcrumProps: BreadcrumbProps =
    {
        pageTitle: uiPathPackage?.displayName ?? '',
        crumbs: [
            {
                title: t('ml_packages_title'),
                link: generatePath(RoutePath.MLPACKAGES, { projectName }),
            },
            {
                title: t('out_of_the_box_packages'),
                link: generatePath(RoutePath.UIPATH_PROJECTS, { projectName }),
            },
            {
                title: uiPathProjectName ?? '',
                link: generatePath(RoutePath.UIPATH_PACKAGES, {
                    projectName,
                    uiPathProjectName,
                }),
                data: {
                    mlPackageOwnedByAccountId: data.mlPackageOwnedByAccountId,
                    mlPackageOwnedByTenantId: data.mlPackageOwnedByTenantId,
                    mlPackageOwnedByProjectId: data.mlPackageOwnedByProjectId,
                },
            },
        ],
    };

    return (
        <UiPathPackageContainer>
            <Formik
                enableReinitialize
                initialValues={{ currentPackageVersionId }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {
                    history.push(
                        {
                            pathname: generatePath(RoutePath.UIPATH_PACKAGE_CLONE,
                                {
                                    projectName,
                                    uiPathProjectName,
                                    uiPathPackageId,
                                    uipathPackageVersionId: values.currentPackageVersionId,
                                }),
                            state: {
                                data: {
                                    newVersion: data.newVersion,
                                    mlpackageId: data.mlpackageId,
                                    mlpackageName: data.mlpackageName,
                                    mlPackageOwnedByAccountId: data.mlPackageOwnedByAccountId,
                                    mlPackageOwnedByTenantId: data.mlPackageOwnedByTenantId,
                                    mlPackageOwnedByProjectId: data.mlPackageOwnedByProjectId,
                                },
                            },
                        },
                    );
                }}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    props => {
                        const {
                            dirty,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            values,
                            errors,
                        } = props;

                        if (uiPathPackage && uiPathPackage.mlPackageVersions) {
                            uiPathPackage.mlPackageVersions.forEach(mlPackageVersion => {
                                if (mlPackageVersion.id === values.currentPackageVersionId) {
                                    setInputDescription(mlPackageVersion.inputDescription);
                                    setOutputDescription(mlPackageVersion.outputDescription);
                                    setRetrainable(mlPackageVersion.retrainable);
                                    mlPackageVersion.description = uiPathPackage.description;
                                    setMinAIFVersion(mlPackageVersion.minAIFabricVersion);
                                    setChangeLog(mlPackageVersion.changeLog);
                                }
                            });
                        }

                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                /* Let be empty class so form layout won't add another padding */
                                childrenClass={' '}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                        submitButtonText={t('form_edit_button_text')}
                                    />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                <UiPathPackageContainer>
                                    <Section breadcrumProps={uipathPackageBreadcrumProps}>
                                        <div style={{ display: 'flex' }}>
                                            <Header>
                                                {uiPathPackage?.displayName}
                                            </Header>
                                        </div>

                                        <div className='description'>
                                            <Label value={t('uipath_package_description')} />
                                            {/* Linkify is needed for parse url text into HTTP link */}
                                            <p>
                                                <Linkify>
                                                    {uiPathPackage?.description}
                                                </Linkify>
                                            </p>

                                            <Label value={t('uipath_package_input_description')} />
                                            <p>
                                                {inputDescription}
                                            </p>

                                            <Label value={t('uipath_package_output_description')} />
                                            <p>
                                                {outputDescription}
                                            </p>

                                            {(changeLog !== undefined && changeLog !== null && changeLog.length > 0) ?
                                                <div>
                                                    <Label value={t('mlpkg_create_changeLog_label')} />
                                                    <p>
                                                        {changeLog}
                                                    </p>
                                                </div> : <div />}

                                            {retrainable !== undefined ?
                                                <Chip
                                                    className="retrainable-chip"
                                                    label={retrainable ? t('uipath_package_retrainable_label') : t('uipath_package_not_retrainable_label')}
                                                /> : <div />}
                                            {
                                                uiPathPackage?.id ? packageVersions.length > 0 ? (<div>
                                                    <Label value={t('uipath_package_choose_version')} />
                                                    <Field
                                                        component={AccessibleFormikInput}
                                                        className="textFeild min-width-override"
                                                        select
                                                        type="text"
                                                        name="currentPackageVersionId"
                                                        variant="outlined"
                                                        color="secondary"
                                                        data-testid="version_select"
                                                        SelectProps={{ 'inputProps': { 'aria-label': t('uipath_package_choose_version') } }}
                                                    >
                                                        <MenuItem
                                                            value=""
                                                            disabled>
                                                            {' '}
                                                            {t('uipath_package_version_dropdown_label')}
                                                            {' '}
                                                        </MenuItem>
                                                        {packageVersions.map((packageVersion, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                data-testid={`version_select-${packageVersion.version}`}
                                                                value={packageVersion.id}>
                                                                {packageVersion.version}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </div>)
                                                    : (<p>
                                                        {t('private_preview_package_info_text')}
                                                    </p>) : null
                                            }

                                            {
                                                isOnPrem ? (<>
                                                    <Label value={t('min_aif_version')} />
                                                    <p>
                                                        {minAIFVersion}
                                                    </p>
                                                </>) : null
                                            }

                                        </div>
                                    </Section>
                                </UiPathPackageContainer>
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </UiPathPackageContainer>
    );
};

export default connect((state: any) => ({ isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined }))(UiPathPackage);
