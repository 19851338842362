import type {
    MLSkillDto,
    ProjectDto,
} from '@uipath/aifabric';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
    useLocation,
} from 'react-router-dom';

import { updateMLSkill } from '../../../api/client/deployerManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import { MlSkillUpdateDialog } from '../../../components/Dialog';
import type { ResponsiveColumnDefinition } from '../../../components/Table/BaseTable/types';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import { WithVisibility } from '../../../components/WithVisibility';
import URLManager from '../../../config/URLManager';
import Mappings from '../../../constants/TableHeaderMappings';
import { AppPermissions } from '../../../enums/Authorization';
import { BaseTableIcons } from '../../../enums/BaseTableIcons';
import { SkillUpdateType } from '../../../enums/SkillUpdate';
import type FeatureFlagManager from '../../../feature-flag/FeatureFlagManager';
import { RoutePath } from '../../../route/routeMap';
import {
    extractErrorMessage,
    isPublicCapable,
} from '../../../utils/CommonUtils';
import { generateSkillUpdateData } from './MlSkillDetailsPage';

interface SkillPackageVersionProps {
    currentProject: ProjectDto | undefined;
    permissions: AppPermissions[];
    isOnPrem: boolean;
    isPublicTenant: boolean;
    featureManager: FeatureFlagManager;
}

export interface SkillUpdateData {
    currentVersion: string | undefined;
    nextVersion: string | undefined;
    packageName: string | undefined;
    gpuEnabled: boolean;
    updateType: SkillUpdateType;
    isOnprem: boolean;
    rotate?: boolean;
    isPublicSkill: boolean;
    autoUpdate: boolean;
    inactivityPeriodInDays?: number;
    replicas?: number;
    requestMemory?: number;
    limitMemory?: number;
    requestCPU?: number;
    limitCPU?: number;
    currentCustomVersion?: string;
    nextCustomVersion?: string;
    mlPackageId?: string;
}

interface MetaLimits {
    replicas: number;
    requestMemory: number;
    requestCPU: number;
    limitMemory: number;
    limitCPU: number;
}

export const MlPackageVersions: React.FC<SkillPackageVersionProps> =
  ({
      currentProject,
      permissions,
      isOnPrem,
      isPublicTenant,
      featureManager,
  }) => {

      const { t } = useTranslation();
      const dataMapper: ResponsiveColumnDefinition[] = Mappings.VersionsList;

      const history = useHistory();
      const feedback = useFeedback();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const locationState: any = useLocation()?.state;
      const skillData: MLSkillDto = locationState.data;

      const [ open, setOpen ] = React.useState(false);

      const newVersion = useRef<{ version: string; customVersion?: string }>();
      const newVersionId = useRef('');

      const mlPackagesBaseUrl = URLManager.url().apiPkgManager + '/mlpackages';
      const versionsUrl = '/versions';
      const versionListUrl = versionsUrl + '?sortBy=versions&sortOrder=DESC&status=DEPLOYING,DEPLOYED,UNDEPLOYED&projectId=' + currentProject?.id;

      const packageVersionsListUrl = React.useMemo(() => `${mlPackagesBaseUrl}/${skillData.mlPackageId}${versionListUrl}`, [ mlPackagesBaseUrl, skillData.mlPackageId, versionListUrl ]);

      const updateData: SkillUpdateData = generateSkillUpdateData(skillData);
      updateData.currentVersion = skillData.currentVersion + '.' + skillData.currentTrainingVersion;
      updateData.nextVersion = newVersion.current?.version;
      updateData.updateType = SkillUpdateType.Update;
      updateData.isOnprem = isOnPrem;
      updateData.currentCustomVersion = skillData.currentCustomVersion != null ? skillData.currentCustomVersion + '.' + skillData.currentTrainingVersion : undefined;
      updateData.nextCustomVersion = newVersion.current?.customVersion;

      const closeDialog = (): void => {
          setOpen(false);
      };

      /**
   * Update existing ML skill to another version along with new requirement like gpu, publicSkill
   *
   * @param gpuRequired
   * @param isPublicSkill
   * @param isAutoUpdate
   */
      const updateMlSkill = async (
          gpuRequired: boolean,
          isPublicSkill: boolean,
          isAutoUpdate: boolean,
          inactivityPeriodThresholdSelected: number,
          meta: MetaLimits): Promise<void> => {
          try {
              await updateMLSkill(
                  {
                      deploymentsRequired: 1,
                      gpuRequired: Number(gpuRequired),
                      mlPackageVersionId: newVersionId.current,
                      processor: gpuRequired === true ? 'GPU' : 'CPU',
                      publicSkill: isPublicSkill,
                      autoUpdate: isAutoUpdate,
                      inactivityPeriodInDays: skillData.inactivityPeriodInDays,
                      replicas: meta.replicas,
                      requestMemory: meta.requestMemory,
                      requestCPU: meta.requestCPU,
                      limitMemory: meta.limitMemory,
                      limitCPU: meta.limitCPU,
                  },
                  skillData.id, /* ML Skill ID */
                  'UPDATE',
                  skillData.projectId, /* Project ID */
              ).then(() => {
                  closeDialog();
                  feedback.enqueueSuccess(t('feedback_update_success_mlskill'));
                  /* Move to Ml Skill page on success */
                  history.push(generatePath(RoutePath.MLSKILLS, { projectName: currentProject?.name }));
                  return true;
              });
          } catch (error) {
              closeDialog();
              feedback.enqueueError(extractErrorMessage(
                  error, t('feedback_skill_update_error'),
                  {
                      40802: {},
                      40016: {},
                  },
              ));
          }
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const icons: any[] = [];

      if (permissions.indexOf(AppPermissions.MLSkills_Edit) > -1) {
          icons.push({
              iconType: BaseTableIcons.SWAPVERT,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              click: (event: any, rowInfo: any): void => {
                  newVersion.current = { version: rowInfo.original.version + '.' + rowInfo.original.trainingVersion };

                  if (rowInfo.original.customVersion) {
                      newVersion.current.customVersion = rowInfo.original.customVersion + '.' + rowInfo.original.trainingVersion;
                  }
                  newVersionId.current = rowInfo.original.id;
                  setOpen(true);
              },
          });
      }

      return (
          <div>
              <WithVisibility visible={permissions.indexOf(AppPermissions.MLPackages_View) > -1 || permissions.indexOf(AppPermissions.MLSkills_Create) > -1}>
                  <MlSkillUpdateDialog
                      canBePublic={isPublicCapable(skillData, isOnPrem, isPublicTenant, featureManager)}
                      open={open}
                      title={t('ml_skill_update_dialog_title_text', { mlSkillName: skillData.name })}
                      data={updateData}
                      handleClose={closeDialog}
                      closeIconButton
                      primaryButtonText={t('dialog_button_confirm_text')}
                      secondaryButtonText={t('dialog_button_cancel_text')}
                      primarybuttonCallback={updateMlSkill}
                      secondarybuttonCallback={closeDialog}
                      isAutoUpdateEnabled={featureManager.isEnabled('auto-retraining-enabled')}
                      isMLSkillInfraSettingsEnabled={featureManager.isEnabled('ml-skill-infra-settings-enabled')}
                      inactivePeriodThresholds={[]}
                  />
                  <ServerSideTable
                      url={packageVersionsListUrl}
                      totalKey="data.totalCount"
                      dataKey="data.dataList"
                      mapper={dataMapper}
                      icons={icons}
                      level="mlskills_pagesize"
                  />
              </WithVisibility>
          </div>
      );
  };
