import {
    AnonymousCredential,
    BlockBlobClient,
    newPipeline,
} from '@azure/storage-blob';
import type {
    BaseResponseOfListOfMLPackageLanguageVersionDto,
    BaseResponseOfListOfProjectDto,
    BaseResponseOfMLPackageDto,
    BaseResponseOfMLPackageVersionDto,
    BaseResponseOfPageListingDtoOfMLPackageDto,
    BaseResponseOfPageListingDtoOfMLPackageVersionDto,
    BaseResponseOfPageListingDtoOfPublicTenantDto,
    BaseResponseOfSignedURLDto,
    BaseResponseOfstring,
    BaseResponseOfTenantCheckDTO,
    MLPackageCloneDto,
    MLPackageDto,
    MLPackageLanguageVersionDto,
    MLPackageMetaDataDto,
    MLPackageVersionDto,
    MLPackageVersionPublicUpdateDto,
    ProjectDto,
    PublicTenantDto,
} from '@uipath/aifabric';
import type {
    AxiosResponse,
    CancelTokenSource,
} from 'axios';

import type FileToUpload from '../../components/fileDropzone/FileToUpload';
import URLManager from '../../config/URLManager';
import { http } from '../../http';

const createMlPackage = async (createPackage: MLPackageMetaDataDto, importPackageType: string | undefined): Promise<MLPackageDto | undefined> => {
    const result = await http.post<MLPackageMetaDataDto, AxiosResponse<BaseResponseOfMLPackageDto>>(URLManager.url().apiPkgManager +
        '/mlpackages?mlPackageCreationType=' + importPackageType + '&projectId=' + createPackage.projectId, createPackage).then(res => res?.data?.data);
    return result;
};

const createMlPackageVersion = async (createPackage: MLPackageMetaDataDto, mlPackageId: string): Promise<MLPackageDto | undefined> => {
    const res = await http.post<MLPackageMetaDataDto, AxiosResponse<BaseResponseOfMLPackageDto>>(URLManager.url().apiPkgManager +
        `/mlpackages/${mlPackageId}/versions?projectId=${createPackage.projectId}`, createPackage);
    return res.data.data;
};

const cloneMlPackage = async (cloneMLPackage: MLPackageCloneDto | undefined): Promise<MLPackageDto | undefined> => {
    const result = await http.post<MLPackageCloneDto, AxiosResponse<BaseResponseOfMLPackageDto>>(URLManager.url().apiPkgManager
        + '/mlpackages/clone?projectId=' + cloneMLPackage?.projectId, cloneMLPackage).then(res => res?.data?.data);
    return result;
};

const cloneMlPackageVersion = async (cloneMLPackageVersion: MLPackageCloneDto | undefined, mlPackageId: string | undefined): Promise<MLPackageVersionDto | undefined> => {
    const result = await http.post<MLPackageCloneDto, AxiosResponse<BaseResponseOfMLPackageVersionDto>>(URLManager.url().apiPkgManager +
        `/mlpackages/${mlPackageId}/versions/clone` + `?projectId=${cloneMLPackageVersion?.projectId}`, cloneMLPackageVersion).then(res => res?.data?.data);
    return result;
};

const getSignedUrl = async (mlPackageName: string): Promise<BaseResponseOfSignedURLDto> => {
    const res = await http.get<BaseResponseOfSignedURLDto>(URLManager.url().apiPkgManager + '/signedURL', {
        params: {
            contentType: 'application/x-zip-compressed',
            mlPackageName,
            signingMethod: 'PUT',
        },
    });
    return res.data;
};

const uploadPackageToOtherStorageProviders = async (file: File, signedUrlDto: BaseResponseOfSignedURLDto, authToken: string): Promise<Response> => {
    const formData = new FormData();
    formData.append(file.name, file);
    const uploadUrl = signedUrlDto.data?.url ?? '';
    const headers: { [key: string]: string } = { 'Content-Type': 'application/x-zip-compressed' };
    if (signedUrlDto.data?.authRequired) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }
    return fetch(uploadUrl, {
        method: 'PUT',
        headers,
        body: file,
    });
};

/**
 * Upload content to azure storage
 *
 * @param file
 * @param signedUrl
 */
export const uploadPackageToAzure = async (file: File, signedUrl: string) => {

    /* Create new blob client for azure */
    const blockBlobClient = new BlockBlobClient(signedUrl, newPipeline(new AnonymousCredential()));

    await blockBlobClient.uploadBrowserData(file, {
        /* Chunk of 4 MB in size */
        maxSingleShotSize: 4 * 1024 * 1024,
    });
};

export const uploadAndCreatePackage = async (createPackageRequest: MLPackageMetaDataDto, uploadFile: FileToUpload, authToken: string, azureStorageFQDNSuffix: string): Promise<MLPackageDto | undefined> => {
    if (uploadFile.file.name) {
        const signedUrl = await getSignedUrl(uploadFile.file.name);

        if (signedUrl && signedUrl.data && signedUrl.data.url) {
            if (signedUrl.data.url.indexOf(azureStorageFQDNSuffix) > -1) {
                /* Upload to azure bucket */
                await uploadPackageToAzure(uploadFile.file, signedUrl.data.url);
            } else {
                /* Upload to other storage */
                await uploadPackageToOtherStorageProviders(uploadFile.file, signedUrl, authToken);
            }

            createPackageRequest.stagingUri = signedUrl.data.url;
            /* Call Create ML package meta data */
            return await createMlPackage(createPackageRequest, '');
        }
    } else {
        return Promise.reject(new Error('upload requires a valid package name'));
    }
};

export const uploadAndCreatePackageVersion = async (createPackageRequest: MLPackageMetaDataDto, mlPackageId: string,
    uploadFile: FileToUpload, authToken: string, azureStorageFQDNSuffix: string): Promise<MLPackageDto | undefined> => {
    if (uploadFile.file.name) {
        const signedUrl = await getSignedUrl(uploadFile.file.name);

        if (signedUrl && signedUrl.data && signedUrl.data.url) {
            if (signedUrl.data.url.indexOf(azureStorageFQDNSuffix) > -1) {
                /* Upload to azure bucket */
                await uploadPackageToAzure(uploadFile.file, signedUrl.data.url);
            } else {
                /* Upload to other storage */
                await uploadPackageToOtherStorageProviders(uploadFile.file, signedUrl, authToken);
            }
            createPackageRequest.stagingUri = signedUrl.data.url;

            /* Call Create ML package version meta data */
            return await createMlPackageVersion(createPackageRequest, mlPackageId);
        }
    } else {
        return Promise.reject(new Error('upload requires a valid package name'));
    }
};

export const uploadAndCreateImportedPackage = async (createPackageRequest: MLPackageMetaDataDto, uploadFile: FileToUpload, importPackageType: string | undefined,
    mlpackageId: string | undefined, cloneRequest: MLPackageCloneDto | undefined, isPublicPackage: boolean, authToken: string, azureStorageFQDNSuffix: string): Promise<MLPackageDto | undefined> => {
    if (uploadFile?.file.name) {
        if (importPackageType === 'ML_PACKAGE_OOB_UPLOAD') {
            createPackageRequest.imagePath = '';
        }
        const signedUrl = await getSignedUrl(uploadFile.file.name);

        if (signedUrl && signedUrl.data && signedUrl.data.url) {
            if (signedUrl.data.url.indexOf(azureStorageFQDNSuffix) > -1) {
                /* Upload to azure bucket */
                await uploadPackageToAzure(uploadFile.file, signedUrl.data.url);
            } else {
                /* Upload to other storage */
                await uploadPackageToOtherStorageProviders(uploadFile.file, signedUrl, authToken);
            }

            if (isPublicPackage && (importPackageType === 'ML_PACKAGE_IMPORT' || importPackageType === 'ML_PACKAGE_VERSION_IMPORT')) {
                cloneRequest!.stagingUri = signedUrl.data.url;
            } else {
                createPackageRequest.stagingUri = signedUrl.data.url;
            }

            if (isPublicPackage && importPackageType === 'ML_PACKAGE_IMPORT') {
                return await clonePublicMLPackage(cloneRequest);
            } else if (isPublicPackage && importPackageType === 'ML_PACKAGE_VERSION_IMPORT') {
                return await clonePublicMLPackageVersion(cloneRequest, mlpackageId);
            } else if (importPackageType === 'ML_PACKAGE_IMPORT') {
                return await createMlPackage(createPackageRequest, importPackageType);
            } else if (importPackageType === 'ML_PACKAGE_VERSION_IMPORT') {
                return await createMlPackageImportedVersion(createPackageRequest, importPackageType, mlpackageId);
            }
            return await createImportedMlPackage(createPackageRequest, importPackageType);

        }
    } else if (createPackageRequest.imagePath && importPackageType == 'ML_PACKAGE_OOB_UPLOAD') {
        return await createImportedMlPackage(createPackageRequest, importPackageType);
    } else {
        return Promise.reject(new Error('upload requires a valid package name'));
    }
};

const createImportedMlPackage = async (createPackage: MLPackageMetaDataDto, importPackageType: string | undefined): Promise<MLPackageDto | undefined> => await http.post<MLPackageMetaDataDto, AxiosResponse<BaseResponseOfMLPackageDto>>(URLManager.url().apiPkgManager +
    '/mlpackages/import?mlPackageCreationType=' + importPackageType, createPackage).then(res => res?.data?.data);

const createMlPackageImportedVersion = async (createPackage: MLPackageMetaDataDto, importPackageType: string | undefined, mlpackageId: string | undefined): Promise<MLPackageDto | undefined> => await http.post<MLPackageMetaDataDto, AxiosResponse<BaseResponseOfMLPackageDto>>(URLManager.url().apiPkgManager +
    '/mlpackages/' + mlpackageId + '/versions?mlPackageCreationType=' + importPackageType + '&projectId=' + createPackage.projectId,
    createPackage).then(res => res?.data?.data);

const isPublicTenant = async (): Promise<boolean | undefined> => {
    const res = await http.get<BaseResponseOfTenantCheckDTO>(URLManager.url().apiPkgManager + '/tenants/check');
    return res.data?.data;
};

export const clonePublicMLPackage = async (clonePackageRequest: MLPackageCloneDto | undefined): Promise<MLPackageDto | undefined> => await cloneMlPackage(clonePackageRequest);

export const clonePublicMLPackageVersion = async (clonePackageVersionRequest: MLPackageCloneDto | undefined, mlPackageId: string | undefined): Promise<MLPackageVersionDto | undefined> => await cloneMlPackageVersion(clonePackageVersionRequest, mlPackageId);

export const getPackageById = async (mlPackageId: string, projectId?: string, isMLTagsAndLabelsRequired?: boolean): Promise<MLPackageDto | undefined> => {
    const res = await http.get<BaseResponseOfMLPackageDto>(URLManager.url().apiPkgManager + '/mlpackages/' + mlPackageId +
        `?projectId=${projectId}&isMLTagsAndLabelsRequired=${isMLTagsAndLabelsRequired}`);
    return res.data?.data;
};

export const getPackageVersionById = async (mlPackageId: string, mlPackageVersionId: string, projectId?: string, isMLTagsAndLabelsRequired?: boolean): Promise<MLPackageDto | undefined> => {
    const res = await http.get<BaseResponseOfMLPackageVersionDto>(URLManager.url().apiPkgManager + '/mlpackages/' + mlPackageId
        + '/versions/' + mlPackageVersionId + `?projectId=${projectId}&isMLTagsAndLabelsRequired=${isMLTagsAndLabelsRequired}`);
    return res.data?.data;
};

const checkUniquePackageByName = async (packageName: string, projectId?: string): Promise<Boolean | undefined> => {
    const res = await http.get<Boolean>(URLManager.url().apiPkgManager + '/mlpackages/search?name=' + packageName + '&projectId=' + projectId);
    return res.data;
};

export const deletePackage = async (mlPackageId: string, projectId?: string) => {
    const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiPkgManager + `/mlpackages/${mlPackageId}/versions/allundeployed` + `?projectId=${projectId}`);
    return res.data;
};

/* pageSize needs to be fixed after https://uipath.atlassian.net/browse/AIFBR-4442 */
export const getMlPackages = async (cancelToken: CancelTokenSource, projectId: string | undefined, name?: string): Promise<MLPackageDto[] | undefined> => {
    const res = await http.get<BaseResponseOfPageListingDtoOfMLPackageDto>(URLManager.url().apiPkgManager +
        '/mlpackages?pageSize=1000&sortBy=createdOn&sortOrder=DESC&status=DEPLOYING,DEPLOYED,UNDEPLOYED&projectId=' + projectId + '&name=' + name,
        { cancelToken: cancelToken.token });
    return res.data.data?.dataList;
};

export const getAllMlPackages = async (projectId: string | undefined, name?: string): Promise<MLPackageDto[] | undefined> => {
    const res = await http.get<BaseResponseOfPageListingDtoOfMLPackageDto>(URLManager.url().apiPkgManager +
        '/mlpackages?pageSize=1000&sortBy=createdOn&sortOrder=DESC&status=DEPLOYING,DEPLOYED,UNDEPLOYED&projectId=' + projectId + '&name=' + name);
    return res.data.data?.dataList;
};

/* pageSize needs to be fixed after https://uipath.atlassian.net/browse/AIFBR-4442 */
export const getMlVersionsByPackageId = async (mlPackageId: string | undefined, projectId: string | undefined, runId?: string | undefined): Promise<MLPackageVersionDto[] | undefined> => {
    const status = runId ? '' : 'DEPLOYING,DEPLOYED,UNDEPLOYED';
    const newRunId = runId ? runId : '';
    const res = await http.get<BaseResponseOfPageListingDtoOfMLPackageVersionDto>(URLManager.url().apiPkgManager + '/mlpackages/' + mlPackageId
        + '/versions?pageSize=1000&sortBy=createdOn&sortOrder=DESC&status=' + status + '&projectId=' + projectId + '&runId=' + newRunId);
    return res.data.data?.dataList;
};

export const deletePackageVersion = async (mlPackageVersionId: string, projectId?: string) => {
    const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiPkgManager + `/mlpackages/versions/${mlPackageVersionId}` + `?projectId=${projectId}`);
    return res.data;
};

export const getPublicTenants = async (): Promise<PublicTenantDto[] | undefined> => {
    const res = await http.get<BaseResponseOfPageListingDtoOfPublicTenantDto>(URLManager.url().apiPkgManager + '/tenants', { params: { sortOrder: 'DESC' } });
    return res.data?.data?.dataList;
};

export const getPublicProjects = async (): Promise<ProjectDto[] | undefined> => {
    const res = await http.get<BaseResponseOfListOfProjectDto>(URLManager.url().apiPkgManager + '/projects/public');
    return res.data?.data;
};

export const getPublicProjectsByTenantId = async (tenantId: string): Promise<ProjectDto[] | undefined> => {
    const res = await http.get<BaseResponseOfListOfProjectDto>(URLManager.url().apiPkgManager + '/projects/public',
        { params: { tenantIds: tenantId } },
    );
    return res.data?.data;
};

export const getPublicPackages = async (mlPackageOwnedByAccountId: string | undefined,
    mlPackageOwnedByTenantId: string | undefined, mlPackageOwnedByProjectId: string | undefined, pageSize: Number, projectId?: string): Promise<MLPackageDto[] | undefined> => {
    const res = await http.get<BaseResponseOfPageListingDtoOfMLPackageDto>(
        /* Add Status by which Ml packages should be fetched */
        URLManager.url().apiPkgManager + '/mlpackages',
        {
            params: {
                pageSize,
                status: 'DEPLOYING,DEPLOYED,UNDEPLOYED',
                mlPackageOwnedByAccountId,
                mlPackageOwnedByTenantId,
                mlPackageOwnedByProjectId,
                projectId,
            },
        });
    return res.data?.data?.dataList;
};

export const getPublicPackageById = async (mlPackageId: string | undefined, mlPackageOwnedByAccountId: string | undefined,
    mlPackageOwnedByTenantId: string | undefined, mlPackageOwnedByProjectId: string | undefined, projectId?: string): Promise<MLPackageDto | undefined> => {
    const res = await http.get<BaseResponseOfMLPackageDto>(
        /* Add Status by which should be excluded */
        URLManager.url().apiPkgManager + `/mlpackages/${mlPackageId}?status=PURGED,VALIDATION_FAILED,VALIDATING,THREAT_DETECTED`,
        {
            params: {
                mlPackageOwnedByAccountId,
                mlPackageOwnedByTenantId,
                mlPackageOwnedByProjectId,
                projectId,
            },
        });
    return res.data?.data;
};

export const getPublicPackageVersion = async (mlPackageId: string, mlPackageVersionId: string, mlPackageOwnedByAccountId: string,
    mlPackageOwnedByTenantId: string, mlPackageOwnedByProjectId: string, projectId?: string): Promise<MLPackageVersionDto | undefined> => {
    const res = await http.get<BaseResponseOfMLPackageVersionDto>(URLManager.url().apiPkgManager +
        `/mlpackages/${mlPackageId}/versions/${mlPackageVersionId}`,
        {
            params: {
                mlPackageOwnedByAccountId,
                mlPackageOwnedByTenantId,
                mlPackageOwnedByProjectId,
                projectId,
            },
        });
    return res.data?.data;
};

export const updateMLPackageVersionVisibility = async (projectId: string, mlPackageId: string, mlPackageVersionId: string, mlPackageVersionPublicUpdate: MLPackageVersionPublicUpdateDto | undefined): Promise<MLPackageVersionDto[] | undefined> => {
    const res = await http.patch<BaseResponseOfPageListingDtoOfMLPackageVersionDto>(URLManager.url().apiPkgManager + '/mlpackages/' + mlPackageId
        + '/versions/' + mlPackageVersionId + '?projectId=' + projectId, mlPackageVersionPublicUpdate);
    return res.data.data?.dataList;
};

export const getMLPackageLanguages = async (): Promise<MLPackageLanguageVersionDto[] | undefined> => {
    const res = await http.get<BaseResponseOfListOfMLPackageLanguageVersionDto>(URLManager.url().apiPkgManager + '/mlpackagelanguages');
    return res.data?.data;
};

export const getMLPackageLanguageVersions = async (mlPackageLanguage: string): Promise<MLPackageLanguageVersionDto[] | undefined> => {
    const res = await http.get<BaseResponseOfListOfMLPackageLanguageVersionDto>(URLManager.url().apiPkgManager + '/mlpackagelanguages/' + mlPackageLanguage + '/versions');
    return res.data?.data;
};

export const selfMigrateTenant = async () => {
    const res = await http.post<BaseResponseOfstring>(URLManager.url().apiPkgManager + '/migrate/tenants/self');
    return res.data?.data;
};
