const url = window.location.href;

let getAccountAndTenantFromCannonicalPath = (isNonFirstParty?: boolean): { account: string; tenant: string } => {
    if (isNonFirstParty) {
        return {
            account: '',
            tenant: '',
        };
    }
    let [ account, tenant ] = url.substring(0, url.indexOf('/aifabric_')).split('/')
        .slice(-2);
    account = account || '';
    tenant = tenant || '';
    return {
        account,
        tenant,
    };
};

if (process.env.REACT_APP_AI_ACCOUNT && process.env.REACT_APP_AI_TENANT) {
    getAccountAndTenantFromCannonicalPath = (): { account: string; tenant: string } => ({
        account: process.env.REACT_APP_AI_ACCOUNT || '',
        tenant: process.env.REACT_APP_AI_TENANT || '',
    });
}

export function isCannonicalPath(): boolean {
    return (url.indexOf('aifabric_') > -1);
}

export { getAccountAndTenantFromCannonicalPath };
