import type {
    FormikErrors,
    FormikValues,
} from 'formik/dist/types';
import React from 'react';

interface Errors {
    errors: FormikErrors<FormikValues>;
}

const FormikErrorLabels: React.FC<Errors> = (props) => (
    (
        <div style={{ display: 'none' }}>
            {
                Object.keys(props.errors).map((field: string) => (
                    <div
                        id={`${field}-error-label`}
                        key={`${field}-error-label`} >
                        {String(props.errors[field])}
                    </div>
                ))
            }
        </div>
    )
);

export default FormikErrorLabels;

