import {
    findIndex,
    isArray,
} from 'lodash';

export const recursiveMultiSearch = (xmlObj: Record<string, any>, searchKeys: string[], results: Record<string, any> = {}): Record<string, any> => {
    const r = results;
    Object.keys(xmlObj).forEach((key) => {
        const value = xmlObj[key];
        if (searchKeys.includes(key)) {
            const arr = r[`${key}`] ? r[`${key}`] : [];
            if (Array.isArray(value)) {
                arr.push(...value);
            } else {
                arr.push(value);
            }
            r[`${key}`] = arr;
        } else if (typeof value === 'object') {
            recursiveMultiSearch(value, searchKeys, r);
        }
    });
    return r;
};

export const findIndexOfKey = (arr: any, searchKey: string, altSearchKey: string): number => {
    let index = -1;
    if (isArray(arr) && arr.length > 0) {
        index = findIndex(arr, searchKey);
        if (index === -1) {
            index = findIndex(arr, altSearchKey);
        }
    }
    return index;
};
