import type { DatasetDto } from '@uipath/aifabric';
import { debounce } from 'lodash';

import { getDatasets } from '../../api/client/datasetManagerClient';
import { http } from '../../http';

interface LoadDataParams {
    projectId: string;
    cancelTokenCallback?: Function;
    finalCallback?: Function;
    thenCallback?: Function;
    catchCallback?: Function;
    loadingInProgress?: Function;
}

export const loadDataset = debounce((params: LoadDataParams) => {
    const CancelToken = http.CancelToken;
    const source = CancelToken.source();
    if (params.cancelTokenCallback) {
        params.cancelTokenCallback(source);
    }

    getDatasets(100, params.projectId)
        .then((data: DatasetDto[] | undefined) => {
            if (data) {
                if (params.thenCallback) {
                    params.thenCallback(data);
                }
            }
            return true;
        })
        .finally(() => {
            if (params.finalCallback) {
                params.finalCallback();
            }
        })
        .catch(error => {
            if (params.catchCallback) {
                params.catchCallback(error);
            }
        });
    if (params.loadingInProgress) {
        params.loadingInProgress(true);
    }
});
