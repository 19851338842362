import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { TelemetryHost } from '../telemetryHost/TelemetryHost';

export const RouterHost: React.FC = () => (
    <Router>
        <TelemetryHost />
    </Router>
);
