import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import Section from '../../components/Section';
import { AppPermissions } from '../../enums/Authorization';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import { RoutePath } from '../../route/routeMap';
import { RoleAssignmentListing } from './Base';

const ProjectLevel: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const {
        currentProjectId, currentProjectName,
    } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProjectId ?? 'undefined']?.permissionSet || [];

    const roleCreationButtons = [];
    if (permissions.indexOf(AppPermissions.Settings_Create) > -1) {
        roleCreationButtons.push({
            buttonText: t('assign_user'),
            buttonCallback: () => {
                history.push(
                    { pathname: generatePath(RoutePath.ProjectRoleAssignment, { projectName: currentProjectName }) },
                );
            },
        });
    }

    return (
        <Section
            title={t('user_management_title')}
            buttonItems={roleCreationButtons}
            tooltipText={t('tooltip_user_management_role_type')}
        >
            <RoleAssignmentListing
                level="PROJECT"
                projectId={currentProjectId}
                projectName={currentProjectName} />
        </Section>
    );
};

export default ProjectLevel;
