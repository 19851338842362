export const PipelineTypeMapping = {
    FULL_TRAINING: 'Full',
    TRAIN_ONLY: 'Train',
    EVALUATE_ONLY: 'Evaluation',
};

export const MLlogsSeverityMapping = {
    ML_SKILL: 'ML Skill',
    PIPELINE: 'Pipeline',
    ML_PACKAGE: 'ML Package',
    ML_SERVICE: 'ML Service',
    DATASET: 'Dataset',
};

export const EntityTypeNameMapping = {
    ML_SKILL: 'ml_logs_details_ml_skill_label',
    PIPELINE: 'ml_logs_details_pipeline_value',
    ML_PACKAGE: 'ml_logs_details_ml_package_label',
    ML_SERVICE: 'ml_service_label',
    ML_SKILL_VERSION: 'ml_skill_version_label',
    RUN: 'common_run_label',
    DATASET: 'ml_logs_details_dataset_label',
};
