import type { AxiosResponse } from 'axios';
import type { ReactElement } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import URLManager from '../config/URLManager';
import { PERMISSIONS_FAILED_MSG } from '../constants/TelemetryConstants';
import {
    Origin,
    Scope,
    Service,
} from '../enums/ClientErrorStrings';
import { Configuration } from '../enums/Configuration';
import { http } from '../http';
import { RoutePath } from '../route/routeMap';
import type { MLSkillInactivePeriodThreshold } from '../state-management/dispatchers/authZ';
import {
    markPublic,
    permissionsFailed,
    setTenantMigrated,
    UpdateAccountCreds,
    updateTenantType,
} from '../state-management/dispatchers/authZ';
import type { AppStore } from '../state-management/store';
import { getDisplayErrorCode } from '../utils/CommonUtils';
import logger from '../utils/Logging';

interface BootstrapProviderProps {
    children: ReactElement;
    appState: Configuration;
    isHostAdmin: boolean;
    isOnPrem: boolean;
}

const getTenantDetails = (): Promise<AxiosResponse> => http.get(URLManager.url().apiDeployer + '/mlskills/inactivityperiod');

const validateTenantCheck = (): Promise<AxiosResponse> => http.get(URLManager.url().apiPkgManager + '/tenants/check');

const checkTenantMigrationStatus = (): Promise<AxiosResponse> => http.get(URLManager.url().apiPkgManager + '/tenants/migrated/check');

const BootstrapProvider: React.FC<BootstrapProviderProps> = (
    {
        children, appState, isHostAdmin, isOnPrem,
    },
) => {

    const history = useHistory();

    function fetchMetadata(fetchGroupIds: boolean) {

        http.get(URLManager.url().apiHelper + '/bootstrap?fetchGroupIds=' + fetchGroupIds).then((response: AxiosResponse) => {
            UpdateAccountCreds(
                response.data.data.accountId,
                response.data.data.tenantId,
                response.data.data.groupIds,
            );

            if (!isHostAdmin) {
                // Check and set isPublic for tenant in redux store
                validateTenantCheck().then((isPublicResponse: AxiosResponse) => {
                    if (isPublicResponse.data.data) {
                        markPublic();
                    }
                    return response;
                })
                    .catch((error) => {
                        logger.error({
                            identifier: 'Core',
                            message: 'Error performing public tenant check',
                            error,
                        });
                    });
            }

            getTenantDetails().then((inactiveResponse: AxiosResponse) => {
                updateTenantType(inactiveResponse.data.data as MLSkillInactivePeriodThreshold);
                return true;
            })
                .catch(
                    (error) => {
                        logger.error({
                            identifier: 'Core',
                            message: 'Error getting skill in activity period',
                            error,
                        });
                    },
                );

            return true;
        })
            .catch(error => {
                const backendCode = getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.BOOTSTRAPROVIDER, error, error.response?.status);
                logger.error({
                    identifier: 'Core',
                    message: PERMISSIONS_FAILED_MSG,
                    error,
                    backendCode,
                });
                permissionsFailed(
                    error.response?.status,
                    backendCode,
                );
                history.push(RoutePath.HOME);
            });
    }

    React.useEffect(() => {
        if (appState === Configuration.APPREADY) {
            if (isOnPrem) {
                setTenantMigrated(true);
                fetchMetadata(false);
            } else {
                checkTenantMigrationStatus().then((tenantMigrationResponse: AxiosResponse) => {
                    setTenantMigrated(tenantMigrationResponse.data.data);
                    fetchMetadata(!tenantMigrationResponse.data.data);
                    return true;
                })
                    .catch((error) => {
                        logger.error({
                            identifier: 'Core',
                            message: 'Error performing tenant migration status check',
                            error,
                        });
                    });
            }
        }
    }, [ appState ]);

    return (
        <>
            {children}
        </>
    );
};

export default connect((state: AppStore) => ({
    appState: state.config.state,
    isHostAdmin: !!state.auth.isHostAdmin,
    isOnPrem: !!state.config.paths?.isOnPrem,
}))(BootstrapProvider);
