import { styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Card from './Card';

interface ActionCardProps {
    title: string;
    imgUrl: string;
    cardContent: string;
    route?: string;
    data?: any;
    onClick?: () => void;
}

const envPrefix = process.env.NODE_ENV === 'development' ? window.location.origin : '';

const CardWrapper = styled('div')({ marginBottom: '1em' });

const ContentBase = styled('div')({
    paddingTop: '1em',
    height: 130,
});

export const ActionCard: React.FC<ActionCardProps> = ({
    title,
    imgUrl,
    cardContent,
    route,
    data,
    onClick,
}) => {
    const history = useHistory();

    const handleCardClicked = useCallback((): void => {
        if (onClick) {
            onClick();
        } else {
            history.push({
                pathname: route,
                state: { data },
            });
        }
    }, [ onClick, route, data ]);

    return (
        <CardWrapper>
            <Card
                hover
                onClick={handleCardClicked}
                avatar={envPrefix + imgUrl}
                title={title}>
                <div>
                    <ContentBase
                        data-testid="portal-action-card-description">
                        {cardContent}
                    </ContentBase>
                </div>
            </Card>
        </CardWrapper>
    );
};
