import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    styled,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import { isValidCron } from 'cron-validator';
import type { FieldProps } from 'formik';
import type {
    ChangeEvent,
    ReactElement,
} from 'react';
import React, {
    useCallback,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    getCronStr,
    nextRecurrence,
} from '../../utils/CommonUtils';
import {
    addMinutes,
    getUtcDate,
} from '../../utils/DateFormatter';
import InputMimick from '../InputMimick';
import Label from '../Label';
import { AdvancedCronBuilder } from './AdvancedTab';
import { RecurringCronBuilder } from './RecurrenceTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface CronValue {
    time: Date | null;
    cronExp: string;
    recurrence: number;
}

const TabPanel = (props: TabPanelProps): ReactElement => {
    const {
        children, value, index, ...other
    } = props;
    const { t } = useTranslation();

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-label={t('a11y_public_tab_panel')}
            {...other}
        >
            {value === index && <Box p={3}>
                {children}
            </Box>}
        </Typography>
    );
};

const RecurrenceDialog = styled(Dialog)({
    '& .MuiDialogTitle-root': {
        padding: '0 0 0 24px',
        marginTop: '24px',
    },
    '& .MuiDialogContent-root': { padding: '0 0 0 24px' },
});

const CronContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    minHeight: '384px',
    minWidth: '400px',

    '& .MuiOutlinedInput-root': {
        height: Tokens.FontFamily.FontHeader1LineHeight,
        lineHeight: Tokens.FontFamily.FontHeader1LineHeight,
    },

    '& .MuiTypography-colorTextPrimary': {
        fontSize: Tokens.FontFamily.FontMSize,
        height: Tokens.FontFamily.FontHeader1LineHeight,
        fontWeight: 'normal',
        lineHeight: Tokens.FontFamily.FontHeader1LineHeight,
    },
    '& .timePicker': { '& .MuiInputBase-input': { width: '10em' } },
    '& .buttonControls': {
        position: 'absolute',
        right: 24,
        bottom: 24,
    },
    '& .flexed': {
        display: 'flex',
        alignItems: 'center',

        '& .errorContent': {
            color: theme.palette.semantic.colorErrorText,
            fontSize: Tokens.FontFamily.FontSSize,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
        },

        '& .custom-input': {
            position: 'relative',

            '& .errorContent': {
                position: 'absolute',
                bottom: 0,
                transform: 'translateY(100%)',
                width: '150px',
                wordBreak: 'break-word',
            },
        },

        '& .time-container': {
            marginRight: '50px',

            '& .MuiFilledInput-inputMarginDense': { paddingTop: '8px' },
        },

        '& .MuiInputBase-input': { width: '6rem' },
    },
    '& .flexInputContainer': {
        marginBottom: '56px',

        '&.scheduled-container': {
            marginTop: '32px',
            height: '8rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
        },

        '& .cronExpression': {
            minWidth: '260px',

            '& .cronExpressionTextField': { width: '200px' },
        },

        '& .hintText': {
            fontStyle: 'italic',
            fontWeight: 500,
            fontSize: Tokens.FontFamily.FontMSize,
        },
        '& .label': {
            minWidth: '50px',
            marginRight: '40px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '32px',
            lineHeight: Tokens.FontFamily.FontLLineHeight,
        },
    },
}));

enum TabTypes {
    Simple,
    Advanced,
}

const CronInput: React.FC<FieldProps<CronValue>> = (
    {
        field,
        form,
    }) => {
    const { t } = useTranslation();
    const timeObj = new Date();

    const [ fieldValue, setFieldValue ] = useState<CronValue>({
        time: field.value.time || addMinutes(new Date(), 5),
        cronExp: field.value.cronExp || '0 * * * ?',
        recurrence: field.value.recurrence || 0,
    });

    const activeTabType = TabTypes.Simple;
    const [ activeTab, setActiveTab ] = React.useState(activeTabType);
    const [ dialogOpen, setDialogOpen ] = useState(false);

    const closeDialog = useCallback(() => {
    // Re initialize in case of cancel
        setFieldValue({
            time: field.value.time || new Date(),
            cronExp: field.value.cronExp || '0 * * * ?',
            recurrence: field.value.recurrence || 0,
        });
        setDialogOpen(false);
    }, []);

    const openDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    let label;

    if (fieldValue.recurrence > 0) {
        label = nextRecurrence(0, fieldValue.time);
    } else if (field.value.cronExp) {
        label = isValidCron(fieldValue.cronExp) ?
            getCronStr(fieldValue.cronExp) :
            t('label_cron_expression_hint');
    } else {
        label = t('cron_select');
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => setActiveTab(newValue);

    const callback = useCallback(() => {
        form.setFieldValue(field.name, {
            ...fieldValue,
            time: fieldValue.time,
        });
        setDialogOpen(false);
    }, [ fieldValue, fieldValue.time ]);

    const advancedCallback = useCallback(() => {
        form.setFieldValue(field.name, {
            time: null,
            cronExp: fieldValue.cronExp,
            recurrence: 0,
        });
        setDialogOpen(false);
    }, [ fieldValue ]);

    return (
        <>
            <Label
                id="first-run"
                value={t('cron_first_run')}
                aria-label={`${t('cron_first_run')} label`} />
            <InputMimick
                labelledBy="first-run"
                label={label}
                onClick={openDialog} />
            <RecurrenceDialog
                maxWidth="lg"
                open={dialogOpen}>
                <DialogTitle>
                    {t('label_cron_title')}
                </DialogTitle>
                <DialogContent>
                    <CronContainer>
                        <Tabs
                            value={activeTab}
                            indicatorColor="secondary"
                            textColor="secondary"
                            onChange={handleChange}
                            aria-label={t('a11y_pipeline_scheduler')}
                        >
                            <Tab label={t('label_cron_days')} />
                            <Tab label={t('label_cron_advanced')} />
                        </Tabs>
                        <TabPanel
                            value={activeTab}
                            index={TabTypes.Simple}>
                            <RecurringCronBuilder
                                time={fieldValue.time || timeObj}
                                setTime={(time: any) => {
                                    const utcTime = getUtcDate(time);
                                    setFieldValue({
                                        cronExp: `${utcTime.getMinutes()} ${utcTime.getHours()} * * *`,
                                        time,
                                        recurrence: fieldValue.recurrence,
                                    });
                                }}
                                onRecurrenceDaysChange={(evt: any, time) => {
                                    setFieldValue({
                                        cronExp: fieldValue.cronExp,
                                        time,
                                        recurrence: parseInt(evt.target.value, 10),
                                    });
                                }}
                                recurrenceDays={fieldValue.recurrence}
                                closeDialog={closeDialog}
                                callback={callback}
                            />
                        </TabPanel>
                        <TabPanel
                            value={activeTab}
                            index={TabTypes.Advanced}>
                            <AdvancedCronBuilder
                                cronExp={fieldValue.cronExp}
                                onChange={(val: ChangeEvent<HTMLInputElement>): void => {
                                    setFieldValue({
                                        time: null,
                                        cronExp: val.target.value,
                                        recurrence: 0,
                                    });
                                }}
                                closeDialog={closeDialog}
                                callback={advancedCallback}
                            />
                        </TabPanel>
                    </CronContainer>
                </DialogContent>
            </RecurrenceDialog>
        </>

    );
};

export default CronInput;
