import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';

export const StyledSectionContainer = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    width: '340px',

    '& #dropzone': { overflow: 'hidden' },
});

interface StyledDropZoneContainerProps {
    error: boolean;
    disabled?: boolean;
}
export const StyledDropZoneContainer = styled('div')<StyledDropZoneContainerProps>(({
    theme, error, disabled,
}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '0.5rem',
    cursor: 'pointer',

    width: '340px',
    height: '108px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: `solid 1px ${theme.palette.semantic.colorBorder}`,
    margin: 0,

    '&:focus': { border: `solid 2px ${theme.palette.semantic.colorForegroundLink}` },

    ...(error && { border: `solid 2px ${theme.palette.semantic.colorErrorText}` }),

    ...(!disabled && { '&:hover': { border: `solid 1px ${theme.palette.semantic.colorForegroundEmp}` } }),
}));

export const StyledDropzoneUploadContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const StyledCloudUploadIcon = styled(CloudUploadIcon)(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontHeroSize,
    color: theme.palette.semantic.colorForegroundDisable,
    alignSelf: 'center',
}));

export const StyledDropzoneContent = styled('div')<{ isEmpty: boolean }>(({
    theme, isEmpty,
}) => ({
    ...(isEmpty
        ? {
            color: theme.palette.semantic.colorForeground,
            fontSize: theme.typography.body1.fontSize,
            textAlign: 'center',
        }
        : {
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
            fontSize: theme.typography.body1.fontSize,
            textAlign: 'center',
        }
    ),
}));

export const StyledDropzoneErrorContent = styled('div')(({ theme }) => ({
    color: theme.palette.semantic.colorErrorText,
    fontSize: Tokens.FontFamily.FontSSize,
    fontWeight: Tokens.FontFamily.FontWeightDefault,
    margin: '8px 14px 0',
}));
