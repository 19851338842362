import {
    ChevronLeft,
    ChevronRight,
} from '@mui/icons-material';
import {
    IconButton,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type {
    ReactElement,
    ReactNode,
} from 'react';
import React, {
    createRef,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    Redirect,
    Route,
    Switch,
    useLocation,
    useParams,
} from 'react-router-dom';
import { Key } from 'ts-keycode-enum';

import { useFeedback } from '../../api/global/useFeedback';
import ErrorDialog from '../../components/ErrorDialog';
import FullPageLoader from '../../components/FullPageLoader';
import SideBarLink from '../../components/SideBarLink';
import type { AppPermissions } from '../../enums/Authorization';
import { Configuration } from '../../enums/Configuration';
import FeatureFlagManager from '../../feature-flag/FeatureFlagManager';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import { getAccountAndTenantFromCannonicalPath } from '../../route/routeHelper';
import type { NavRouteMap } from '../../route/routeMap';
import getDefaultNavRoutes, { RoutePath } from '../../route/routeMap';
import { bindKeyTo } from '../../utils/a11y';
import { trackNavigation } from '../../utils/AppInsights';

const canAccess = (from: AppPermissions[], toCheck: AppPermissions[], featureFlag = ''): boolean => {
    let isAccessible = false;
    // for loop to be able to break
    for (const userPermission of toCheck) {
        if (from.indexOf(userPermission) > -1) {
            isAccessible = true;
            break;
        }
    }

    return isAccessible &&
        (featureFlag === '' || Boolean(FeatureFlagManager.getInstance()?.isEnabled(featureFlag)));
};

interface ProjectHostProps {
    configState: Configuration;
}

export const homePageCallback = (): void => {
    const { account } = getAccountAndTenantFromCannonicalPath();
    // If not cannonical URL then navigate to app home
    window.location.href = account ? `${window.location.origin}/${account}/portal_/home` : RoutePath.HOME;
};

export const aiCenterCallback = (): void => {
    window.location.href = RoutePath.HOME;
};

export const ProjectHostFC: React.FC<ProjectHostProps> = ({ configState }) => {
    const { t } = useTranslation();
    const routes = getDefaultNavRoutes(t);
    const sideBarRoutes: NavRouteMap[] = routes.filter(route => route.showOnSideNav);
    const location = useLocation();
    const feedback = useFeedback();

    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.tenantData.permissionSet;

    if (configState === Configuration.NETWORK_ISSUE_UNAUTHORIZED) {
        feedback.enqueueError(t('unauthorized_call'));
    }

    if (configState === Configuration.NETWORK_ISSUE) {
        feedback.enqueueError(t('network_issue'));
    }

    return (
        <>
            <FullPageLoader
                open={
                    location.pathname === RoutePath.REDIRECT_URL
                }
            />
            <Switch>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={(): ReactElement => (
                            route.global ?
                                (<PageComponent
                                    permissions={permissions}
                                    sideBarRoutes={sideBarRoutes}
                                    route={route} />) :
                                (<PageComponentWithProject
                                    tenantPermissions={permissions}
                                    sideBarRoutes={sideBarRoutes}
                                    route={route} />)
                        )}
                    />
                ))}
                <Redirect to={RoutePath.HOME} />
            </Switch>
        </>
    );
};

interface PageComponentProps {
    route: NavRouteMap;
    sideBarRoutes: NavRouteMap[];
    permissions: AppPermissions[];
}

export const PageComponentWithProject: React.FC<{
    route: NavRouteMap;
    sideBarRoutes: NavRouteMap[];
    tenantPermissions: AppPermissions[];
    children?: ReactNode;
}> = ({
    sideBarRoutes, route, tenantPermissions,
}) => {
    const { projectName } = useParams<{ projectName: string }>();
    const {
        state, actions,
    } = useContext(ProjectsContext);
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[state.currentProject?.id ?? '']?.permissionSet || [];

    React.useEffect(() => {
        if (state.currentProject === undefined || state.currentProject.name !== projectName) {
            actions.setCurrent(projectName);
        }
    }, [ state.currentProject, projectName ]);

    if (!permissions.length) {
        return null;
    }

    return (
        <PageComponent
            permissions={[ ...permissions, ...tenantPermissions ]}
            sideBarRoutes={sideBarRoutes}
            route={route} />
    );
};

const PageContent = styled('div')({
    display: 'flex',
    flex: 1,
    overflowX: 'auto',
    overflowY: 'hidden',

    '& .minPageWidth': {
        flex: 1,
        height: 'calc(100vh - 56px)',
        overflowY: 'auto',
    },
});

const PageComponent: React.FC<PageComponentProps> = ({
    route, permissions, sideBarRoutes,
}) => {
    const { projectName } = useParams<{ projectName: string }>();
    const {
        state: projectsState, actions: projectsActions,
    } = useContext(ProjectsContext);
    useEffect(() => {
        trackNavigation(projectName ? route.path.replace(':projectName', projectName) : route.path);
        route.pageTitle && (document.title = route.pageTitle);
    }, []);

    useEffect(() => {
        if (route.global) {
            const {
                projects, status, currentProjectName,
            } = projectsState;
            if (projects && projects.length === 0 && status === 'INITIAL') {
                projectsActions.forceRefresh();
            }
            if (currentProjectName) {
                projectsActions.clearProject();
            }
        }
    }, [ route ]);

    return (
        <>
            {!route.global ? (
                <StatefulSideNav
                    sideBarRoutes={sideBarRoutes}
                    permissions={permissions}
                />
            ) : null}
            <PageContent>
                {!route.permission || (route.permission && canAccess(permissions, route.permission)) ? (
                    route.global ? React.createElement(route.component) : (
                        <div className="minPageWidth">
                            <ComponentWithProject>
                                {React.createElement(route.component)}
                            </ComponentWithProject>
                        </div>
                    )
                ) : (
                    <Unauthorized />
                )}
            </PageContent>
        </>
    );
};

interface ComponentWithProjectProps {
    children: ReactNode;
}

const ComponentWithProject: React.FC<ComponentWithProjectProps> = (props) => {
    const { state } = useContext(ProjectsContext);

    return state.currentProject?.name ? (<>
        {' '}
        {props.children}
    </>) : null;
};

interface SideNavProps {
    permissions: AppPermissions[];
    className: string;
    projectName: string;
    sideBarRoutes: NavRouteMap[];
}

interface StatefulSideNavProps {
    permissions: AppPermissions[];
    sideBarRoutes: NavRouteMap[];
}

const Unauthorized: React.FC = () => {
    const { t } = useTranslation();

    return (
        <ErrorDialog
            backendCode={null}
            title={t('permission_error_title')}
            buttonCallback={homePageCallback}
            buttonText={t('tenant_error_home_button')}
            secondaryButtonCallback={aiCenterCallback}
            secondaryButtonText={t('generic_error_AIF_home_button')}
        />
    );
};

const SidebarContainer = styled('div')(({ theme }) => ({
    minWidth: '312px',
    width: '312px',
    transitionDuration: '1s',
    '&.collapsed': {
        minWidth: '16px',
        width: '16px',
    },

    '& .navContainer': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,

        '&:hover': {
            borderRight: `solid 1px ${Tokens.Colors.ColorBlue500}`,
            right: '-1px',
        },

        '&:focus': {
            borderRight: `solid 1px ${Tokens.Colors.ColorBlue500}`,
            right: '-1px',
        },

        '&:focus .focusHack': { display: 'initial' },

        '& .focusHack': { display: 'none' },

        '& .navbtn': {
            position: 'absolute',
            right: '-12px',
            zIndex: 99,
            top: '10px',
            height: '24px',
            width: '24px',
            background: theme.palette.background.paper,
            color: Tokens.Colors.ColorInk450,
            opacity: 0,
            transitionDuration: '300ms',
            boxShadow: '0px 1px 2px 0px #000000 15%',
            border: `1px solid ${Tokens.Colors.ColorInk300}`,

            '&.visible': { opacity: 1 },
        },
    },

    '& .sideBarContent': {
        height: '100%',
        flexDirection: 'column',
        // overflowX: "hidden",
        borderRight: 'solid',
        borderWidth: '1px',
        borderRightColor: theme.palette.semantic.colorBorderDeEmp,
        // overflowY: "auto",
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        transitionDuration: '300ms',
    },
}));

const StatefulSideNav: React.FC<StatefulSideNavProps> = ({
    permissions, sideBarRoutes,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { state } = useContext(ProjectsContext);
    const ref = createRef<any>();
    const [ collapsed, setCollapsed ] = useState(matches);
    const click = () => {
        setCollapsed(!collapsed);
    };
    const cb = useCallback(bindKeyTo({
        traps: {
            [Key.Enter]: click,
            [Key.Space]: click,
        },
        ignoreTarget: true,
    }), [ ref ]);

    React.useEffect(() => {
        setCollapsed(matches);
    }, [ matches ]);

    return (state.currentProject && state.currentProject.name) ? (
        <SidebarContainer
            className={collapsed ? ' collapsed' : ''}
            style={{ position: 'relative' }}>
            <div
                className="navContainer"
                tabIndex={0}
                role="button"
                onKeyDown={cb}
                onClick={click}
                aria-label={t('a11y_app_open_collapse_side_menu')}
            >
                <IconButton
                    onClick={click}
                    color="secondary"
                    size="medium"
                    tabIndex={-1}
                    aria-label={t('a11y_app_open_collapse_side_button')}
                    className="navbtn visible"
                >
                    <ChevronRight style={{ display: collapsed ? 'block' : 'none' }} />
                    <ChevronLeft style={{ display: !collapsed ? 'block' : 'none' }} />
                    <span className="focusHack MuiTouchRipple-root">
                        <span
                            className="MuiTouchRipple-ripple MuiTouchRipple-rippleVisible MuiTouchRipple-ripplePulsate"
                            style={{
                                width: '22px',
                                height: '22px',
                                top: 0,
                                left: 0,
                            }}
                        >
                            <span className="MuiTouchRipple-child MuiTouchRipple-childPulsate" />
                        </span>
                    </span>
                </IconButton>
            </div>
            <SideNav
                permissions={permissions}
                sideBarRoutes={sideBarRoutes}
                className={'sideBarContent' + (collapsed ? ' collapsed' : '')}
                projectName={state.currentProject.name} />
        </SidebarContainer>
    ) : null;
};

const BottomCenteredContent = styled('div')({
    position: 'absolute',
    bottom: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
});

export const SideNav: React.FC<SideNavProps> = ({
    permissions, sideBarRoutes, className, projectName,
}) => {
    const { t } = useTranslation();
    const sidebarLinks = sideBarRoutes
        .filter((route: NavRouteMap) => !route.permission || (route.permission && canAccess(permissions, route.permission, route.featureFlag)))
        .map((route, index) => (
            <SideBarLink
                isFirstElement={index === 0}
                key={index}
                name={route.translated ? t(route.name) : route.name}
                path={generatePath(route.path, { projectName })} />
        ));

    return (
        <div className={className}>
            <div className="sidebar-links">
                {sidebarLinks}
            </div>
            <BottomCenteredContent
                data-testid="aif-version">
                {process.env.APP_VERSION}
            </BottomCenteredContent>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    failureCode: state.authZ.failureCode,
    backendCode: state.authZ.backendCode,
    configState: state.config.state,
}))(ProjectHostFC);
