import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import type { AuthenticationConfig } from '../../../state-management/states';
import RoleAssignmentComponent from './Base';

const TenantLevelAssignment: React.FC<{
    token: string;
    authConfig: AuthenticationConfig;
}> = ({
    token,
    authConfig,
}) => {

    const state: any = useLocation()?.state;
    const userInfo = state.data.userInfo;

    return (
        <RoleAssignmentComponent
            identityUrl={authConfig.identityAPIUrl}
            level="TENANT"
            token={token}
            userInfo={userInfo} />
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    token: state.auth.authToken,
    authConfig: state.config.paths ? state.config.paths.authConfig : {},
}))(TenantLevelAssignment);
