import {
    XMLBuilder,
    XMLParser,
} from 'fast-xml-parser';

export const parseXmlToObject = (xml: string): Record<string, any> => {
    const alwaysArray = [
        'Choices',
        'Choice',
        'Labels',
        'Label',
    ];
    const parser = new XMLParser({
        ignoreAttributes: false,
        isArray: (jpath): boolean => {
            if (alwaysArray.indexOf(jpath) !== -1) {
                return true;
            }
            return false;
        },
    });
    return parser.parse(xml);
};

export const parseObjectToXml = (xmlObj: Record<string, any>): string => {
    const builder = new XMLBuilder({
        ignoreAttributes: false,
        suppressBooleanAttributes: false,
        suppressEmptyNode: true,
        format: true,
    });
    return builder.build(xmlObj);
};
