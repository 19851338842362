import {
    Button,
    styled,
    Tab,
    Tabs,
} from '@mui/material';
import type {
    MLPackageVersionDto,
    ProjectDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import type { ReactNode } from 'react';
import React, {
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    connect,
    useSelector,
} from 'react-redux';
import {
    generatePath,
    Link,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';

import {
    deletePackageVersion,
    getPackageById,
    getPackageVersionById,
    getPublicProjectsByTenantId,
    updateMLPackageVersionVisibility,
} from '../../api/client/pkgManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import type { BreadcrumbProps } from '../../components/Breadcrumbs';
import {
    CustomDialog,
    MLPackageVersionDetailsDialog,
    MLPackageVersionPublicUpdateDialog,
    TooltipDialog,
} from '../../components/Dialog';
import Section from '../../components/Section';
import type { ColumnDefinition } from '../../components/Table/BaseTable/types';
import ServerSideTable
    from '../../components/Table/ServerSideTable/ServerSideTable';
import { WithVisibility } from '../../components/WithVisibility';
import URLManager from '../../config/URLManager';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../constants/BlobStorageConstants';
import Mappings from '../../constants/TableHeaderMappings';
import { AppPermissions } from '../../enums/Authorization';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import { RoutePath } from '../../route/routeMap';
import {
    dataFormatter,
    downloadContentWithSignUrl,
    downloadMLPackageVersionMetadata,
    extractErrorMessage,
    getMLPackageVersionDetailData,
} from '../../utils/CommonUtils';
import logger from '../../utils/Logging';
import { MlLogsPageContent } from '../mlLogs/MlLogsPage';
import { setClearOrDoneIcon } from '../mlSkill/list/MlSkillPage';
import { PipelineList } from '../pipeline/list/PipelineList';

const MLPackageVersionTabContainer = styled('div')({
    width: '100%',

    '& .tabBarArea': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '15px',

        '& .tabBar': { width: '53%' },
    },
});

const MLPackageVersionsPageContainer = styled('div')({
    fontSize: Tokens.FontFamily.FontSSize,
    width: '100%',
    fontFamily: Tokens.FontFamily.FontNormal,
    overflowY: 'auto',

    '& .mlPackageVersionList': { marginBottom: '50px' },

    '& .mlPackageVersionTitle': { fontSize: 4 },
});

export interface PackageVersionProps {
    version?: number;
    trainingVersion?: number;
    customVersion?: string;
}

interface MLPackageVersionsPageProps {
    isPublicTenant: boolean;
    isOnPrem: boolean;
    validatedMlPackageBucket: string;
    isInstanceProfileEnabled: boolean;
    azureStorageFQDNSuffix: string;
}

const MLPackageVersionsPage: React.FC<MLPackageVersionsPageProps> = ({
    isPublicTenant, isOnPrem, validatedMlPackageBucket, isInstanceProfileEnabled, azureStorageFQDNSuffix,
}) => {
    const {
        mlpackageName, projectName,
    } = useParams<{ mlpackageName: string; projectName: string }>();
    const { t } = useTranslation();

    const {
        currentProject, currentProjectId,
    } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProjectId ?? 'undefined']?.permissionSet || [];

    /* Generate breadcrum for ML Package version page */
    const mlPackageVersionbreadcrumProps: BreadcrumbProps =
    {
        pageTitle: mlpackageName,
        crumbs: [
            {
                title: t('common_ml_package_label'),
                link: generatePath(RoutePath.MLPACKAGES, { projectName }),
            },
        ],
    };

    return (
        <MLPackageVersionsPageContainer>
            {(permissions.indexOf(AppPermissions.MLPackages_View) > -1) ? (
                <div>
                    <Section
                        breadcrumProps={mlPackageVersionbreadcrumProps}
                        className="mlPackageVersionList" />
                    <Section title={mlpackageName}>
                        <MLPackageVersionTab
                            permissions={permissions}
                            currentProject={currentProject}
                            isPublicTenant={isPublicTenant}
                            isOnPrem={isOnPrem}
                            validatedMlPackageBucket={validatedMlPackageBucket}
                            isInstanceProfileEnabled={isInstanceProfileEnabled}
                            azureStorageFQDNSuffix={azureStorageFQDNSuffix} />
                        {' '}
                    </Section>
                </div>) : null}
        </MLPackageVersionsPageContainer>
    );
};

interface MLPackageVersionTabProps {
    currentProject: ProjectDto | undefined;
    permissions: AppPermissions[];
    isPublicTenant: boolean;
    isOnPrem: boolean;
    validatedMlPackageBucket: string;
    isInstanceProfileEnabled: boolean;
    azureStorageFQDNSuffix: string;
}

export interface MLPackageVersionPublicUpdateData {
    isPublic: boolean;
    mlPackageName: string;
    mlPackageVersion: string;
}

export interface MLPackageVersionDetailsData {
    inputType?: string;
    inputDescription?: string;
    outputDescription?: string;
    changeLog?: string;
    recommendGpu?: boolean;
    enableTraining?: boolean;
    mlPackage?: string;
    version?: number;
    trainingVersion?: number;
    customVersion?: string;
}

const MLPackageVersionTab: React.FC<MLPackageVersionTabProps> = ({
    currentProject, isPublicTenant, isOnPrem, validatedMlPackageBucket, isInstanceProfileEnabled, azureStorageFQDNSuffix,
}) => {
    const { t } = useTranslation();
    const {
        projectName, mlpackageName, mlpackageId,
    } = useParams<{ projectName: string; mlpackageName: string; mlpackageId: string }>();

    const [ value, setValue ] = React.useState(0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state: any = useLocation()?.state;
    const history = useHistory();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: React.SetStateAction<number>): void => {
        setValue(newValue);
    };
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProject?.id ?? '']?.permissionSet || [];

    // Base Audit logs Url for ML Package entity wuthout project ID
    const auditsUrl = URLManager.url().apiHelper + '/audits?components=ML_PACKAGE&sortBy=createdOn&sortOrder=DESC&entityId=' + mlpackageId;

    const handleUploadNewVersionClick = (): void => {
        if (state?.data?.sourcePackageId) {
            getPublicProjectsByTenantId(state.data.mlPackageOwnedByTenantId).then((projectDtos) => {
                if (projectDtos) {
                    projectDtos.forEach(projectDto => {
                        if (projectDto.id?.toLowerCase() === state.data.mlPackageOwnedByProjectId.toLowerCase()) {
                            history.push({
                                pathname: generatePath(RoutePath.UIPATH_PACKAGE, {
                                    projectName,
                                    uiPathProjectName: projectDto.name,
                                    uiPathPackageId: state.data.sourcePackageId,
                                }),
                                state: {
                                    data: {
                                        mlpackageId,
                                        newVersion: true,
                                        mlpackageName,
                                        mlPackageOwnedByAccountId: state.data.mlPackageOwnedByAccountId,
                                        mlPackageOwnedByTenantId: state.data.mlPackageOwnedByTenantId,
                                        mlPackageOwnedByProjectId: state.data.mlPackageOwnedByProjectId,
                                    },
                                },
                            });
                        }
                    });
                }
                return true;
            })
                .catch((error: Error) => {
                    logger.error({
                        identifier: 'ML Package versions',
                        message: 'Error getting public projects',
                        error,
                    });
                });
        } else {
            history.push(generatePath(RoutePath.NEW_VERSION_MLPACKAGE, {
                projectName,
                mlpackageId,
                mlpackageName,
            }));
        }
    };

    return (
        <MLPackageVersionTabContainer>
            <div className="tabBarArea">
                <div className="tabBar">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label={t('a11y_ml_package_version_tab_label')}
                        indicatorColor="secondary"
                        textColor="secondary">
                        <Tab label={t('ml_package_version_tab_label_version')} />
                        <Tab label={t('ml_package_version_tab_label_pipeline_runs')} />
                        <Tab label={t('ml_logs_title')} />
                    </Tabs>
                </div>
                <WithVisibility visible={(permissions.indexOf(AppPermissions.MLPackages_Edit) > -1)}>
                    <div>
                        {(value === 0 || value === 2) ?
                            <Button
                                variant="outlined"
                                color="secondary"
                                aria-label={t('a11y_ml_package_version_upload')}
                                onClick={handleUploadNewVersionClick}
                                data-testid="upload-version-btn"
                            >
                                {t('ml_package_upload_new_version')}
                            </Button> :
                            <WithVisibility visible={(permissions.indexOf(AppPermissions.MLPipeline_Create) > -1)}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    component={Link}
                                    aria-label={t('a11y_create_pipeline_button')}
                                    to={generatePath(RoutePath.CREATE_PIPELINE, { projectName })}>
                                    {t('pipeline_list_create_new_button')}
                                </Button>
                            </WithVisibility>}
                    </div>
                </WithVisibility>
            </div>

            <TabPanel
                value={value}
                index={0}>
                <MLPackageVersionslist
                    permissions={permissions}
                    currentProject={currentProject}
                    isPublicTenant={isPublicTenant}
                    isOnPrem={isOnPrem}
                    validatedMlPackageBucket={validatedMlPackageBucket}
                    isInstanceProfileEnabled={isInstanceProfileEnabled}
                    azureStorageFQDNSuffix={azureStorageFQDNSuffix} />
            </TabPanel>
            <TabPanel
                value={value}
                index={1}>
                <PipelineList
                    canDelete={(permissions.indexOf(AppPermissions.MLPipeline_Delete) > -1)}
                    currentProject={currentProject}
                    sourcePackageId={mlpackageId} />
            </TabPanel>
            <TabPanel
                value={value}
                index={2}>
                <MlLogsPageContent
                    auditsUrl={auditsUrl}
                    entityLogsPage
                    breadCrumbPath={generatePath(RoutePath.MLPACKAGE_VERSION, {
                        projectName,
                        mlpackageId,
                        mlpackageName,
                    })} />
            </TabPanel>
        </MLPackageVersionTabContainer>
    );
};

interface TabPanelProps {
    children: ReactNode;
    value: number;
    index: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({
    children, value, index,
}) => (
    <div>
        {
            value === index && <div>
                {children}
            </div>
        }
    </div>
);

const MLPackageVersionslist: React.FC<MLPackageVersionTabProps> = ({
    currentProject, permissions, isPublicTenant, validatedMlPackageBucket, isInstanceProfileEnabled, azureStorageFQDNSuffix,
}) => {
    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: ColumnDefinition[] = [
        ...Mappings.VersionsList,
        {
            header: `${t('common_status_label')}`,
            accessorKey: 'status',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dataFormatter(t(`ML_PACKAGE_STATUS_${getValue()}`), i18n.language),
        },
        {
            header: `${t('mlpackage_training_enabled_label')}`,
            accessorKey: 'retrainable',
            enableSorting: true,
            cell: ({ cell: { getValue } }): React.ReactElement => setClearOrDoneIcon(Number(getValue()), t),
        },
    ];

    if ((isPublicTenant === true)) {
        dataMapper.push({
            header: `${t('common_is_public_label')}`,
            accessorKey: 'isPublic',
            enableSorting: true,
            cell: ({ cell: { getValue } }): React.ReactElement => setClearOrDoneIcon(Number(getValue()), t),
        });
    }

    const mlPackagesBaseUrl = URLManager.url().apiPkgManager + '/mlpackages';
    const versionsUrl = '/versions';
    const versionListUrl = versionsUrl + '?sortBy=versions&sortOrder=DESC&projectId=' + currentProject?.id;
    const [ totalVersion, setTotalVersion ] = React.useState(0);
    const history = useHistory();
    const authToken = useSelector((state: any) => state.auth.authToken);

    const { mlpackageId } = useParams<{ mlpackageId: string }>();
    const feedback = useFeedback();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _data = useRef<any>('');
    const { mlpackageName } = useParams<{ mlpackageName: string }>();

    const [ mlPackageVersionDetailData, setMlPackageVersionDetailData ] = React.useState({} as MLPackageVersionDetailsData);
    const mlPackageVersionPublicUpdateData: MLPackageVersionPublicUpdateData = {} as MLPackageVersionPublicUpdateData;
    mlPackageVersionPublicUpdateData.isPublic = _data.current.isPublic;
    mlPackageVersionPublicUpdateData.mlPackageName = mlpackageName;
    mlPackageVersionPublicUpdateData.mlPackageVersion = (_data.current?.customVersion ? _data.current?.customVersion : _data.current?.version) + '.' + _data.current?.trainingVersion;

    const [ open, setOpen ] = useState(false);
    const [ deleteOpen, setDeleteOpen ] = useState(false);
    const [ changeIsPublicOpen, setChangeIsPublicOpen ] = useState(false);
    const [ mlPackageVersionDetailsOpen, setMlPackageVersionDetailsOpen ] = useState(false);
    const [ itemDeletedFlag, setItemDeletedFlag ] = useState(false);
    const [ itemChangeIsPublicFlag, setItemChangeIsPublicFlag ] = useState(false);

    const closeDialog = (): void => setOpen(false);
    const closeDeleteDialog = (): void => setDeleteOpen(false);
    const closeChangeIsPublicDialog = (): void => setChangeIsPublicOpen(false);
    const closeMLPackageVersionDetailsDialog = (): void => setMlPackageVersionDetailsOpen(false);
    const totalCountListener = (versionCount: number): void => setTotalVersion(versionCount);

    const deleteVersion = (): void => {
        deletePackageVersion(_data.current.id, currentProject?.id).then(() => {
            closeDeleteDialog();
            feedback.enqueueSuccess(t('feedback_delete_success_mlpkg_version'));
            if (totalVersion === 1) {
                history.push(generatePath(RoutePath.MLPACKAGES, { projectName: currentProject?.name }));
            } else {
                // toggle the flag to reload table data
                setItemDeletedFlag(prev => !prev);
            }
            return true;
        })
            .catch(error => {
                closeDeleteDialog();
                logger.error({
                    identifier: 'ML Package versions',
                    message: 'Error deleting package versions',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_delete_error_mlpkg_version'),
                    {
                        10602: {
                            1: 'ML Package version',
                            0: _data.current.id,
                        },
                        20102: {},
                        20114: { 0: _data.current.id },
                    },
                ));
            });
    };

    const changeIsPublicVersion = (isPublic: boolean): void => {
        updateMLPackageVersionVisibility(_data.current.projectId, mlpackageId, _data.current.id,
            { isPublic }).then(() => {
            closeChangeIsPublicDialog();
            feedback.enqueueSuccess(t('feedback_change_is_public_success_mlpkg_version'));
            // toggle the flag to reload table data
            setItemChangeIsPublicFlag(prev => !prev);
            return true;
        })
            .catch(error => {
                closeChangeIsPublicDialog();
                logger.error({
                    identifier: 'ML Package versions',
                    message: 'Error changing is public package versions',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(error, t('feedback_change_is_public_error_mlpkg_version')));
            });
    };

    const disablePipelineDetailsMenuItem = useCallback((rowInfo: any): boolean => {
        const versionDto: MLPackageVersionDto = rowInfo as MLPackageVersionDto;
        return !versionDto.runId;
    }, []);

    const fetchTagsAndLabelsAndDownloadModel = (mlPackageVersionId: string, metadata: MLPackageVersionDto, metadataFileame: string, signedUrl: string): void => {

        let packageTagsAndlabels: any;
        let packageVersionTagsAndlabels;
        getPackageById(mlpackageId, currentProject?.id, true).then((mlPackage) => {
            packageTagsAndlabels = mlPackage?.tagsAndLabels;
            getPackageVersionById(mlpackageId, mlPackageVersionId, currentProject?.id, true).then((mlPackageVersion) => {
                packageVersionTagsAndlabels = mlPackageVersion?.tagsAndLabels;
                if (packageVersionTagsAndlabels && packageTagsAndlabels) {
                    packageTagsAndlabels = JSON.parse(packageTagsAndlabels);
                    packageVersionTagsAndlabels = JSON.parse(packageVersionTagsAndlabels);
                    const keys = new Set(Object.keys(packageTagsAndlabels).concat(Object.keys(packageVersionTagsAndlabels)));
                    for (const key of keys) {
                        let tagsOrLabels = {};
                        if (key in packageVersionTagsAndlabels && key in packageTagsAndlabels) {
                            tagsOrLabels = packageTagsAndlabels[key].concat(packageVersionTagsAndlabels[key]);
                        } else if (key in packageTagsAndlabels) {
                            tagsOrLabels = packageTagsAndlabels[key];
                        } else {
                            tagsOrLabels = packageVersionTagsAndlabels[key];
                        }
                        packageTagsAndlabels[key] = tagsOrLabels;
                    }
                    metadata.tagsAndLabels = JSON.stringify(packageTagsAndlabels);
                } else if (!packageVersionTagsAndlabels) {
                    metadata.tagsAndLabels = packageTagsAndlabels;
                } else if (!packageTagsAndlabels) {
                    metadata.tagsAndLabels = packageVersionTagsAndlabels;
                }
                downloadContentWithSignUrl(signedUrl, isInstanceProfileEnabled, authToken, azureStorageFQDNSuffix);
                downloadMLPackageVersionMetadata(JSON.stringify(metadata), metadataFileame, 'text/plain');
                return true;
            })
                .catch(error => {
                    logger.error({
                        identifier: 'ML Package download',
                        message: 'Error getting package version by id based on tags',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(error, t('ml_package_version_loading_error_for_tags_and_labels'), {
                        10602: {
                            1: 'MLPackage',
                            0: mlpackageId,
                        },
                    }));
                });
            return mlPackage;
        })
            .catch(error => {
                logger.error({
                    identifier: 'ML Package download',
                    message: 'Error getting ML package by id based on tags',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(error, t('ml_package_loading_error_for_tags_and_labels'), {
                    10602: {
                        1: 'MLPackage',
                        0: mlpackageId,
                    },
                }));
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const menuItems: any[] = [];

    menuItems.push({
        text: 'ml_package_version_details',
        click: (event: any, data: any) => {
            _data.current = data.original;
            setMlPackageVersionDetailData(getMLPackageVersionDetailData(data.original));
            setMlPackageVersionDetailsOpen(true);
        },
    });
    if ((permissions.indexOf(AppPermissions.MLPackages_Create) > -1)) {
        menuItems.push({
            text: 'common_download_label',
            click: (event: any, row: any): void => {
                const contentUri = row.original.contentUri;
                let metadataFileame = '';
                if (contentUri.length) {
                    const metadata: MLPackageVersionDto = row.original;
                    if (row.original.customVersion) {
                        metadataFileame = row.original.mlPackageDisplayName + '_v' + row.original.customVersion + '.' + row.original.trainingVersion + '_metadata.json';
                    } else {
                        metadataFileame = row.original.mlPackageDisplayName + '_v' + row.original.version + '.' + row.original.trainingVersion + '_metadata.json';
                    }

                    const updatedBucketName = validatedMlPackageBucket?.replaceAll('-', '_');
                    let contentUrl = contentUri.replace('/' + validatedMlPackageBucket + '/o/', '/' + validatedMlPackageBucket + '/');
                    contentUrl = contentUrl.replace('/' + validatedMlPackageBucket + '/', '/' + updatedBucketName + '/');
                    const uri = decodeURIComponent(contentUrl);
                    const arr = uri.split('?')[0].split('/');
                    let modelUrl = '';
                    const length = arr.length;
                    for (let i = 0; i < length; i++) {
                        const str = arr.pop();
                        if (str === updatedBucketName) {
                            break;
                        }
                        if (str !== '' && modelUrl !== '') {
                            modelUrl = str + '/' + modelUrl;
                        } else {
                            modelUrl = str + modelUrl;
                        }
                    }
                    const mlpackagePath = modelUrl;

                    const signedUrl = URLManager.url().apiPkgManager + '/signedURL?mlPackageName=' + mlpackagePath + '&signingMethod=GET&urlEncoded=false';
                    // Attach tags and labels to metadata if applicable
                    fetchTagsAndLabelsAndDownloadModel(row.original.id, metadata, metadataFileame, signedUrl);
                } else {
                    feedback.enqueueError(t('feedback_download_ml_package_error'));
                }
            },
            type: 'downloadMLPackageVersion',
        });
    }

    menuItems.push({
        text: 'ml_package_version_pipeline_details_label',
        click: (event: any, data: any) => {
            // when clicked, it should go to particular pipeline run url.
            history.push(generatePath(RoutePath.PIPELINE_DATA, {
                projectName: currentProject?.name,
                pipelineRunId: data.original.runId,
            }));
        },
        disable: disablePipelineDetailsMenuItem,
    });

    if ((permissions.indexOf(AppPermissions.MLPackages_Delete) > -1)) {
        menuItems.push({
            text: 'ml_package_version_remove',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any) => {
                _data.current = data.original;
                setDeleteOpen(true);
            },
            type: 'deleteMLPackageVersionDto',
        });

        if ((isPublicTenant === true)) {
            menuItems.push({
                text: 'ml_package_version_is_public',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                click: (event: any, data: any) => {
                    _data.current = data.original;
                    setChangeIsPublicOpen(true);
                },
                type: 'updateMLPackageVersionDto',
            });
        }
    }

    return (
        <MLPackageVersionsPageContainer>

            <TooltipDialog
                open={open}
                data={_data.current}
                handleClose={closeDialog}
                closeIconButton={false}
                primaryButtonText={t('dialog_button_close_text')}
            />

            <CustomDialog
                title={t('ml_package_version_delete_dialog_title_text',
                    {
                        mlPackageVersion: _data.current?.customVersion ? _data.current?.customVersion : _data.current?.version
                            + '.' + _data.current?.trainingVersion,
                    })}
                open={deleteOpen}
                handleClose={closeDeleteDialog}
                closeIconButton
                infoText={t('ml_package_version_delete_dialog_info_text')}
                warningText={t('ml_package_delete_dialog_warning_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={deleteVersion}
                secondarybuttonCallback={closeDeleteDialog}
            />

            <MLPackageVersionDetailsDialog
                data={mlPackageVersionDetailData}
                open={mlPackageVersionDetailsOpen}
                handleClose={false}
                closeIconButton={false}
                secondaryButtonText={t('dialog_button_close_text')}
                secondarybuttonCallback={closeMLPackageVersionDetailsDialog} />

            <MLPackageVersionPublicUpdateDialog
                title={t('ml_package_version_is_public_dialog_title_text')}
                open={changeIsPublicOpen}
                data={mlPackageVersionPublicUpdateData}
                handleClose={closeChangeIsPublicDialog}
                closeIconButton
                infoText={t('ml_package_version_is_public_dialog_info_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={changeIsPublicVersion}
                secondarybuttonCallback={closeChangeIsPublicDialog}
            />

            {
                mlpackageId ? (<ServerSideTable
                    url={`${mlPackagesBaseUrl}/${mlpackageId}${versionListUrl}`}
                    totalKey="data.totalCount"
                    dataKey="data.dataList"
                    mapper={dataMapper}
                    contextMenuItems={menuItems}
                    itemDeletedFlag={itemDeletedFlag}
                    itemChangeIsPublicFlag={itemChangeIsPublicFlag}
                    totalCountListener={totalCountListener}
                    keyColumnIndex={0}
                    level="mlpackages_pagesize"
                />) : null
            }
        </MLPackageVersionsPageContainer>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    isPublicTenant: state.config.isPublic,
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    validatedMlPackageBucket: state.config.paths ? state.config.paths.validatedMlPackageBucket : undefined,
    isInstanceProfileEnabled: state.config.paths ? state.config.paths.isInstanceProfileEnabled : undefined,
    azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING,
}))(MLPackageVersionsPage);
