import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import type { FormikProps } from 'formik';
import { Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeedback } from '../../../api/global/useFeedback';
import URLManager from '../../../config/URLManager';
import { http } from '../../../http';
import { extractErrorMessage } from '../../../utils/CommonUtils';

const CRUD: string[] = [ 'View', 'Edit', 'Create', 'Delete' ];
const canChangeFields: string[] = CRUD.map((perm) => ('can' + perm));

const viewAccess = canChangeFields.reduce((acc, perm) => ({
    ...acc,
    [perm]: perm === 'canView',
}), {});

const allAccess = canChangeFields.reduce((acc, perm) => ({
    ...acc,
    [perm]: true,
}), {});

const PermissionsTable: React.FC<{
    formProps: FormikProps<FormData>;
    permissionLevel: string;
    roleId?: string;
    mode: 'create' | 'view' | 'edit';
}> = ({
    formProps, permissionLevel, roleId, mode,
}) => {
    const feedback = useFeedback();
    const { t } = useTranslation();

    const headings = [ 'Project Permissions', ...CRUD ].map((elem) => t(`permission_table_heading_${elem.replace(' ', '_')}`));

    const [ canCRUD, setCanCRUD ] = useState<{ [service: string]: { [permission: string]: boolean } }>({});
    const editablePermissions = (mode === 'edit' || mode === 'create');

    React.useEffect(() => {
        http.get(`${URLManager.url().apiHelper}/permissions/all/`, { params: { level: permissionLevel } }).then((response: any) => {
            const possibleCRUDOps = response.data.data.reduce((accumulator: any, permission: any) => ({
                ...accumulator,
                [permission.permission]: (
                // Some permissions are read only for eg license
                    permission.permissionAccess === 'READ' ? viewAccess : allAccess
                ),
            }), {});
            setCanCRUD(possibleCRUDOps);

            if (mode === 'create') {
                const defaultPermission = response.data.data.reduce((accumulator: any, permission: any) => ({
                    ...accumulator,
                    [permission.permission]: (
                        canChangeFields.reduce((acc: any, perm: any) => ({
                            ...acc,
                            [perm]: false,
                        }), {})
                    ),
                }), {});
                formProps.setValues({
                    ...formProps.values,
                    ['permissions']: defaultPermission,
                });
            } else { // "view" or "edit"
                http.get(`${URLManager.url().apiHelper}/roles/${roleId}`)
                    .then((roleResponse: any) => {

                        const defaultPermission = roleResponse.data.data.permissions.reduce((accumulator: any, fetchedPermission: any) => ({
                            ...accumulator,
                            [fetchedPermission.permission]: (
                                canChangeFields.reduce((acc: any, perm: any) => ({
                                    ...acc,
                                    [perm]: fetchedPermission[perm],
                                }), {})
                            ),
                        }), {});

                        formProps.setValues({
                            ...formProps.values,
                            ['permissions']: defaultPermission,
                        });
                        return true;
                    })
                    .catch((error: any) => {
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            t('error_fetching_roles'),
                            { 20205: {} },
                        ));
                    });
            }

            return true;
        })
            .catch((error) => {
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('error_fetching_permissions'),
                    { 20205: {} },
                ));
            });

    }, []);

    const selectAll = (event: any) => {
        const name = 'can' + event.target.name;
        const checked = event.target.checked;

        Object.keys(canCRUD).forEach((field) => {
            const formFieldName = `permissions.${field}.${name}`;
            if (name in formProps.values.permissions[field]) {
                formProps.setFieldValue(formFieldName, checked);
            }
        });
    };

    return (
        <TableContainer style={{
            padding: '10px',
            width: '100%',
        }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            headings.map((row) => (
                                <TableCell>
                                    {
                                        (CRUD.indexOf(row) > -1 && editablePermissions)
                                            ?
                                            <Checkbox
                                                name={row}
                                                onChange={selectAll} />
                                            :
                                            null
                                    }
                                    {row}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(canCRUD).map((service) => (
                            <TableRow style={{ width: '100%' }}>
                                <TableCell style={{ width: '25%' }}>
                                    {service}
                                </TableCell>
                                {
                                    canChangeFields.map((permission) => (

                                        <TableCell style={{ width: '18%' }}>
                                            {
                                                canCRUD[service][permission]
                                                    ?
                                                    <Field
                                                        type="checkbox"
                                                        name={'permissions.' + service + '.' + permission}
                                                        disabled={!editablePermissions} />
                                                    :
                                                    null
                                            }
                                        </TableCell>

                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface FormData {
    roleName: string;
    permissions: any;
}

export default PermissionsTable;
