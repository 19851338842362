import {
    Button,
    LinearProgress,
    styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface FormButtonGroupProps {
    dirty: boolean;
    isSubmitting: boolean;
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleReset?: (e?: React.SyntheticEvent<any> | undefined) => void;
    submitButtonText?: string;
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',

    '& .group': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '24px',

        '& .buttons': { flexGrow: 0 },
    },
});

const FormButtonGroup: React.FC<FormButtonGroupProps> = ({
    dirty, isSubmitting, handleReset, submitButtonText, disabled,
}) => {

    const history = useHistory();
    const { t } = useTranslation();
    const submitLabel = submitButtonText ? submitButtonText : t('form_create_button_text');
    return (
        <Root>
            <div>
                {isSubmitting ? <LinearProgress
                    color="secondary"
                    id="progressBar" /> : null}
            </div>
            <div className="group">
                <div className="buttons">
                    <Button
                        style={{ minWidth: '88px' }}
                        id="submitButton"
                        data-cy="submitButton"
                        variant="contained"
                        disableRipple
                        type="submit"
                        aria-label={submitLabel}
                        disabled={isSubmitting || disabled}>
                        {submitLabel}
                    </Button>
                    {handleReset && <Button
                        aria-label={t('form_reset_button_text')}
                        id="resetButton"
                        data-cy="resetButton"
                        variant="contained"
                        disableRipple
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting || disabled}
                    >
                        {t('form_reset_button_text')}
                    </Button>}
                    <Button
                        aria-label={t('form_cancel_button_text')}
                        style={{
                            marginLeft: '8px',
                            minWidth: '88px',
                        }}
                        id="cancelButton"
                        data-cy="cancelButton"
                        disableRipple
                        variant="outlined"
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        onClick={() => history.goBack()}
                        disabled={isSubmitting || disabled}
                    >
                        {t('form_cancel_button_text')}
                    </Button>
                </div>
            </div>
        </Root>
    );
};

export default FormButtonGroup;
