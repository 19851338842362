import type { AuthConfiguration } from '@uipath/auth-react';
import {
    AuthGuard,
    onCallback,
    UiPathAuthProvider,
} from '@uipath/auth-react';
import type { ReactElement } from 'react';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';

import { Configuration } from '../enums/Configuration';
import { getAccountAndTenantFromCannonicalPath } from '../route/routeHelper';
import { ConfigurationActions } from '../state-management/Actions';
import { markIDPCommFailure } from '../state-management/dispatchers/auth';
import type { AuthenticationConfig } from '../state-management/states';
import { store } from '../state-management/store';
import {
    getAuthConfigurationParameters,
    isAppReady,
} from '../utils/authUtils';

interface AuthenticationProviderProps {
    children: ReactElement;
    authConfig: AuthenticationConfig;
    isFirstParty: boolean;
    appState: Configuration;
}

const unsubscribe = store.subscribe(() => {
    const {
        auth, config,
    } = store.getState();
    if (isAppReady(auth, config)) {
        store.dispatch({
            type: ConfigurationActions.APPREADY,
            payload: null,
        });
        unsubscribe();
    }
});

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
    children,
    authConfig,
    isFirstParty,
    appState,
}) => {
    const history = useHistory();
    const location = useLocation();
    const {
        account, tenant,
    } = getAccountAndTenantFromCannonicalPath(!isFirstParty);
    const [ authConfigParams, setAuthConfigParams ] = React.useState<AuthConfiguration>(
        getAuthConfigurationParameters(authConfig, account, tenant),
    );

    useEffect(() => {
        if (
            appState === Configuration.LOADED &&
        authConfig !== undefined &&
        authConfig !== null &&
        Object.keys(authConfig).length !== 0
        ) {
            setAuthConfigParams(getAuthConfigurationParameters(authConfig, account, tenant));
        }
    }, [ appState === Configuration.LOADED, authConfig?.url ]);

    const errorCallback = useCallback((error: any) => {
        markIDPCommFailure(error);
    }, []);

    return authConfigParams?.authority !== '' ? (
        <UiPathAuthProvider
            configuration={authConfigParams}
            onSigninCallback={user => onCallback(user, history.push)}
            onError={errorCallback}
        >
            <AuthGuard
                extraSigninRequestArgs={{
                    organizationName: account ? account : '',
                    state: { returnTo: location.pathname ?? '/' },
                }}
            >
                {children}
            </AuthGuard>
        </UiPathAuthProvider>
    ) : null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    appState: state.config.state,
    authConfig: state.config.paths ? state.config.paths.authConfig : {},
    isFirstParty: !!state.config.paths.isFirstParty,
}))(AuthenticationProvider);
