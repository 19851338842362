import InfoIcon from '@mui/icons-material/Info';
import {
    Hidden,
    styled,
} from '@mui/material';
import React from 'react';

const IconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
        fontSize: '3em !important',
        color: theme.palette.semantic.colorBackgroundInverse,
    },
}));

export default () => (
    <Hidden mdDown>
        <IconContainer>
            <InfoIcon />
        </IconContainer>
    </Hidden>
);

