import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import {
    DeleteAttrPopup,
    TaskEditorPopup,
} from '../../../components/Dialog';
import TaskHeader from './TaskHeader';
import TaskListItems from './TaskListItems';

interface NerTaskProps {
    labels: any[];
    setLabels: (value: any[]) => void;
    inputTypes: string[];
    setIsEdited: () => void;
    isTabEditable: boolean;
}

interface FormData {
    name: string;
    inputType: string;
    attributes: Array<{
        attributeName: string;
        shortcut: string;
        color: string;
    }>;
}

const NerTasks: React.FC<NerTaskProps> = ({
    labels, setLabels, inputTypes, setIsEdited, isTabEditable,
}) => {
    const initialValues: any = {
        name: '',
        inputType: inputTypes[0],
        attributes: [ {
            id: uuid(),
            attributeName: '',
            shortcut: '',
            color: '',
        } ],
    };

    const [ openTaskEditorPopup, setOpenTaskEditorPopup ] = React.useState(false);
    const [ openDeleteConfirmationPopup, setOpenDeleteConfirmationPopup ] = React.useState(false);
    const [ deleteIndex, setDeleteIndex ] = React.useState(-1);
    const [ initialFormValues, setInitialFormValues ] = React.useState({ ...initialValues });
    const [ openIndex, setOpenIndex ] = React.useState(-1);
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('data_labelling_general_tab_name_required')),
        inputType: Yup.string().required(t('data_labelling_general_tab_input_type_required')),
        attributes: Yup.array().of(Yup.object({
            attributeName: Yup.string().required(t('data_labelling_general_tab_attribute_name_required')),
            shortcut: Yup.string(),
            color: Yup.string().required(t('data_labelling_general_tab_color_required')),
        })),
    });

    const handleOpen = (index: number): void => {
        const initialValue = initialFormValues;
        const label = labels[index];
        initialValue.name = label['@_name'];
        initialValue.inputType = label['@_toName'];
        initialValue.attributes = [];
        label['Label']?.forEach((attribute: Record<string, any>) => {
            initialValue.attributes.push({
                id: uuid(),
                attributeName: attribute['@_value'],
                shortcut: attribute['@_hotkey'] ? attribute['@_hotkey'] : '',
                color: attribute['@_background'],
            });
        });
        setInitialFormValues(initialValue);
        setOpenTaskEditorPopup(true);
        setOpenIndex(index);
    };

    const handleNewAttributeAdd = (): void => {
        setInitialFormValues({ ...initialValues });
        setOpenTaskEditorPopup(true);
        setOpenIndex(-1);
    };

    const handleSubmit = (value: FormData): void => {
        const newLabels = labels !== undefined && labels.length !== 0 ? [ ...labels ] : [];
        const newLabel = openIndex > -1 ? newLabels[openIndex] : {};
        newLabel['@_name'] = value.name;
        newLabel['@_toName'] = value.inputType;
        newLabel['@_showInLine'] = openIndex > -1 ? newLabels[openIndex]['@_showInLine'] : true;
        newLabel['Label'] = [];
        value.attributes.forEach((attribute: any) => {
            newLabel['Label'].push({
                '@_value': attribute.attributeName,
                '@_hotkey': attribute.shortcut,
                '@_background': attribute.color,
            });
        });
        if (openIndex > -1) {
            newLabels[openIndex] = newLabel;
        } else {
            newLabels.push(newLabel);
        }
        setLabels([ ...newLabels ]);
        setIsEdited();
        setOpenTaskEditorPopup(false);
    };

    const handleDelete = (index: number): void => {
        const newLabels = [ ...labels ];
        newLabels.splice(index, 1);
        setLabels(newLabels);
        setDeleteIndex(-1);
        setOpenDeleteConfirmationPopup(false);
        setIsEdited();
    };

    return (
        <>
            <DeleteAttrPopup
                title={t('entity_attr_delete_dialog_title_text')}
                infoText={t('entity_attr_delete_dialog_info_text')}
                setOpen={setOpenDeleteConfirmationPopup}
                open={openDeleteConfirmationPopup}
                handleDelete={(): void => handleDelete(deleteIndex)} />
            <TaskHeader
                headerText={t('a11y_entity_label')}
                handleOpen={handleNewAttributeAdd}
                isTabEditable={isTabEditable} />
            {labels?.length !== 0 && labels?.map((label, index) => (
                <TaskListItems
                    label={label}
                    key={uuid()}
                    option='Label'
                    handleOpen={(): void => handleOpen(index)}
                    handleDelete={(): void => {
                        setDeleteIndex(index);
                        setOpenDeleteConfirmationPopup(true);
                    }}
                    isTabEditable={isTabEditable} />
            ))}
            <TaskEditorPopup
                type='entity'
                title={t('a11y_entity_label')}
                open={openTaskEditorPopup}
                setOpen={setOpenTaskEditorPopup}
                initialFormValues={initialFormValues}
                handleSubmit={handleSubmit}
                validationSchema={validationSchema}
                inputTypes={inputTypes} />
        </>
    );
};

export default NerTasks;
