import { styled } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useParams,
} from 'react-router-dom';

import extractionPkgImg from '../../assets/extract-pkg.svg';
import { ActionCard } from '../../components/ActionCard';
import Section from '../../components/Section';
import { WithVisibility } from '../../components/WithVisibility';
import { AppPermissions } from '../../enums/Authorization';
import type FeatureFlagManager from '../../feature-flag/FeatureFlagManager';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import { RoutePath } from '../../route/routeMap';
import { DataLabelingSessionList } from './list/DataLabelingSessionList';

const DataLabellingPageContainer = styled('div')({
    '& .apps-container': {
        display: 'flex',

        '& .app': { marginRight: '2em' },
    },
});

interface Props {
    featureManager: FeatureFlagManager;
    appsLaunchUrlTemplate: string;
}

const DataLabelingSessionsPageFC: React.FC<Props> = ({
    featureManager,
    appsLaunchUrlTemplate,
}) => {
    const { t } = useTranslation();
    const { projectName } = useParams<{ projectName: string }>();

    const { currentProject } = useContext(ProjectsContext).state;

    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProject?.id ?? '']?.permissionSet || [];

    const isLabelStudioEnabled: boolean = featureManager ? featureManager.isEnabled('label-studio-enabled') : false;

    return (
        <DataLabellingPageContainer>
            <WithVisibility visible={permissions.indexOf(AppPermissions.DataLabeling_Create) > -1}>
                <Section title={t('dl_create_new_app_header')}>
                    <div className="apps-container">
                        <div
                            className="app"
                            data-testid="app-datamanager">
                            <ActionCard
                                title={t('dl_document_understanding_suite_title')}
                                cardContent={t('dl_document_understanding_suite_description')}
                                imgUrl={extractionPkgImg}
                                route={generatePath(RoutePath.CREATE_DATALABELING, { projectName })}
                                data={{ projectName }}
                            />
                        </div>
                        <WithVisibility visible={isLabelStudioEnabled}>
                            <div
                                className="app"
                                data-cy="uipath-dl-oob-template">
                                <ActionCard
                                    title={t('labeling-card-title')}
                                    cardContent={t('labeling-card-content')}
                                    imgUrl={extractionPkgImg}
                                    route={generatePath(RoutePath.CHOOSE_LABELING_TEMPLATE, { projectName })}
                                    data={{ projectName }}
                                />
                            </div>
                        </WithVisibility>
                    </div>
                </Section>
            </WithVisibility>
            <Section title={t('dl_session_header')}>
                <DataLabelingSessionList
                    appsLaunchUrlTemplate={appsLaunchUrlTemplate}
                    permissions={permissions} />
            </Section>
        </DataLabellingPageContainer>
    );
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    featureManager: state.featureManagement.featureManager,
    appsLaunchUrlTemplate: state.config.paths.appsLaunchUrlTemplate,
}))(DataLabelingSessionsPageFC);
