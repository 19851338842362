export enum BaseTableIcons {
    INFO,
    TOOLTIP,
    DELETE,
    CANCEL,
    DOWNLOAD,
    SWAPVERT,
    MORE,
    REFRESH,
}
