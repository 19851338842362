import * as React from 'react';

import TelemetryProvider from '../../main/telemetry/TelemetryProvider';
import { ProjectsHost } from '../projectsHost/ProjectsHost';

export const TelemetryHost: React.FC = () => (
    <TelemetryProvider>
        <ProjectsHost />
    </TelemetryProvider>
);
