import moment from 'moment';

const localeDateMap: { [k: string]: string } = {
    en: 'YYYY-MM-DD hh:mm a',
    ja: 'YYYY-MM-DD HH:mm',
};

const dateFormatter = (value: string | number | undefined, locale: string, convertToUTC?: boolean): string => {

    if (typeof value === 'undefined' || !value) {
        return '';
    }

    const dateFormat = localeDateMap[locale] || localeDateMap.en;
    const date = moment(value);
    if (convertToUTC) {
        return date.utc().format(dateFormat);
    }
    return date.utc().local()
        .format(dateFormat);
};

const getUtcDate = (date: Date): Date => new Date(date.getTime() + date.getTimezoneOffset() * 60000);

const addMinutes = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60000);

const removeTZ = (date: Date): Date => new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export {
    dateFormatter,
    getUtcDate,
    removeTZ,
    addMinutes,
};
