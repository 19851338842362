import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { ImportMLPackage } from '../../../components/mlPackage/importPackage';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../../constants/BlobStorageConstants';
import { ProjectsContext } from '../../../providers/ProjectsProvider';

interface MLPackageImportProps {
    azureStorageFQDNSuffix: string;
}

const MLPackageImport: React.FC<MLPackageImportProps> = ({ azureStorageFQDNSuffix }) => {
    const importPackageType = 'ML_PACKAGE_VERSION_IMPORT';

    const { state: projectsState } = useContext(ProjectsContext);
    const currentProject = projectsState.currentProject;

    return (
        <ImportMLPackage
            importPackageType={importPackageType}
            currentProject={currentProject}
            isOobImport={false}
            azureStorageFQDNSuffix={azureStorageFQDNSuffix}
        />
    );
};
export default connect((state: any) => ({ azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING }))(MLPackageImport);
