import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { ReactNode } from 'react';
import React from 'react';

const BodyTextRoot = styled('span')(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForeground,
}));

interface BodyTextProps {
    children: ReactNode;
}

export const BodyText: React.FC<BodyTextProps> = ({ children }) => <BodyTextRoot>
    {children}
</BodyTextRoot>;
