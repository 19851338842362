import type {
    KeyboardEvent,
    MutableRefObject,
} from 'react';
import { Key } from 'ts-keycode-enum';

interface BindKey {
    ref?: MutableRefObject<HTMLDivElement | HTMLButtonElement | any | null> | null;
    cycle?: boolean;
    cycleByLR?: boolean;
    tabNext?: HTMLElement;
    tabPrev?: HTMLElement;
    traps?: {
        [key in Key]?: (event: KeyboardEvent<any>) => void
    };
    ignoreTarget?: boolean;
}

export const bindKeyTo = ({
    ref, traps, cycle, ignoreTarget, cycleByLR,
}: BindKey): any => {
    const modTraps: {
        [key in Key]?: (event: KeyboardEvent<any>) => void
    } = traps || {};
    if (!modTraps[Key.Enter]) {
        modTraps[Key.Enter] = () => {
            ref?.current?.click();
        };
    }

    // todo: provide this support as a good to have
    // if(!modTraps[Key.Space]) {
    //   modTraps[Key.Space] = () => {
    //     ref?.current?.click();
    //   };
    // }

    if (cycle) {
        modTraps[cycleByLR ? Key.LeftArrow : Key.UpArrow] = () => {
            ((ref?.current?.previousSibling ? ref?.current?.previousSibling : ref?.current?.parentElement?.lastChild) as HTMLElement).focus();
        };

        modTraps[cycleByLR ? Key.RightArrow : Key.DownArrow] = () => {
            ((ref?.current?.nextSibling ? ref?.current?.nextSibling : ref?.current?.parentElement?.firstChild) as HTMLElement).focus();
        };
    }

    return (event: KeyboardEvent<HTMLDivElement>) => {
        const code = (event.keyCode || event.which || event.key) as Key;
        if ((event.target === ref?.current) || ignoreTarget) {
            if (modTraps && modTraps[code]) {
                // @ts-ignore
                modTraps[code](event);
                event.stopPropagation();
            }
        }
    };
};
