import React, {
    Fragment,
    useState,
} from 'react';
import { connect } from 'react-redux';

import { setup } from '../../utils/AppInsights';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TelemetryProvider: React.FC<any> = ({
    children, insightsKey, clusterId, isOnPrem, portalNavEnabled,
}) => {
    const [ initialized, setInitialized ] = useState(false);

    React.useEffect(() => {
        if (!initialized && insightsKey) {
            setup(insightsKey, clusterId, isOnPrem, portalNavEnabled);
            setInitialized(true);
        }
    }, [ initialized, insightsKey ]);

    return (
        <>
            {children}
        </>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    insightsKey: state.config.paths ? state.config.paths.insightsKey : '',
    clusterId: state.config.paths ? state.config.paths.clusterId : '',
    isOnPrem: !!state.config.paths?.isOnPrem,
    portalNavEnabled: !!state.config.paths?.portalNavEnabled,
}))(TelemetryProvider);
