import * as React from 'react';
import { Provider } from 'react-redux';

import { store } from '../../state-management/store';
import { ThemeHost } from '../themeHost/ThemeHost';

export const StoreHost: React.FC = () => (
    <Provider store={store}>
        <ThemeHost />
    </Provider>
);
