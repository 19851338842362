import type {
    ListingDtoOfRBACProjectDto,
    ProjectDto,
    RBACProjectWrapper,
} from '@uipath/aifabric';
import React, {
    createContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { getProjectsRBAC } from '../api/client/projectManagerClient';
import type { AppStore } from '../state-management/store';
import logger from '../utils/Logging';

export enum UpdateType {
    Create,
    Edit,
    Refresh,
    Delete,
}

export type ProjectState = {
    projects: ProjectDto[];
    currentProject: ProjectDto | undefined;
    currentProjectName: string | undefined;
    currentProjectId: string | undefined;
    status: 'INITIAL' | 'LOADING' | 'SUCCESS' | 'FAILED';
    projectsRBAC: ListingDtoOfRBACProjectDto;
};

export type ProjectActions = {
    forceRefresh: () => Promise<void>;
    setCurrent: (projectName: string | undefined, project?: ProjectDto) => void;
    clearProject: () => void;
    updateProjectsList: (newProject: ProjectDto, updateType: UpdateType, refreshedlist?: ProjectDto[]) => void;
};

export enum ProjectAction {
    FORCE_REFRESH,
    SET_CURRENT,
    CLEAR_PROJECT,
    UPDATE_PROJECTS_LIST,
    SET_LOADING_STATE,
    SET_PROJECTS_RBAC,
}

export const initialProjectState: ProjectState = {
    projects: [],
    currentProject: undefined,
    currentProjectName: undefined,
    currentProjectId: undefined,
    status: 'INITIAL',
    projectsRBAC: {
        others: [],
        owned: [],
    },
};

export const initialProjectsActions: ProjectActions = {
    forceRefresh: async () => {},
    setCurrent: () => {},
    clearProject: () => {},
    updateProjectsList: () => {},
};

export const ProjectsContext = createContext<{
    state: ProjectState;
    actions: ProjectActions;
}>({
    state: initialProjectState,
    actions: initialProjectsActions,
});

interface ProjectsProviderProps {
    children: React.ReactNode;
}

function projectsReducer(state: ProjectState, action: { type: ProjectAction; payload: any }): ProjectState {
    switch (action.type) {
        case ProjectAction.SET_PROJECTS_RBAC: {
            const projectsMap = action.payload;
            return {
                ...state,
                projectsRBAC: projectsMap,
                projects: [ ...projectsMap.others.map((o: RBACProjectWrapper) => o.projectDto), ...projectsMap.owned.map((o: RBACProjectWrapper) => o.projectDto) ],
            };
        }
        case ProjectAction.SET_LOADING_STATE: {
            return {
                ...state,
                status: 'LOADING',
            };
        }
        case ProjectAction.FORCE_REFRESH: {
            try {
                const projectsMap = state.projectsRBAC;
                const projects = [ ...projectsMap.others.map(o => o.projectDto), ...projectsMap.owned.map(o => o.projectDto) ];
                if (projects && projects.length) {
                    return {
                        ...state,
                        projects,
                        status: 'SUCCESS',
                    };
                }
                return { ...state };

            } catch (error) {
                return {
                    ...state,
                    status: 'FAILED',
                };
            }
        }
        case ProjectAction.CLEAR_PROJECT:
            return {
                ...state,
                currentProject: undefined,
                currentProjectName: undefined,
                currentProjectId: undefined,
                status: 'SUCCESS',
            };
        case ProjectAction.SET_CURRENT: {
            const {
                project, projectName,
            } = action.payload;
            /* Handle when project is edited */
            if (project && state.projects && state.projects.length > 0) {
                return {
                    ...state,
                    currentProject: project,
                    currentProjectId: project.id,
                    currentProjectName: project.name,
                };
            }

            if (projectName.toLowerCase() !== state.currentProject?.name?.toLowerCase()) {
                if (state.projects && state.projects.length > 0) {
                    const currProj = state.projects.find((proj: ProjectDto) => proj.name?.toLowerCase() === projectName.toLowerCase());
                    if (currProj && (state.currentProjectId !== currProj.id || state.currentProjectName !== currProj.name)) {
                        return {
                            ...state,
                            currentProject: currProj,
                            currentProjectId: currProj.id,
                            currentProjectName: currProj.name,
                        };
                    }
                    logger.error({
                        identifier: 'Use projects hook',
                        message: 'Failed to find project name',
                    });

                } else {
                    if (state.status === 'SUCCESS') {
                        return state;
                    }

                    if (state.status === 'FAILED') {
                        logger.error({
                            identifier: 'Use projects hook',
                            message: 'Failed to make the call to get projects',
                        });
                        return state;
                    }

                    if (state.status !== 'LOADING') {

                        try {
                            const projectsMap = state.projectsRBAC;
                            const projects = [ ...projectsMap.others.map(o => o.projectDto), ...projectsMap.owned.map(o => o.projectDto) ];
                            if (projects) {
                                const currProj = projects.find((proj: ProjectDto) => proj.name?.toLowerCase() === projectName.toLowerCase());
                                if (currProj && (state.currentProjectId !== currProj.id || state.currentProjectName !== currProj.name)) {
                                    return {
                                        ...state,
                                        projects,
                                        currentProject: currProj,
                                        currentProjectId: currProj.id,
                                        currentProjectName: currProj.name,
                                        status: 'SUCCESS',
                                    };
                                }
                                logger.error({
                                    identifier: 'Use projects hook',
                                    message: 'Failed to find project name',
                                });

                            } else {
                                return {
                                    ...state,
                                    status: 'FAILED',
                                };
                            }
                        } catch (error) {
                            return {
                                ...state,
                                status: 'FAILED',
                            };
                        }
                    }
                    return state;
                }
            }
            return state;
        }
        case ProjectAction.UPDATE_PROJECTS_LIST: {
            /* Add new project to list */
            const {
                updateType, newProject, refreshedList,
            } = action.payload;
            if (UpdateType.Create === updateType) {
                const projects: ProjectDto[] = [ ...state.projects ];
                projects.push(newProject);
                return {
                    ...state,
                    projects,
                };
            }

            /* Update edited project to list */
            if (UpdateType.Edit === updateType) {
                const projects: ProjectDto[] = [ ...state.projects ];
                const index = projects.findIndex(project => project.id == newProject.id);
                if (index !== -1) {
                    projects[index] = newProject;
                    return {
                        ...state,
                        projects,
                    };
                }
            }

            /* Remove project from list */
            if (UpdateType.Delete === updateType) {
                let projects: ProjectDto[] = [ ...state.projects ];
                projects = projects.filter(project => project.id !== newProject.id);
                return {
                    ...state,
                    projects: (projects && projects.length > 0) ? projects : [],
                };
            }

            /* Refresh project list */
            if (UpdateType.Refresh === updateType) {
                return {
                    ...state,
                    projects: refreshedList ? refreshedList : [],
                };
            }
            return state;
        }
        default:
            return state;
    }
}

export const ProjectsStateProvider: React.FC<ProjectsProviderProps> = ({ children }) => {
    const [ state, dispatch ] = useReducer(projectsReducer, initialProjectState);

    const forceRefresh = async () => {
        dispatch({
            type: ProjectAction.SET_LOADING_STATE,
            payload: null,
        });
        dispatch({
            type: ProjectAction.FORCE_REFRESH,
            payload: null,
        });
    };

    const clearProject = () => {
        dispatch({
            type: ProjectAction.CLEAR_PROJECT,
            payload: null,
        });
    };

    const updateProjectsList = (newProject: ProjectDto, updateType: UpdateType, refreshedList?: ProjectDto[]) => {
        if (!newProject && !refreshedList) {
            return;
        }

        dispatch({
            type: ProjectAction.UPDATE_PROJECTS_LIST,
            payload: {
                newProject,
                updateType,
                refreshedList,
            },
        });
    };

    const setCurrent = (projectName: string | undefined, project?: ProjectDto) => {
        if (projectName === undefined) {
            return;
        }

        dispatch({
            type: ProjectAction.SET_CURRENT,
            payload: {
                projectName,
                project,
            },
        });
    };

    const [ actions ] = useState<ProjectActions>({
        forceRefresh,
        setCurrent,
        clearProject,
        updateProjectsList,
    });

    const { apiPkgManager } = useSelector((state: AppStore) => state.config.paths);
    useEffect(() => {
        if (apiPkgManager) {
            const populateProjectsRBAC = async () => {
                const projects = await getProjectsRBAC();
                dispatch({
                    type: ProjectAction.SET_PROJECTS_RBAC,
                    payload: projects,
                });
            };
            populateProjectsRBAC();
        }
    }, [ apiPkgManager ]);

    return (
        <ProjectsContext.Provider value={{
            state,
            actions,
        }}>
            {children}
        </ProjectsContext.Provider>
    );
};
