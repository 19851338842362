import * as React from 'react';

import ConfigurationProvider from '../../providers/ConfigurationProvider';
import { AuthenticationHost } from '../authenticationHost/AuthenticationHost';

export const ConfigurationHost: React.FC = () => {
    const configPath = process.env.NODE_ENV === 'development' ? '/config.json' : 'AIF_APP_ASSETS_PATH/config.json';
    return (
        <ConfigurationProvider configUrl={configPath}>
            <AuthenticationHost />
        </ConfigurationProvider>
    );
};
