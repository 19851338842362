import RefreshIcon from '@mui/icons-material/Refresh';
import {
    IconButton,
    Select,
    styled,
} from '@mui/material';
import type { MLSkillDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    getSkillReplicas,
    getSkillRunLogs,
} from '../../../api/client/deployerManagerClient';
import { WithVisibility } from '../../../components/WithVisibility';
import logger from '../../../utils/Logging';

const MLSkillRunLogsContainer = styled('div')(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontMSize,
    width: '100%',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: Tokens.FontFamily.FontNormal,
    lineHeight: Tokens.FontFamily.FontMLineHeight,

    '& .topHeader': {
        paddingBottom: '0px',

        '& .dropDown': {
            width: '100%',

            '& .options': { width: '30%' },
        },

        '& .refreshIcon': { float: 'right' },
    },

    '& .skillRunLog': {
        height: 'auto',
        maxHeight: '350px',
        overflowY: 'scroll',
        marginTop: '12px',
        marginBottom: '15px',
        borderRadius: '2px',
        border: `solid 1px ${theme.palette.semantic.colorBorder}`,
        paddingTop: '0px',
    },

    '& .runLogs': {
        color: theme.palette.semantic.colorForegroundDeEmp,
        padding: '12px',
        whiteSpace: 'pre-line',
        lineHeight: Tokens.FontFamily.FontLLineHeight,
    },
}));

interface MLSkillRunLogsProps {
    skillData: MLSkillDto;
}

export const MLSkillRunLogs: React.FC<MLSkillRunLogsProps> = ({ skillData }) => {
    const { t } = useTranslation();
    const [ selectedReplica, setSelectedReplica ] = React.useState<string | undefined>('');
    const [ skillLogs, setSkillLogs ] = React.useState<string | undefined>(t('table_no_data'));
    const [ replicas, setReplicas ] = React.useState<string[] | undefined>([]);

    const handleOnChange = (event: any): void => {
        setSelectedReplica(event.target.value);
    };

    const getSkillReplicaPodNames = () => getSkillReplicas(skillData.id, skillData.projectId)
        .then((res) => {
            setReplicas(res?.replicas);
            if (res?.replicas?.length !== 0) {
                setSelectedReplica(res?.replicas?.[0]);
            }
            return res?.replicas;
        })
        .catch((error) => {
            logger.error({
                identifier: 'Core',
                message: t('ml_streaming_logs_replicas_fetch_error_message'),
                error,
            });
            return [];
        });

    const fetchOrUpdateLogs = () => {
        // Fetch logs only when selectedReplica is not null.
        if (selectedReplica) {
            const replicaId = selectedReplica.split('.')[0];
            getSkillRunLogs(skillData.id, replicaId, skillData.projectId)
                .then((res) => {
                    setSkillLogs(res?.logs);
                    return res?.logs;
                })
                .catch((error) => {
                    logger.error({
                    // confirm identifier once.
                        identifier: 'Core',
                        message: t('ml_streaming_logs_runLogs_fetch_error_message'),
                        error,
                    });
                });
        }
    };

    React.useEffect(() => {
        getSkillReplicaPodNames();
    }, []);

    React.useEffect(() => {
        fetchOrUpdateLogs();
    }, [ selectedReplica ]);

    return (
        <MLSkillRunLogsContainer>
            <WithVisibility visible={replicas != null && replicas?.length > 0}>
                <div className="topHeader">
                    <div className="refreshIcon">
                        <IconButton>
                            <RefreshIcon
                                data-testid="refreshIcon"
                                onClick={fetchOrUpdateLogs}
                                aria-label={t('a11y_refresh_logs')}
                            />
                        </IconButton>
                    </div>
                    <div className="dropDown">
                        <div>
                            <Select
                                native
                                value={selectedReplica}
                                onChange={handleOnChange}
                                data-testid="replicas"
                                className="options"
                                aria-label={t('ml_logs_replica_dropdown')}
                            >
                                {replicas?.map((opt: string) => (<option
                                    value={opt}
                                    key={opt}
                                    data-testid={opt}>
                                    {opt}
                                </option>))}
                            </Select>
                        </div>
                    </div>
                </div>
            </WithVisibility>
            <div className="skillRunLog">
                <div
                    className="runLogs"
                    data-testid="runLogs" >
                    {skillLogs}
                </div>
            </div>
        </MLSkillRunLogsContainer>
    );
};
