import { styled } from '@mui/material';
import type { MLPackageDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React, {
    useContext,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useLocation,
    useParams,
} from 'react-router-dom';

import { getPublicPackages } from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import extractionPkgImg from '../../../assets/extract-pkg.svg';
import { ActionCard } from '../../../components/ActionCard';
import { PageBreadCrumbs } from '../../../components/Breadcrumbs';
import LoadingDots from '../../../components/loadingDots/LoadingDots';
import Section from '../../../components/Section';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';

const UIPathPackagesContainer = styled('div')({
    width: '100%',
    fontSize: Tokens.FontFamily.FontMSize,
    overflowY: 'auto',

    '& .space-after': { marginRight: 24 },
});

const UiPathPackages: React.FC = () => {
    const { t } = useTranslation();
    const feedback = useFeedback();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state: any = useLocation()?.state;
    const data = state.data;

    const {
        projectName, uiPathProjectName,
    } = useParams<{ projectName: string; uiPathProjectName: string }>();
    const [ publicPackages, setPublicPackages ] = useState<MLPackageDto[]>();
    const [ loading, setLoading ] = useState(false);
    const { state: projectState } = useContext(ProjectsContext);
    const currentProject = React.useMemo(() => projectState.currentProject, [ projectState ]);

    React.useEffect(() => {
        setLoading(true);
        getPublicPackages(data.mlPackageOwnedByAccountId, data.mlPackageOwnedByTenantId,
            data.mlPackageOwnedByProjectId, 200, currentProject?.id)
            .then((publicPackagesResponse) => {
                setPublicPackages(publicPackagesResponse);
                setLoading(false);
                return true;
            })
            .catch(error => {
                logger.error({
                    identifier: 'Public Packages',
                    message: 'Error getting public packages',
                    error,
                });
                setLoading(false);
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_error_loading_public_ml_packages'),
                    {
                        10009: {},
                        10403: { 0: 'Server Error' },
                        20003: {},
                        20004: {},
                    },
                ));
            });
    }, []);

    const documentUnderstandingCards = publicPackages?.map((publicPackage, index) =>
        <div
            className="action-card space-after"
            key={index}>
            <ActionCard
                title={publicPackage.displayName ? publicPackage.displayName : ''}
                cardContent={publicPackage.description ?
                    (publicPackage.description.length < 139 ? publicPackage.description : publicPackage.description.substring(0, 139) + '...') : ''}
                route={generatePath(RoutePath.UIPATH_PACKAGE, {
                    projectName,
                    uiPathProjectName,
                    uiPathPackageId: publicPackage.id,
                })}
                data={{
                    mlPackageOwnedByAccountId: data.mlPackageOwnedByAccountId,
                    mlPackageOwnedByTenantId: data.mlPackageOwnedByTenantId,
                    mlPackageOwnedByProjectId: publicPackage?.projectId,
                }}
                imgUrl={extractionPkgImg}
            />
        </div>,
    );

    return (
        <UIPathPackagesContainer>
            <Section title="">
                <PageBreadCrumbs
                    crumbs={[
                        {
                            title: t('ml_packages_title'),
                            link: generatePath(RoutePath.MLPACKAGES, { projectName }),
                        },
                        {
                            title: t('out_of_the_box_packages'),
                            link: generatePath(RoutePath.UIPATH_PROJECTS, { projectName }),
                        },
                    ]}
                    pageTitle={uiPathProjectName ?? ''}
                />
            </Section>
            {loading ? <LoadingDots /> :
                <Section title={uiPathProjectName ? uiPathProjectName : ''}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>
                        {documentUnderstandingCards}
                    </div>
                </Section>}
        </UIPathPackagesContainer>
    );
};

export default UiPathPackages;
