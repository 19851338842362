import { InputLabel } from '@mui/material';
import {
    Field,
    Formik,
} from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import * as Yup from 'yup';

import { useFeedback } from '../../../api/global/useFeedback';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormLayout from '../../../components/FormLayout';
import URLManager from '../../../config/URLManager';
import { http } from '../../../http';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import PermissionTable from './PermissionsTable';

interface FormData {
    roleName: string;
    permissions: any;
}

const CreateRole: React.FC = () => {

    const location: any = useLocation();
    const permissionLevel: string = location.state.data.permissionLevel;

    const history = useHistory();
    const feedback = useFeedback();
    const { t } = useTranslation();
    const initialValues: FormData = {
        roleName: '',
        permissions: {},
    };

    const validationSchema = Yup.object().shape({
        roleName: Yup.string()
            .required(t('rolename_required')),
    });

    const submitForm = (values: FormData) => {
        const permissionsDTO = {
            isStatic: false,
            isDefault: false,
            roleName: values.roleName,
            permissions: [] as any[],
            roleType: permissionLevel.toUpperCase(),
        };
        permissionsDTO.permissions = Object.keys(values.permissions).reduce(
            (accumulator, perm) => [ ...accumulator, {
                ...values.permissions[perm],
                permission: perm,
            } ], [] as any[]);
        http.post(`${URLManager.url().apiHelper}/roles/`, permissionsDTO)
            .then(() => {
                history.go(-1);
                return true;
            })
            .catch((error: any) => {
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('error_creating_role'),
                    { 20205: {} },
                ));
            });
    };

    return (

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitForm}>
            {
                (props) => (
                    <FormLayout
                        onSubmit={props.handleSubmit}
                        submitForm={props.submitForm}
                        footer={
                            <FormButtonGroup
                                dirty={props.dirty}
                                isSubmitting={props.isSubmitting}
                            />
                        }
                    >
                        <InputLabel htmlFor="component-filled">
                            {t('role_name')}
                        </InputLabel>
                        <Field
                            component={TextField}
                            name="roleName"
                            data-testid="role-name"
                            placeholder={t('enter_rolename')}
                            variant="outlined"
                            id="component-filled"
                        />

                        <PermissionTable
                            formProps={props}
                            permissionLevel={permissionLevel}
                            mode="create" />
                    </FormLayout>
                )
            }
        </Formik>
    );
};

export default CreateRole;
