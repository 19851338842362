type LanguageCulture = 'en' | 'ja' | 'fr' | 'de' | 'es' | 'pt-BR' | 'pt-PT' | 'es-MX' | 'ko' | 'zh-CN' | 'pt' | 'tr' | 'tr-TR' | 'ru' | 'ru-RU' | 'zh-TW' | 'keys';

export const getBrowserLanguage = () => isValidLanguageCode(navigator.language) || isValidLanguageCode(navigator.language.substring(0, 2)) || 'en';

export function isValidLanguageCode(languageCode: string | null) {
    return getAvailableLanguageCodeList().find(language => language.toLowerCase() === languageCode?.toLowerCase());
}

export function getUserLanguageFromLocalStorage() {
    const defaultLanguage = getBrowserLanguage();

    let languageFromLocalStorage = isValidLanguageCode(localStorage.getItem('language'));
    if (!languageFromLocalStorage) {
        localStorage.setItem('language', defaultLanguage);
        languageFromLocalStorage = defaultLanguage;
    }

    return languageFromLocalStorage;
}

export function getAvailableLanguageCodeList() {
    return getSupportedLanguages().map(language => language.id);
}

/*
 * All supported languages in Portal
 */
function getSupportedLanguages() {
    const supportedLanguages: Array<{ id: LanguageCulture; text: string; disabledSelection?: boolean }> = [
        {
            id: 'de',
            text: 'Deutsche',
        },
        {
            id: 'en',
            text: 'English',
        },
        {
            id: 'es',
            text: 'Español',
        },
        {
            id: 'es-MX',
            text: 'Español (Mexico)',
        },
        {
            id: 'fr',
            text: 'Français',
        },
        {
            id: 'ja',
            text: '日本語',
        },
        {
            id: 'ko',
            text: 'Korean',
        },
        {
            id: 'pt',
            text: 'Português',
        },
        {
            id: 'pt-PT',
            text: 'Português',
            disabledSelection: true,
        },
        {
            id: 'pt-BR',
            text: 'Português (Brasil)',
        },
        {
            id: 'tr',
            text: 'Turkish',
        },
        {
            id: 'tr-TR',
            text: 'Turkish',
            disabledSelection: true,
        },
        {
            id: 'ru',
            text: 'Russian',
        },
        {
            id: 'ru-RU',
            text: 'Russian',
            disabledSelection: true,
        },
        {
            id: 'zh-CN',
            text: 'Chinese (PRC)',
        },
        {
            id: 'zh-TW',
            text: 'Chinese (Traditional)',
        },
        {
            id: 'keys',
            text: 'Localization Keys',
        },
    ];

    return supportedLanguages;
}
