import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { ReactNode } from 'react';
import React from 'react';

const SecondaryTextRoot = styled('span')(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontSSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForegroundDeEmp,
}));

interface SecondaryTextProps {
    children: ReactNode;
}

export const SecondaryText: React.FC<SecondaryTextProps> = ({ children }) => <SecondaryTextRoot>
    {children}
</SecondaryTextRoot>;
