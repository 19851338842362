import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ErrorDialog from '../../components/ErrorDialog';
import { WithVisibility } from '../../components/WithVisibility';
import type { AppStore } from '../../state-management/store';
import ProjectHost, {
    aiCenterCallback,
    homePageCallback,
} from './ProjectHost';
import { TenantManagement } from './TenantManagement';

const AppContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',

    '&.isPortalNavDisabled': { left: 0 },
});

interface AppContentProps {
    isHostAdmin?: boolean;
    portalNavEnabled: boolean;
    failureCode?: null | number | string;
    backendCode: undefined | null | string;
    loggedIn: boolean;
    isErrored: boolean;
}

const AppContent: React.FC<AppContentProps> = ({
    failureCode, backendCode, isHostAdmin, portalNavEnabled, loggedIn, isErrored,
}) => {
    const { t } = useTranslation();

    return (
        <AppContentContainer
            className={!portalNavEnabled ? 'isPortalNavDisabled' : ''}
            data-testid="app-content">
            {/* Show page content if all good */}
            <WithVisibility visible={loggedIn && !isErrored}>
                <ShowPageContent isHostAdmin={isHostAdmin} />
            </WithVisibility>
            {/* Show error page if errored */}
            <WithVisibility visible={isErrored}>
                <ErrorDialog
                    title={t(`tenant_error_title_${failureCode}`, t('tenant_error_title'))}
                    subtitle={t(`tenant_error_subtitle_${failureCode}`, t('tenant_error_subtitle'))}
                    buttonCallback={homePageCallback}
                    backendCode={backendCode}
                    buttonText={t('tenant_error_home_button')}
                    secondaryButtonCallback={aiCenterCallback}
                    secondaryButtonText={t('generic_error_AIF_home_button')}
                />
            </WithVisibility>
        </AppContentContainer>);
};

interface PageContentProps {
    isHostAdmin?: boolean;
}

const ShowPageContent: React.FC<PageContentProps> = ({ isHostAdmin }) => isHostAdmin ? <TenantManagement /> :
    <ProjectHost />;

export default connect((state: AppStore) => ({
    isHostAdmin: !!state.auth.isHostAdmin,
    portalNavEnabled: !!state.config.paths?.portalNavEnabled,
    failureCode: state.authZ.failureCode,
    backendCode: state.authZ.backendCode,
}))(AppContent);
