import { styled } from '@mui/material';
import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useParams,
} from 'react-router-dom';

import { getLabelingTemplates } from '../../api/client/dataLabelling';
import extractionPkgImg from '../../assets/extract-pkg.svg';
import { ActionCard } from '../../components/ActionCard';
import Section from '../../components/Section';
import { WithVisibility } from '../../components/WithVisibility';
import { AppPermissions } from '../../enums/Authorization';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import { RoutePath } from '../../route/routeMap';

const OObLabellingTemplatesContainer = styled('div')({
    '& h4': { marginLeft: '24px' },

    '& .apps-container': {
        display: 'flex',

        '& .app': { marginRight: '2em' },
    },
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const OOBLabelingTemplates: React.FC = () => {
    const { projectName } = useParams<{ projectName: string }>();
    const [ data, setData ] = useState<any>([]);
    const { t } = useTranslation();
    const { currentProject } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProject?.id ?? '']?.permissionSet || [];

    useEffect(() => {
        if (currentProject?.id) {
            getLabelingTemplates(currentProject.id).then((response: any[]) => {
                setData(response);
                return true;
            })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [ currentProject?.id ]);

    return (
        <OObLabellingTemplatesContainer>
            <WithVisibility visible={permissions.indexOf(AppPermissions.DataLabeling_Create) > -1}>
                <Section title={t('configure-data-labeling')}>
                    {
                        data?.map((template: any) => (
                            <div className="apps-container">
                                <div
                                    className="app"
                                    data-testid="app-datamanager"
                                    data-cy={template.name}>
                                    <ActionCard
                                        title={template.name}
                                        cardContent={template.description}
                                        imgUrl={extractionPkgImg}
                                        route={generatePath(RoutePath.CREATE_DATALABELING, { projectName })}
                                        data={{
                                            projectName,
                                            appType: 'LABEL_BOX',
                                            oobTemplateId: template.id,
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </Section>
            </WithVisibility>
        </OObLabellingTemplatesContainer>
    );
};
