/* eslint-disable */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";
import translationESMX from "./locales/es-MX/translation.json";
import translationES from "./locales/es/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationKO from "./locales/ko/translation.json";
import translationPTBR from "./locales/pt-BR/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationRURU from "./locales/ru-RU/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationZHCN from "./locales/zh-CN/translation.json";
import translationZHTW from "./locales/zh-TW/translation.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de: translationDE,
  en: translationEN,
  es: translationES,
  "es-MX": translationESMX,
  fr: translationFR,
  ja: translationJA,
  ko: translationKO,
  "kr-KO": translationKO,
  pt: translationPT,
  tr: translationTR,
  'tr-TR': translationTR,
  "pt-PT": translationPT,
  "pt-BR": translationPTBR,
  "ru": translationRURU,
  "ru-RU": translationRURU,
  "zh-CN": translationZHCN,
  "zh-TW": translationZHTW,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("PORTAL_LOC_CONTEXT") || localStorage.getItem("AIF_LOCALE") || "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: "{{",
      suffix: "}}"
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"]
    },
    missingInterpolationHandler: () => '',
    fallbackLng: "en"
  });

export default i18n;
