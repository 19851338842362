import { InputLabel } from '@mui/material';
import {
    Field,
    Formik,
} from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import FormLayout from '../../../components/FormLayout';
import PermissionTable from './PermissionsTable';

interface FormData {
    roleName: string;
    permissions: any;
}

const ViewRole: React.FC<{ roleId?: string }> = () => {

    const state: any = useLocation().state;
    const data = state.data;
    const permissionLevel = data.permissionLevel;
    const roleId = data.roleId;
    const roleName = data.roleName;
    const { t } = useTranslation();

    const initialValues: FormData = {
        roleName,
        permissions: {},
    };

    const validationSchema = Yup.object().shape({
        roleName: Yup.string()
            .required(t('rolename_required')),
    });

    return (

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(): void => undefined}>
            {
                (props) => (
                    <FormLayout
                        data-testid="no-submit-form"
                        onSubmit={props.handleSubmit}
                        submitForm={props.submitForm}
                        footer={null}
                    >
                        <InputLabel htmlFor="component-filled">
                            {t('role_name')}
                        </InputLabel>
                        <Field
                            component={TextField}
                            name="roleName"
                            placeholder={t('enter_rolename')}
                            variant="outlined"
                            id="component-filled"
                            data-testid="role-name"
                            disabled
                        />

                        <PermissionTable
                            formProps={props}
                            permissionLevel={permissionLevel}
                            roleId={roleId}
                            mode="view"
                        />
                    </FormLayout>
                )
            }
        </Formik>
    );
};

export default ViewRole;
