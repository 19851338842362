import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { ReactElement } from 'react';
import React, { useState } from 'react';

import { ConfigurationActions } from '../state-management/Actions';
import type { URLs } from '../state-management/states';
import { store } from '../state-management/store';
import logger from '../utils/Logging';

interface ConfigurationProviderProps {
    children: ReactElement;
    configUrl: string;
}

const ConfigurationProvider: React.FC<ConfigurationProviderProps> = ({
    children, configUrl,
}) => {
    const [ isReady, setIsReady ] = useState(false);

    React.useEffect(() => {
        axios.get<URLs>(configUrl).then((response: AxiosResponse<URLs>) => store.dispatch({
            type: ConfigurationActions.LOADED,
            payload: response.data,
        }))
            .catch((error: Error) => {
                logger.error({
                    identifier: 'Core',
                    message: 'Error while fetching config',
                    error,
                });
            });
        setIsReady(true);
    }, [ configUrl ]);

    return isReady ? (
        <>
            {children}
        </>
    ) : null;
};

export default ConfigurationProvider;
