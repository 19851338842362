type themes = 'dark' | 'light' | 'autoTheme';

export const getBrowserTheme = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

function isValidTheme(theme: string | null) {
    return getAvailableThemesList().find(t => t.toLowerCase() === theme?.toLowerCase());
}

export function getBrowserThemeFromLocalStorage() {
    const defaultTheme = getBrowserTheme();

    let themeFromLocalStorage = isValidTheme(localStorage.getItem('aic_theme'));
    if (!themeFromLocalStorage) {
        localStorage.setItem('aic_theme', defaultTheme);
        themeFromLocalStorage = defaultTheme;
    }

    return themeFromLocalStorage;
}

function getAvailableThemesList() {
    return getSupportedThemes().map(language => language.id);
}

function getSupportedThemes() {
    const supportedThemes: Array<{ id: themes; text: string }> = [
        {
            id: 'light',
            text: 'Light',
        },
        {
            id: 'dark',
            text: 'Dark',
        },
        {
            id: 'autoTheme',
            text: 'Auto',
        },
    ];

    return supportedThemes;
}
