import * as React from 'react';

import { ProjectsStateProvider } from '../../providers/ProjectsProvider';
import { PermissionsHost } from '../permissionsHost/PermissonsHost';

export const ProjectsHost: React.FC = () => (
    <ProjectsStateProvider>
        <PermissionsHost />
    </ProjectsStateProvider>
);
