import type {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import axios from 'axios';

import { Configuration } from '../enums/Configuration';
import { store } from '../state-management/store';
import {
    addInterceptors,
    urlFiller,
} from './errorUtils';
import type { CustomAxiosInstance } from './types';

let token = '';
let axiosInstance: AxiosInstance;

const headers = { Authorization: '' };

const waitForInit = new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
        const {
            auth, config,
        } = store.getState();
        if (config.state === Configuration.APPREADY && !!auth.authToken) {
            updateInstanceWithParameters(auth.authToken);
            resolve(true);
            unsubscribe();
        }
    });
});

store.subscribe(() => {
    const {
        auth, config,
    } = store.getState();
    if (config.state === Configuration.APPREADY && auth.authToken !== token) {
        updateInstanceWithParameters(auth.authToken);
    }
});

const http: CustomAxiosInstance = {
    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return waitForInit.then(() =>
            axiosInstance.get(urlFiller(url), config),
        );
    },
    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return waitForInit.then(() =>
            axiosInstance.delete(urlFiller(url), config),
        );
    },
    head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return waitForInit.then(() =>
            axiosInstance.head(urlFiller(url), config),
        );
    },
    options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return waitForInit.then(() =>
            axiosInstance.options(urlFiller(url), config),
        );
    },
    post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return waitForInit.then(() =>
            axiosInstance.post(urlFiller(url), data, config),
        );
    },
    put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return waitForInit.then(() =>
            axiosInstance.put(urlFiller(url), data, config),
        );
    },
    patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return waitForInit.then(() =>
            axiosInstance.patch(urlFiller(url), data, config),
        );
    },
    CancelToken: axios.CancelToken,
};

/* eslint-enable */

const updateInstanceWithParameters = (authToken: string): void => {
    token = authToken;

    headers.Authorization = `Bearer ${authToken}`;

    axiosInstance = axios.create({
    /* Increase the timeout to 20 seconds, Pipeline kill is taking more than 10 seconds */
        timeout: 0,
        headers: { Authorization: `Bearer ${authToken}` },
    });

    addInterceptors(axiosInstance);

};

export {
    http,
    headers,
};
