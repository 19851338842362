import type FeatureFlagManager from './FeatureFlagManager';
import LaunchDarklyFeatureFlagManager from './LaunchDarklyFeatureFlagManager';
import OnPremFeatureFlagManager from './OnPremFeatureFlagManager';

export interface FeatureFlagParams {
    isOnPrem: boolean;
    clientKey?: string;
    userKey?: string;
    userEmail?: string;
    flags?: string;
}

export default class FeatureFlagFactory {
    static getInstance({
        isOnPrem, clientKey, userKey, userEmail, flags,
    }: FeatureFlagParams): FeatureFlagManager | null {
        if (isOnPrem) {
            return OnPremFeatureFlagManager.initialize(flags);
        }
        return LaunchDarklyFeatureFlagManager.initialize(clientKey, userKey, userEmail);

    }
}
