import React from 'react';
import {
    Trans,
    useTranslation,
} from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { CustomDialog } from '../../components/Dialog';
import { WithVisibility } from '../../components/WithVisibility';
import { LinkText } from '../../utils/CommonUtils';

interface UpdateAccessControlDialogProps {
    showUpdateToNewAccessControl: boolean;
    isUpdateToNewAccessControlDialogOpen: boolean;
    closeUpdateToNewAccessControlDialog: () => void;
    updateTenantToNewAccessControl: () => void;
}

export const UpdateAccessControlDialog: React.FC<UpdateAccessControlDialogProps> = ({
    showUpdateToNewAccessControl, isUpdateToNewAccessControlDialogOpen, closeUpdateToNewAccessControlDialog, updateTenantToNewAccessControl,
}) => {
    const { t } = useTranslation();

    return (
        <WithVisibility visible={showUpdateToNewAccessControl}>
            <CustomDialog
                title={t('update_to_new_access_control_dialog_title')}
                open={isUpdateToNewAccessControlDialogOpen}
                handleClose={closeUpdateToNewAccessControlDialog}
                closeIconButton={false}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={updateTenantToNewAccessControl}
                secondarybuttonCallback={closeUpdateToNewAccessControlDialog}
                customMessageComponent={<Trans
                    i18nKey="update_to_new_access_control_warning_text"
                    components={[ <LinkText
                        to="https://forum.uipath.com/t/updating-to-the-new-ai-center-access-control/458016"
                        title="URL"
                        key={uuid()} /> ]}
                />}
            />
        </WithVisibility>
    );
};
