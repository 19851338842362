import { Configuration } from '../../enums/Configuration';
import { getBrowserThemeFromLocalStorage } from '../../utils/themeUtils';
import type { ActionType } from '../Actions';
import { ConfigurationActions } from '../Actions';
import type { ConfigurationState } from '../states.d';

export const ConfigurationReducer = (
    state: ConfigurationState = {
        paths: {
            server: '',
            apiPkgManager: '',
            apiDeployer: '',
            apiHelper: '',
            apiTrainer: '',
            apiAppManager: '',
            appsLaunchUrlTemplate: '',
            portalNavEnabled: false,
            isFirstParty: false,
            isOnStandalone: false,
            actionCenterUrl: '',
            azureStorageFQDNSuffix: '',
        },
        state: Configuration.EMPTY,
        theme: getBrowserThemeFromLocalStorage(),
    },
    action: ActionType,
): ConfigurationState => {
    switch (action.type) {
        case ConfigurationActions.MARK_PUBLIC:
            return {
                ...state,
                isPublic: true,
            };
        case ConfigurationActions.UPDATE_THEME:
            return {
                ...state,
                theme: action.payload.theme,
            };
        case ConfigurationActions.UPDATE_TENANT_TYPE:
            return {
                ...state,
                inactivePeriodThresholds: action.payload.inactivePeriodThresholds,
                defaultThreshold: action.payload.defaultThreshold,
                tenantType: action.payload.tenantType,
            };
        case ConfigurationActions.INITIATED:
            return {
                ...state,
                state: Configuration.INITIATED,
            };
        case ConfigurationActions.APPREADY:
            return {
                ...state,
                state: Configuration.APPREADY,
            };
        case ConfigurationActions.LOADED:
            return {
                ...state,
                paths: action.payload,
                state: Configuration.LOADED,
            };
        case ConfigurationActions.NETWORK_ISSUE:
            return {
                ...state,
                state: Configuration.NETWORK_ISSUE,
            };
        case ConfigurationActions.NETWORK_ISSUE_UNAUTHORIZED:
            return {
                ...state,
                state: Configuration.NETWORK_ISSUE_UNAUTHORIZED,
            };
        case ConfigurationActions.UPDATE_TENANT_MIGRATION_STATUS:
            return {
                ...state,
                isTenantMigrated: action.payload.isTenantMigrated,
            };
        default:
            return state;
    }
};
