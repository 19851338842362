import type { AuthConfiguration } from '@uipath/auth-react';

import { Authentication } from '../enums/Authentication';
import { Configuration } from '../enums/Configuration';
import type {
    AuthenticationConfig,
    AuthenticationState,
    ConfigurationState,
} from '../state-management/states';

export const getAuthConfigurationParameters = (
    authConfig: AuthenticationConfig | undefined,
    account: string,
    tenant: string,
) => {
    let configuration: AuthConfiguration = {
        authority: '',
        redirect_uri: '',
        post_logout_redirect_uri: '',
        overrides: {
            client_id: '',
            scope: '',
        },
    };
    if (authConfig) {
        const redirectUrl =
      account && tenant
          ? authConfig?.config?.redirectUrl?.replace(':account', account)?.replace(':tenant', tenant)
          : authConfig?.config?.redirectUrl;
        configuration = {
            ...configuration,
            authority: authConfig?.url,
            redirect_uri: redirectUrl,
            post_logout_redirect_uri: authConfig?.config?.logoutUrl,
            overrides: {
                client_id: authConfig?.config?.clientId,
                scope: authConfig?.config?.scope,
            },
        };
    }
    return configuration;
};

export const isAppReady = (auth: AuthenticationState, config: ConfigurationState): boolean => !!(
    auth.userState === Authentication.LOGGEDIN &&
    config.state === Configuration.LOADED &&
    auth.authToken && auth.authToken !== 'null'
);
