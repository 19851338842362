import type { ActionType } from '../Actions';
import { FeatureFlagActions } from '../Actions';
import type { FeatureflagState } from '../states';

export const FeatureFlagReducer = (
    state: FeatureflagState = {},
    action: ActionType,
): FeatureflagState => {
    const payload = action.payload || {};
    if (action.type === FeatureFlagActions.INITIALIZED) {
        return {
            ...state,
            featureManager: payload,
        };
    }
    if (action.type === FeatureFlagActions.NOT_AVAILABLE) {
        return {
            ...state,
            featureManager: undefined,
        };
    }
    return state;
};
