import React from 'react';
import { connect } from 'react-redux';

import { ImportMLPackage } from '../../../components/mlPackage/importPackage';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../../constants/BlobStorageConstants';

interface MLPackageImportOOBProps {
    azureStorageFQDNSuffix: string;
}

const MLPackageImportOOB: React.FC<MLPackageImportOOBProps> = ({ azureStorageFQDNSuffix }) => {
    const importPackageType = 'ML_PACKAGE_OOB_UPLOAD';
    return (
        <ImportMLPackage
            importPackageType={importPackageType}
            currentProject={undefined}
            azureStorageFQDNSuffix={azureStorageFQDNSuffix}
            isOobImport
        />
    );
};
export default connect((state: any) => ({ azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING }))(MLPackageImportOOB);
