export enum AppPermissions {
    'MLSkills' = 'MLSkills',
    'MLPackages' = 'MLPackages',
    'MLLogs' = 'MLLogs',

    'MLSkills_View' = 'MLSkills.View',
    'MLSkills_Edit' = 'MLSkills.Edit',
    'MLSkills_Create' = 'MLSkills.Create',
    'MLSkills_Delete' = 'MLSkills.Delete',

    'MLPackages_View' = 'MLPackages.View',
    'MLPackages_Edit' = 'MLPackages.Edit',
    'MLPackages_Create' = 'MLPackages.Create',
    'MLPackages_Delete' = 'MLPackages.Delete',

    'MLLogs_View' = 'MLLogs.View',
    'MLLogs_Edit' = 'MLLogs.Edit',
    'MLLogs_Create' = 'MLLogs.Create',
    'MLLogs_Delete' = 'MLLogs.Delete',

    'MLStorage_Create' = 'MLStorage.Create',
    'MLStorage_View' = 'MLStorage.View',
    'MLStorage_Edit' = 'MLStorage.Edit',
    'MLStorage_Delete' = 'MLStorage.Delete',

    'MLPipeline_Create' = 'MLPipeline.Create',
    'MLPipeline_View' = 'MLPipeline.View',
    'MLPipeline_Edit' = 'MLPipeline.Edit',
    'MLPipeline_Delete' = 'MLPipeline.Delete',

    'DataLabeling_Create' = 'DataLabeling.Create',
    'DataLabeling_View' = 'DataLabeling.View',
    'DataLabeling_Edit' = 'DataLabeling.Edit',
    'DataLabeling_Delete' = 'DataLabeling.Delete',

    'Settings_Create' = 'Settings.Create',
    'Settings_View' = 'Settings.View',
    'Settings_Edit' = 'Settings.Edit',
    'Settings_Delete' = 'Settings.Delete',

    'Profile_Create' = 'Profile.Create',
    'Profile_View' = 'Profile.View',
    'Profile_Edit' = 'Profile.Edit',
    'Profile_Delete' = 'Profile.Delete',

    'Licenses_Create' = 'Licenses.Create',
    'Licenses_View' = 'Licenses.View',
    'Licenses_Edit' = 'Licenses.Edit',
    'Licenses_Delete' = 'Licenses.Delete',

    'Roles_Create' = 'Roles.Create',
    'Roles_View' = 'Roles.View',
    'Roles_Edit' = 'Roles.Edit',
    'Roles_Delete' = 'Roles.Delete',

    'Users_Create' = 'Users.Create',
    'Users_View' = 'Users.View',
    'Users_Edit' = 'Users.Edit',
    'Users_Delete' = 'Users.Delete',

    'MLProjects_Create' = 'MLProjects.Create',
    'MLProjects_View' = 'MLProjects.View',
    'MLProjects_Edit' = 'MLProjects.Edit',
    'MLProjects_Delete' = 'MLProjects.Delete',

    'OOBUpload_Create' = 'OOBUpload.Create',
    'OOBUpload_View' = 'OOBUpload.View',
    'OOBUpload_Edit' = 'OOBUpload.Edit',
    'OOBUpload_Delete' = 'OOBUpload.Delete',

    'EMPTY' = 'EMPTY',
}
