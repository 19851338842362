import React from 'react';
import { connect } from 'react-redux';

import type { AuthenticationConfig } from '../../../state-management/states';
import RoleAssignmentComponent from './Base';

const TenantLevelAssignment: React.FC<{
    token: string;
    authConfig: AuthenticationConfig;
}> = ({
    token,
    authConfig,
}) => (
    <RoleAssignmentComponent
        identityUrl={authConfig.identityAPIUrl}
        level="TENANT"
        token={token} />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    token: state.auth.authToken,
    authConfig: state.config.paths ? state.config.paths.authConfig : {},
}))(TenantLevelAssignment);
