import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useRef,
} from 'react';

import { bindKeyTo } from '../utils/a11y';

const Root = styled('div')(({ theme }) => ({
    fontWeight: 'normal',
    width: '340px',
    border: `solid 1px ${theme.palette.semantic.colorIconDefault}`,
    lineHeight: '40px',
    padding: '0 8px',
    boxSizing: 'border-box',
    borderRadius: '6px',
    color: theme.palette.semantic.colorForegroundDeEmp,
    fontSize: theme.spacing(1.75),

    '&.hasError': { border: `solid 1px ${theme.palette.semantic.colorErrorText}` },

    '&:focus': {
        borderColor: theme.palette.semantic.colorForegroundLink,
        borderWidth: 2,
    },
}));

const ErrorContent = styled('p')(({ theme }) => ({
    color: theme.palette.semantic.colorErrorText,
    fontSize: Tokens.FontFamily.FontSSize,
    fontWeight: Tokens.FontFamily.FontWeightDefault,
    margin: '8px 0',
}));

const InputMimick: React.FC<{
    label: string;
    onClick: () => void;
    requiredField?: boolean;
    error?: string;
    labelledBy?: string;
    ariaLabel?: string;
}> = ({
    label, onClick, requiredField = false, error, labelledBy, ariaLabel,
}) => {
    const cellRef = useRef<HTMLDivElement | null>(null);
    const cb = useCallback(bindKeyTo({
        ref: cellRef,
        cycle: true,
    }), [ cellRef ]);
    return (
        <>
            <Root
                ref={cellRef}
                onClick={onClick}
                aria-labelledby={labelledBy ? labelledBy : undefined}
                tabIndex={0}
                onKeyDown={cb}
                data-testid="open-dataset-chooser-dialog"
                className={`${error ? 'hasError' : ''}`}
                aria-label={ariaLabel ? ariaLabel : label}
                aria-required={requiredField}
                role='textbox'
            >
                {label}
            </Root>
            {
                error &&
                <ErrorContent>
                    {error}
                </ErrorContent>
            }
        </>
    );
};

export default InputMimick;
