import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    styled,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import type { Row } from '@tanstack/react-table';
import type { FieldProps } from 'formik';
import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Key } from 'ts-keycode-enum';

import InputMimick from '../components/InputMimick';
import ServerSideTable from '../components/Table';
import {
    StyledTable,
    StyledTableRow,
} from '../components/Table/BaseTable/BaseTableStyledComponents';
import type { ColumnDefinition } from '../components/Table/BaseTable/types';
import { WithVisibility } from '../components/WithVisibility';
import URLManager from '../config/URLManager';
import TableHeaderMappings from '../constants/TableHeaderMappings';
import { http } from '../http';
import { bindKeyTo } from '../utils/a11y';
import { getFileOrFolderNameForPathFromString } from '../utils/CommonUtils';
import logger from '../utils/Logging';

export interface DataSetInput {
    datasetId: string;
    label: string;
    dataDirectory: string;
}

interface TableSelectPayload {
    row: Row<{
        original: {
            id: string;
        };
    }>;
    folderName?: string;
}

const FormikCompliantDataSetChooser: React.FC<FieldProps> = ({
    form, field,
    ...rest
}) => {
    const {
        projectId, requiredField, labelKey, ariaLabel, labelledBy,
    } = rest as any;
    const [ isOpen, setOpen ] = useState(false);
    const [ level, setLevel ] = useState(0);
    const { t } = useTranslation();

    const [ folderId, setFolderId ] = useState('');
    const [ datasetContext, setDatasetContext ] = useState({
        name: '',
        id: '',
    });

    const navigateToBlobFolder = useCallback((rowInfo: Row<any>) => {
        setFolderId(rowInfo.original.id);
        setLevel(1);
        setDatasetContext(rowInfo.original);
    }, [ setFolderId, setLevel ]);

    const openDatasetChooser = useCallback(() => {
        setLevel(0);
        setDatasetContext({
            name: '',
            id: '',
        });
    }, [ setLevel ]);

    const reset = useCallback(() => {
        setLevel(0);
        setDatasetContext({
            name: '',
            id: '',
        });
    }, []);

    const openDialog = useCallback(() => {
        setOpen(true);
    }, [ setOpen ]);

    const closeDialog = useCallback(() => {
        setOpen(false);
    }, [ setOpen ]);

    const selectDatasetFolder = useCallback((data: any) => {
        form.setFieldValue(field.name, {
            datasetId: data.row.original.id,
            dataDirectory: '',
            label: data.row.original.name,
        });
        closeDialog();
    }, [ form, closeDialog, form.setFieldValue ]);

    const setBlobStorageFolder = useCallback((data: any) => {
        let folderNameWithoutDatasetId = getFolderName(data.blobName).replace(projectId, '')
            .replace(datasetContext.id, '');
        folderNameWithoutDatasetId = removeDoubleSlashes(sanitizeFolderName(folderNameWithoutDatasetId));
        form.setFieldValue(field.name, {
            datasetId: datasetContext.id,
            dataDirectory: folderNameWithoutDatasetId,
            label: datasetContext.name + folderNameWithoutDatasetId,
        });
        closeDialog();
    }, [ form, closeDialog, form.setFieldValue, datasetContext, datasetContext.id, datasetContext.name ]);

    return (
        <>
            <InputMimick
                error={form.errors[field.name]?.toString()}
                requiredField={requiredField}
                label={field.value?.label || t('pipeline_create_' + labelKey + '_label')}
                labelledBy={labelledBy}
                onClick={openDialog}
                ariaLabel={ariaLabel}
            />

            <Dialog
                maxWidth="lg"
                open={isOpen}
                TransitionProps={{ onExited: reset }}>

                {/* Level 1 dataset chooser lists only DB datasets */}
                <WithVisibility visible={level === 0}>
                    <LevelOneDatasetChooser
                        projectId={projectId}
                        onCellClick={navigateToBlobFolder}
                        onSelect={selectDatasetFolder} />
                </WithVisibility>

                {/* Level 2 dataset chooser provides navigation inside blob storage */}
                <WithVisibility visible={level === 1}>
                    <LevelTwoDatasetChooser
                        openDatasetChooser={openDatasetChooser}
                        projectId={projectId}
                        folderId={folderId}
                        onSelect={setBlobStorageFolder} />
                </WithVisibility>
                <DialogActions>
                    <Button
                        data-testid="close-dataset-chooser"
                        onClick={closeDialog}>
                        {t('form_cancel_button_text')}
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

interface LevelOneDatasetChooserProps extends DatasetChooserProps {
    onCellClick: (props: any) => void;
}

interface LevelTwoDatasetChooserProps extends DatasetChooserProps {
    folderId: string;
    openDatasetChooser: () => void;
}

interface LevelTwoDatasetTableRowProps {
    row: any;
    navigate: any;
    onSelect: any;
    rowIndex: number;
}

interface DatasetChooserProps {
    onSelect: (data: TableSelectPayload) => void;
    projectId: string;
}

const LoaderPositioning = styled('div')({
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const LoaderText = () => {
    const { t } = useTranslation();
    return (
        <LoaderPositioning>
            <CircularProgress
                size="1rem"
                color="secondary" />
            <br />
            {t('searching_folders')}
        </LoaderPositioning>
    );
};

const getFolderName = (blobName: string): string => {
    const navLinkTokenized = blobName.split('/');

    return navLinkTokenized.slice(1).join('/');
};

const LevelOneDatasetChooser: React.FC<LevelOneDatasetChooserProps> = ({
    onSelect,
    onCellClick,
    projectId,
}) => {
    const { t } = useTranslation();
    if (!projectId) {
        return null;
    }
    const dataMapper: ColumnDefinition[] = [
        ...TableHeaderMappings.DatasetsBase,
        {
            header: ' ',
            cell: (data: TableSelectPayload): React.ReactElement => (
                <Button
                    key="bread-crumb-link-datasets-dialog"
                    data-testid={'selection-col-' + (data.row.original as any).name}
                    onClick={(e: { stopPropagation: () => void; preventDefault: () => void }) => {
                        onSelect(data);
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    variant="outlined"
                >
                    {t('common_select_label')}
                </Button>
            ),
        },
    ];
    const url = URLManager.url().apiTrainer + '/datasets?sortBy=createdOn&sortOrder=DESC&projectId=' + projectId;
    return (
        <>
            <DialogTitle>
                <LevelOneBreadCrumbComponent name={t('treeSelect_ph')} />
            </DialogTitle>
            <DialogContent style={{ minWidth: '720px' }}>
                <ServerSideTable
                    url={url}
                    searchable
                    searchKey="name"
                    showRefresh={false}
                    totalKey="data.totalCount"
                    dataKey="data.dataList"
                    mapper={dataMapper}
                    onTableCellClicked={onCellClick}
                    level="dataset_pagesize"
                />
            </DialogContent>
        </>
    );
};

const removeDoubleSlashes = (name: string): string => name.replace(/\/\//, '/');

const sanitizeFolderName = (name: string): string => {
    const n = removeDoubleSlashes(name);
    if (n.endsWith('/')) {
        return n;
    }
    return n + '/';
};

const LevelTwoDatasetChooser: React.FC<LevelTwoDatasetChooserProps> = ({
    onSelect,
    folderId,
    projectId,
    openDatasetChooser,
}) => {
    const { t } = useTranslation();
    const [ data, setData ] = useState<any[]>([]);
    const baseURL: string = React.useMemo(() => `${URLManager.url().apiTrainer}/datasets/listDataset`, []);
    const [ blobLimitReached, setBlobLimitReached ] = useState(false);
    const [ params, setParams ] = React.useState<any>({
        pageSize: 10,
        directoryName: sanitizeFolderName(`${projectId + '/' + removeProjectIdIfAny(folderId, projectId)}/`),
        projectId,
    });

    React.useEffect(() => {
        setParams({
            ...params,
            directoryName: sanitizeFolderName(`${projectId + '/' + removeProjectIdIfAny(folderId, projectId)}`),
            projectId,
        });
    }, [ folderId, projectId ]);

    const loadDataUntilFolder = useCallback(async (append: boolean, directoryName: string): Promise<boolean> => {
        try {
            let pageToken: string = params.pageToken;
            let folderFound = false;
            while (!folderFound) {
                const response = await http.get(baseURL, {
                    params: {
                        ...params,
                        pageToken,
                        directoryName: sanitizeFolderName(directoryName),
                    },
                });
                const dataList = response.data.data.dataList as any[];

                const folders = dataList.filter(d => d.blobType === 'DIRECTORY');
                folderFound = folders.length > 0;

                if (!response.data.data.nextPageToken) {
                    setBlobLimitReached(true);
                }
                if (folderFound || !response.data.data.nextPageToken) {
                    if (append) {
                        setData([ ...data, ...folders ]);
                    } else {
                        setData(folders);
                    }
                    setParams({
                        ...params,
                        pageToken: response.data.data.nextPageToken,
                    });
                    break;
                }
                pageToken = response.data.data.nextPageToken;
            }
            return true;
        } catch (e) {
            logger.error({
                identifier: 'Data set chooser',
                message: 'Error fetching lists in dataset chooser',
                error: e,
            });
        }
        return false;
    }, [ params.directoryName, params.pageToken ]);

    React.useEffect(() => {
        setBlobLimitReached(false);
        setData([]);
        loadDataUntilFolder(false, params.directoryName);
    }, [ params.directoryName ]);

    const loadMore = useCallback(() => {
        loadDataUntilFolder(true, params.directoryName);
    }, [ params.directoryName, params.pageToken ]);

    const navigate = useCallback((fId: string) => {
        setParams({
            ...params,
            directoryName: sanitizeFolderName(`${projectId}/${removeProjectIdIfAny(fId, projectId)}`),
        });
    }, [ projectId ]);

    if (!folderId || !projectId) {
        return null;
    }
    return (
        <>
            <DialogTitle>
                <LevelTwoBreadCrumbComponent
                    parent={t('project_details_datasets_title')}
                    parentNavigate={openDatasetChooser}
                    blobName={params.directoryName}
                    blobNavigate={navigate}
                />
            </DialogTitle>
            <DialogContent style={{ minWidth: '720px' }}>
                <div
                    id="scrollableDiv"
                    style={{
                        height: '450px',
                        overflowY: 'auto',
                    }}>
                    <TableContainer>
                        <InfiniteScroll
                            dataLength={data.length}
                            hasMore={!blobLimitReached}
                            next={loadMore}
                            loader={<LoaderText />}
                            scrollableTarget="scrollableDiv"
                        >
                            <StyledTable>
                                <TableHead className="tableHead">
                                    <TableRow>
                                        <TableCell>
                                            {t('common_name_label')}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <WithVisibility visible={blobLimitReached && data.length == 0}>
                                    <StyledTableRow>
                                        <TableCell
                                            colSpan={2}
                                            align="center">
                                            {t('no_folders_found_text')}
                                        </TableCell>
                                    </StyledTableRow>
                                </WithVisibility>
                                {
                                    data.map((d: any, i) => d.blobType === 'DIRECTORY' ? (<LevelTwoDatasetTableRow
                                        rowIndex={i}
                                        row={d}
                                        navigate={navigate}
                                        onSelect={onSelect} />) : null)
                                }
                            </StyledTable>
                        </InfiniteScroll>
                    </TableContainer>
                </div>
            </DialogContent>
        </>
    );
};
const LevelOneBreadCrumbComponent: React.FC<{ name: string }> = ({ name }) => (
    <Breadcrumbs>
        <Typography
            key="bread-crumb-link-datasets-dialog"
            color="textPrimary">
            {name}
        </Typography>
    </Breadcrumbs>
);

const removeProjectIdIfAny = (folderName: string, projectId: string): string => removeDoubleSlashes(folderName.replace(projectId, ''));

const LevelTwoBreadCrumbComponent: React.FC<{
    parent: string;
    parentNavigate: () => void;
    blobNavigate: (folderId: string) => void;
    blobName: string;
}> = ({
    parent, parentNavigate, blobName, blobNavigate,
}) => {
    // remove project id and dataset ID from name and split
    const crumbs = blobName.split('/');
    const datasetId = crumbs[1];

    // remove last empty entry as it ends with /
    crumbs.pop();

    return (
        <Breadcrumbs>
            <Link
                key="bread-crumb-link-datasets-dialog"
                onClick={parentNavigate}
                underline="hover"
                color="textPrimary">
                {parent}
            </Link>
            {
                crumbs.slice(2).map(
                    (crumbName, idx) => {
                        // last is -3 as it was sliced by 2 above
                        if (idx === crumbs.length - 3) {
                            return <Typography
                                key="bread-crumb-link-datasets-dialog"
                                color="textPrimary">
                                {crumbName}
                            </Typography>;
                        }
                        return <Link
                            key="bread-crumb-link-datasets-dialog"
                            onClick={() => {
                                blobNavigate(`${datasetId}/${crumbs.slice(2, idx + 3).join('/')}`);
                            }}
                            underline="hover"
                            color="textPrimary">
                            {crumbName}
                        </Link>;
                    })
            }
        </Breadcrumbs>
    );
};

const LevelTwoDatasetTableRow: React.FC<LevelTwoDatasetTableRowProps> = ({
    row, navigate, onSelect, rowIndex,
}) => {
    const { t } = useTranslation();
    const ref = useRef<any>();

    const handleOnTableCellClick = (): void => {
        navigate(getFolderName(row.blobName));
    };

    const keyInputHandler = useCallback(bindKeyTo({
        ref,
        traps: {
            [Key.Enter]: handleOnTableCellClick,
            [Key.Space]: handleOnTableCellClick,
        },
        cycle: true,
    }), [ ref ]);

    return (
        <StyledTableRow
            data-testid="table-row"
            data-cy="table-row"
            onKeyDown={keyInputHandler}
            tabIndex={rowIndex === 0 ? 0 : -1}
            ref={ref}

        >
            <TableCell onClick={() => {
                navigate(getFolderName(row.blobName));
            }}>
                {
                    getFileOrFolderNameForPathFromString(row.blobName, row.contentType)
                }
            </TableCell>
            <TableCell align="right">
                <Button
                    data-testid={'selection-col-' + row.blobName}
                    key="bread-crumb-link-datasets-dialog"
                    onClick={(e: { stopPropagation: () => void; preventDefault: () => void }) => {
                        onSelect(row);
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    variant="outlined"
                >
                    {t('common_select_label')}
                </Button>
            </TableCell>
        </StyledTableRow>);
};

export default FormikCompliantDataSetChooser;
