import { combineReducers } from 'redux';

import { AuthReducer } from './auth';
import { AuthorizationReducer } from './authZ';
import { ConfigurationReducer } from './config';
import { FeatureFlagReducer } from './featuremanagement';

export const reducers = combineReducers({
    auth: AuthReducer,
    authZ: AuthorizationReducer,
    config: ConfigurationReducer,
    featureManagement: FeatureFlagReducer,
});
