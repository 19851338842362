import type {
    OptionsObject,
    ProviderContext,
} from 'notistack';
import { useSnackbar } from 'notistack';

function enqueueInProgress(this: ProviderContext, message: string, options?: OptionsObject) {
    return this.enqueueSnackbar(message, {
        ...options,
        variant: 'default',
        persist: true,
        key: message,
        autoHideDuration: 5000,
        preventDuplicate: true,
    });
}

function enqueueInProgressIndefinite(this: ProviderContext, message: string, options?: OptionsObject) {
    return this.enqueueSnackbar(message, {
        ...options,
        variant: 'default',
        persist: true,
        key: message,
        autoHideDuration: null,
        preventDuplicate: true,
    });
}

function enqueueErrorIndefinite(this: ProviderContext, message: string, options?: OptionsObject) {
    return this.enqueueSnackbar ? this.enqueueSnackbar(message, {
        ...options,
        variant: 'error',
        persist: true,
        key: message,
        autoHideDuration: null,
        preventDuplicate: true,
    }) : null;
}

function enqueueSuccess(this: ProviderContext, message: string, options?: OptionsObject) {
    return this.enqueueSnackbar(message, {
        ...options,
        variant: 'success',
        key: message,
        autoHideDuration: 5000,
        preventDuplicate: true,
    });
}

function enqueueError(this: ProviderContext, message: string, options?: OptionsObject) {
    return this.enqueueSnackbar ? this.enqueueSnackbar(message.length > 500 ? message.substr(0, 497) + '...' : message, {
        variant: 'error',
        key: message,
        autoHideDuration: 10000,
        preventDuplicate: true,
        ...options,
    }) : null;
}

function closeSnackBar(this: ProviderContext, key: string) {
    this.closeSnackbar(key);
}

export const useFeedback = () => ({
    ...useSnackbar(),
    enqueueInProgress,
    enqueueSuccess,
    enqueueError,
    enqueueErrorIndefinite,
    enqueueInProgressIndefinite,
    closeSnackBar,
});
