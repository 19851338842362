import styled from '@emotion/styled';
import { PortalAlertErrorPage } from '@uipath/portal-shell-react';
import type { ReactElement } from 'react';
import React, { useRef } from 'react';

const Background = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // otherwise it overlaps with portal-nav border
    marginLeft: '1px',

    '& portal-alert-error-page': { width: '100%' },
});

interface ErrorDialogProps {
    title: string;
    subtitle?: string;
    showLogo?: boolean;
    buttonCallback: () => void;
    secondaryButtonCallback: () => void;
    buttonText?: string;
    secondaryButtonText?: string;
    children?: React.ReactNode | JSX.Element;
    backendCode: null | undefined | string;
}

export default function ErrorDialog(props: ErrorDialogProps): ReactElement {
    const portalErrorElement = useRef<HTMLPortalAlertErrorPageElement>(null);
    React.useEffect(() => {
        portalErrorElement.current?.addEventListener('primaryButtonClicked', () => {
            props.buttonCallback();
        });
        portalErrorElement.current?.addEventListener('secondaryButtonClicked', () => {
            props.secondaryButtonCallback();
        });
    }, [ portalErrorElement.current ]);

    return (
        <Background
            data-testid="error_dialog">
            <PortalAlertErrorPage
                ref={portalErrorElement}
                type="error"
                primary-action-text={props.buttonText}
                secondary-action-text={props.secondaryButtonText}
                heading={props.title}
                subheading={props.backendCode || ''}>
                {props.subtitle}
            </PortalAlertErrorPage>
        </Background>
    );
}
