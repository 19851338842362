import type {
    BaseResponseOfLicenseDto,
    LicenseDto,
} from '@uipath/aifabric';

import URLManager from '../../config/URLManager';
import { http } from '../../http';

export const getLicenseUsage = async (): Promise<LicenseDto | undefined> => {
    const response = await http.get<BaseResponseOfLicenseDto>(URLManager.url().apiDeployer + `/tenantlicensestats/licenses`);
    return response.data.data;
};
