import React from 'react';

import { PermissionsStateProvider } from '../../providers/PermissionsProvider';
import { ConfigurationHost } from '../configHost/ConfigHost';

export const PermissionsHost: React.FC = () => (
    <PermissionsStateProvider>
        <ConfigurationHost />
    </PermissionsStateProvider>
);
