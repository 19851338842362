export const Validation = {
    formStringAlphaNumeric: /[a-zA-Z_][a-zA-Z-0-9_ ]*$/i,

    projectCreateNameValidation: /^[a-zA-Z_][a-zA-Z0-9_ ]*$/i,
    projectCreateProjectNameMax: 50,
    projectCreateDescriptionMax: 2048,

    folderNameValidation: /^[a-zA-Z_0-9][a-zA-Z0-9_ .-]*$/i,

    datasetCreateNameValidation: /^[a-zA-Z0-9][a-zA-Z0-9_ .-]*$/i,
    datasetCreateDatasetNameMax: 36,
    datasetCreateDescriptionMax: 2048,

    uploadDescriptionMax: 2048,

    /* Ml package name should not have spcace  */
    mlPackageCreateNameValidation: /^[A-Za-z]\w*$/i,
    mlpackageCreatePackageNameMax: 36,
    mlpackageCreatePackageNameMin: 2,
    mlpackageCreateDescriptionMax: 2048,

    /* Ml skill name should not have spcace  */
    mlSkillCreateNameValidation: /^[a-zA-Z_][a-zA-Z0-9_]*$/i,
    mlSkillCreatePackageNameMax: 36,
    mlSkillCreateDescriptionMax: 2048,

    appNameValidation: /^[a-zA-Z_][a-zA-Z0-9_]*$/i,
    appNameLengthMax: 36,

    pipelineNameValidation: /^[a-zA-Z0-9][\w .-]*$/i,
    pipelineNameLengthMax: 50,

    /* MLSkill infra settings validation */
    mlSkillInfraSettingsValidation: /[1-9]\d*/,

    customVersionLengthValidation: 36,
    customVersionNameValidation: /[a-zA-Z0-9][a-zA-Z0-9_.-]*$/i,
};
