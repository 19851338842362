import * as React from 'react';

import AuthenticationProvider from '../../providers/AuthenticationProvider';
import AuthorizationHost from '../authorizationHost/AuthorizationHost';

export const AuthenticationHost: React.FC = () => (
    <AuthenticationProvider>
        <AuthorizationHost />
    </AuthenticationProvider>
);
