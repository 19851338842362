import * as LDClient from 'launchdarkly-js-client-sdk';

import FeatureFlagManager from './FeatureFlagManager';

export default class LaunchDarklyFeatureFlagManager extends FeatureFlagManager {
    private ldClient: LDClient.LDClient;

    private constructor(envKey = '', userKey = '', userEmail = '') {
        super();
        const user = {
            key: userKey,
            email: userEmail,
            name: userKey,
        };
        this.ldClient = LDClient.initialize(envKey, user);
    }

    static initialize(envKey = '', userKey = '', userEmail = ''): FeatureFlagManager | null {
        if (!LaunchDarklyFeatureFlagManager.instance) {
            if (envKey) {
                FeatureFlagManager.instance = new LaunchDarklyFeatureFlagManager(envKey, userKey, userEmail);
            }
        }
        return LaunchDarklyFeatureFlagManager.instance;
    }

    async waitUntilReady(): Promise<void> {
        return this.ldClient.waitUntilReady();
    }

    isEnabled(flag: string): boolean {
        return this.ldClient.variation(flag, false);
    }
}
