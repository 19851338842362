import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Section from '../../../components/Section';
import type { ColumnDefinition } from '../../../components/Table/BaseTable/types';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../../config/URLManager';
import { MLlogsSeverityMapping } from '../../../constants/EntityFieldMapping';
import { AppPermissions } from '../../../enums/Authorization';
import { PermissionsContext } from '../../../providers/PermissionsProvider';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    dataFormatter,
    entityDataMapper,
    returnValueIfNotUUID,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';

const OobLogsPageContainer = styled('div')({
    width: '100%',
    fontSize: Tokens.FontFamily.FontMSize,
    fontFamily: Tokens.FontFamily.FontNormal,
    overflowY: 'auto',
});

interface ImportOOBLogsPageProps {
    permissions: AppPermissions[];
}

interface ImportOOBLogsListProps {
    auditsUrl: string;
}

const MLPackageImportOOBLogsPageContent: React.FC<ImportOOBLogsPageProps> = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const auditsUrl = URLManager.url().apiHelper + '/audits?components=ML_PACKAGE&sortBy=createdOn&sortOrder=DESC&isPublic=true';
    const { currentProjectId } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProjectId ?? 'undefined']?.permissionSet || [];

    const buttonItems = [];
    if ((permissions.indexOf(AppPermissions.MLPackages_Create) > -1)) {
        buttonItems.push({
            buttonText: 'import_oob_package_create_button_label',
            buttonCallback: () => {
                history.push(RoutePath.IMPORT_MLPACKAGE_OOB);
            },
        });
    }

    return (permissions.indexOf(AppPermissions.MLLogs_View) > -1) ? (
        <OobLogsPageContainer>
            <Section
                title={t('import_oob_ml_logs_title')}
                buttonItems={buttonItems}>
                <MLPackageImportOOBLogs auditsUrl={auditsUrl} />
            </Section>
        </OobLogsPageContainer >
    ) : null;
};

const MLPackageImportOOBLogs: React.FC<ImportOOBLogsListProps> = ({ auditsUrl }) => {
    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: ColumnDefinition[] = [
        {
            header: `${t('label_severity')}`,
            accessorKey: 'severity',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dataFormatter(getValue()),
        },
        {
            header: `${t('label_about')}`,
            accessorKey: 'entityTypeName',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => entityDataMapper(getValue(), MLlogsSeverityMapping),
        },
        {
            header: `${t('label_user')}`,
            accessorKey: 'createdBy',
            cell: ({ cell: { getValue } }): string => returnValueIfNotUUID(getValue()),
        },
        {
            header: `${t('label_description')}`,
            accessorKey: 'message',
        },
        {
            header: `${t('label_time')}`,
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
        },
    ];

    return (
        <ServerSideTable
            url={auditsUrl}
            totalKey="data.totalCount"
            dataKey="data.dataList"
            mapper={dataMapper}
            searchable
            searchKey="searchText"
            level="mlpackages_pagesize"
        />
    );
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ImportOOBLogs = MLPackageImportOOBLogsPageContent;
