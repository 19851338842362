export default {
    APIAuthSkillsEnabled: 'api-auth-skills-enabled',
    APIAuthSkillsEnabledNonPublic: 'api-auth-skills-enabled-non-public',
    PublicDS: 'public-ds',
    RBACEnabled: 'rbac-enabled',
    BlobEncryptionEnabled: 'cmkEnabled',
    EnablePortalShellV3: 'apollo-enablePortalShellV3',
    pipelineReportDownloadEnabled: 'pipelineReportDownloadEnabled',
    mlSkillReportDownloadEnabled: 'mlSkillReportDownloadEnabled',
};
