import { Authentication } from '../../enums/Authentication';
import type { ActionType } from '../Actions';
import { UserActions } from '../Actions';
import type { AuthenticationState } from '../states';

export const AuthReducer = (
    state: AuthenticationState = {
        userState: Authentication.UNINITIALIZED,
        userData: null,
        authToken: '',
        idToken: '',
        isHostAdmin: false,
        // adding default values
        accountId: 'host',
        tenantId: 'host',
        userId: 'host',
        firstName: '',
        lastName: '',
        email: '',
    },
    action: ActionType,
): AuthenticationState => {
    const payload = action.payload || {};
    switch (action.type) {
        case UserActions.INITIATED:
            return {
                ...state,
                userState: Authentication.INITIATED,
            };
        case UserActions.LOGIN_INITIATED:
            return {
                ...state,
                accountId: payload.accountId || state.accountId,
                tenantId: payload.tenantId || state.tenantId,
                userId: payload.userId || state.userId,
                userState: Authentication.LOGIN_INITIATED,
            };
        case UserActions.LOGGEDIN:
            return {
                ...state,
                accountId: payload.accountId || state.accountId,
                tenantId: payload.tenantId || state.tenantId,
                userId: payload.userId || state.userId,
                isHostAdmin: payload.isHostAdmin || state.isHostAdmin,
                userState: Authentication.LOGGEDIN,
                authToken: payload.token,
                idToken: payload.idToken,
                firstName: payload.firstName || state.firstName,
                lastName: payload.lastName || state.lastName,
                email: payload.email || state.email,
            };
        case UserActions.AUTHTOKEN:
            return {
                ...state,
                userState: Authentication.LOGGEDIN,
                authToken: payload.token,
                idToken: payload.idToken,
                isHostAdmin: payload.isHostAdmin || state.isHostAdmin,
                firstName: payload.firstName || state.firstName,
                lastName: payload.lastName || state.lastName,
                email: payload.email || state.email,
            };
        case UserActions.LOGOUT_INITIATED:
            return {
                ...state,
                userState: Authentication.LOGOUT_INITIATED,
            };
        case UserActions.LOGGEDOUT:
            return {
                ...state,
                userState: Authentication.LOGGEDOUT,
            };
        case UserActions.USERDATA_UPDATE:
            return {
                ...state,
                userData: payload,
            };
        default:
            return state;
    }
};
