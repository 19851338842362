import URLManager from '../../config/URLManager';
import { http } from '../../http';

export const getLabelingTemplates = async (projectId: string): Promise<any> => {
    const res = await http.get(URLManager.url().apiAppManager + '/oobtemplates', { headers: { 'project-id': projectId } });
    return res.data.data;
};

export const getLabelStudioTemplate = async (appId: string, projectId: string): Promise<any> => {
    const res = await http.get(URLManager.url().apiAppManager + '/app/template/' + appId, { headers: { 'project-id': projectId } });
    return res.data;
};

export const updateLabelStudioTemplate = async (appId: string, projectId: string, template: string): Promise<any> => {
    const res = await http.put(URLManager.url().apiAppManager + '/app/template/' + appId, { template }, { headers: { 'project-id': projectId } });
    return res.data;
};
