import {
    MenuItem,
    styled,
    Typography,
} from '@mui/material';
import type { MLPackageVersionDto } from '@uipath/aifabric';
import {
    Field,
    Formik,
} from 'formik';
import React, {
    useContext,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';
import * as Yup from 'yup';

import {
    clonePublicMLPackage,
    clonePublicMLPackageVersion,
    getPublicPackageVersion,
} from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import { PageBreadCrumbs } from '../../../components/Breadcrumbs';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import { Validation as val } from '../../../constants/validation';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    extractErrorFields,
    extractErrorMessage,
} from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';

const ErrorLabel = styled('div')({
    marginTop: '5px',
    marginBottom: '5px',
});

const UiPathPackageCloneContainer = styled('div')(({ theme }) => ({
    width: '100%',

    '& p': { color: theme.palette.semantic.colorForeground },
}));

const UiPathPackageClone: React.FC = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const feedback = useFeedback();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locationState: any = useLocation()?.state;
    const data = locationState.data;

    const {
        projectName, uiPathProjectName, uiPathPackageId, uipathPackageVersionId,
    } = useParams<{ projectName: string; uiPathProjectName: string; uiPathPackageId: string; uipathPackageVersionId: string }>();
    const [ packageVersion, setPackageVersion ] = useState<MLPackageVersionDto>();

    const { state: projectsState } = useContext(ProjectsContext);
    const currentProject = projectsState.currentProject;

    React.useEffect(() => {

        if (currentProject?.id && uiPathPackageId && uipathPackageVersionId) {
            getPublicPackageVersion(uiPathPackageId, uipathPackageVersionId, data.mlPackageOwnedByAccountId, data.mlPackageOwnedByTenantId, data.mlPackageOwnedByProjectId, currentProject?.id)
                .then((packageVersionResponse) => {
                    setPackageVersion(packageVersionResponse);
                    return true;
                })
                .catch((error: Error) => {
                    logger.error({
                        identifier: 'Public Package clone',
                        message: 'Error getting public package version',
                        error,
                    });
                });
        }
    }, [ currentProject?.id ]);

    return (
        <UiPathPackageCloneContainer>
            <Formik
                enableReinitialize
                initialValues={{
                    packageName: data.mlpackageName ? data.mlpackageName : '',
                    packageVersionId: uipathPackageVersionId,
                    inputDescription: packageVersion?.inputDescription,
                    outputDescription: packageVersion?.outputDescription,
                    packageDescription: packageVersion?.description,
                }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {
                    try {
                        let createdPackage = undefined;

                        const mlPackageCloneMetaData = {
                            displayName: values.packageName,
                            name: values.packageName,
                            description: values.packageDescription,
                            inputDescription: values.inputDescription,
                            outputDescription: values.outputDescription,
                            projectId: currentProject?.id,
                            mlPackageOwnedByAccountId: packageVersion?.accountId,
                            mlPackageOwnedByTenantId: packageVersion?.tenantId,
                            mlPackageOwnedByProjectId: packageVersion?.projectId,
                            sourcePackageId: packageVersion?.mlPackageId,
                            sourcePackageVersionId: packageVersion?.id,
                            parametersFileJSON: packageVersion?.parametersFileJSON,
                            customVersion: packageVersion?.customVersion,
                        };

                        if (data.mlpackageName && data.mlpackageId) {
                            await clonePublicMLPackageVersion(mlPackageCloneMetaData, data.mlpackageId);
                            feedback.enqueueSuccess(t('feedback_create_success_mlpkg_version'));
                        } else {
                            createdPackage = await clonePublicMLPackage(mlPackageCloneMetaData);
                            feedback.enqueueSuccess(t('feedback_create_success_mlpkg'));
                        }

                        history.push({
                            pathname: generatePath(RoutePath.MLPACKAGE_VERSION,
                                {
                                    projectName,
                                    mlpackageName: data.mlpackageName ? data.mlpackageName : values.packageName,
                                    mlpackageId: data.mlpackageId ? data.mlpackageId : createdPackage?.id,
                                }),
                            state: {
                                data: {
                                    sourcePackageId: packageVersion?.mlPackageId,
                                    mlPackageOwnedByAccountId: packageVersion?.accountId,
                                    mlPackageOwnedByTenantId: packageVersion?.tenantId,
                                    mlPackageOwnedByProjectId: packageVersion?.projectId,
                                },
                            },
                        });
                    } catch (error) {
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            t('mlpkg_create_default_error'),
                            {
                                10602: {
                                    1: 'ML Package',
                                    0: data.mlpackageId,
                                },
                                20001: {},
                                10009: {},
                                20008: { 0: data.mlpackageName },
                                20005: { 0: data.mlpackageName },
                                20006: { 0: data.mlpackageId },
                                20101: {},
                                20111: {},
                                20016: { 1: data.mlpackageName },
                                10403: { 0: 'Server Error' },
                                20301: {
                                    0: data.mlpackageId,
                                    1: data.tenantId,
                                },
                                10004: {
                                    0: 'ML Package',
                                    1: data.mlpackageId,
                                },
                                10001: { 0: extractErrorFields(error) },
                            },
                        ));
                    }
                }}
                validationSchema={Yup.object().shape({
                    packageName: Yup.string()
                        .required(t('mlpkg_create_pkgName_req'))
                        .matches(val.mlPackageCreateNameValidation, t('ml_package_name_validation_error'))
                        .min(val.mlpackageCreatePackageNameMin,
                            t('mlpkg_create_pkgName_max', {
                                max: val.mlpackageCreatePackageNameMax,
                                min: val.mlpackageCreatePackageNameMin,
                            }))
                        .max(val.mlpackageCreatePackageNameMax,
                            t('mlpkg_create_pkgName_max', {
                                max: val.mlpackageCreatePackageNameMax,
                                min: val.mlpackageCreatePackageNameMin,
                            })),
                    inputDescription: Yup.string().notRequired()
                        .max(val.mlpackageCreateDescriptionMax,
                            t('mlpkg_create_inputDesc_max', { max: val.mlpackageCreateDescriptionMax + 1 })),

                    outputDescription: Yup.string().notRequired()
                        .max(val.mlpackageCreateDescriptionMax,
                            t('mlpkg_create_outputDesc_max', { max: val.mlpackageCreateDescriptionMax + 1 })),

                })}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    props => {
                        const {
                            dirty,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            errors,
                        } = props;
                        const textProps: any = {
                            type: 'text',
                            component: AccessibleFormikInput,
                            className: 'min-width-override',
                            variant: 'outlined',
                            color: 'secondary',
                            autoComplete: 'off',
                            multiline: true,
                            rows: 6,
                        };
                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                        submitButtonText={t('form_edit_button_text')} />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                <PageBreadCrumbs
                                    crumbs={[
                                        {
                                            title: t('ml_packages_title'),
                                            link: generatePath(RoutePath.MLPACKAGES, { projectName }),
                                        },
                                        {
                                            title: t('out_of_the_box_packages'),
                                            link: generatePath(RoutePath.UIPATH_PROJECTS, { projectName }),
                                        },
                                        {
                                            title: uiPathProjectName ?? '',
                                            link: generatePath(RoutePath.UIPATH_PACKAGES, {
                                                projectName,
                                                uiPathProjectName,
                                            }),
                                            data: {
                                                mlPackageOwnedByAccountId: data.mlPackageOwnedByAccountId,
                                                mlPackageOwnedByTenantId: data.mlPackageOwnedByTenantId,
                                                mlPackageOwnedByProjectId: data.mlPackageOwnedByProjectId,
                                            },
                                        },
                                    ]}
                                    pageTitle={packageVersion?.mlPackageDisplayName ?? ''}
                                />

                                <Label
                                    value={t('mlpkg_create_pkgName_label')}
                                    required>
                                    <ErrorLabel>
                                        <Typography>
                                            {t('ml_package_name_validation_error')}
                                        </Typography>
                                        <Typography>
                                            {t('mlpkg_create_pkgName_max', {
                                                max: val.mlpackageCreatePackageNameMax,
                                                min: val.mlpackageCreatePackageNameMin,
                                            })}
                                        </Typography>
                                    </ErrorLabel>
                                </Label>
                                <Field
                                    name="packageName"
                                    type="text"
                                    disabled={data.newVersion}
                                    component={AccessibleFormikInput}
                                    requiredField
                                    className="textFeild min-width-override"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('mlpkg_create_pkgName_ph')}
                                    aria-label={`${t('mlpkg_create_pkgName_ph')} text field`}
                                    autoComplete="off"
                                />

                                <Label value={t('uipath_package_choose_version')} />
                                <Field
                                    component={AccessibleFormikInput}
                                    className="textFeild min-width-override"
                                    type="text"
                                    name="packageVersionId"
                                    select
                                    variant="outlined"
                                    color="secondary"
                                    disabled
                                    aria-label={`${t('uipath_package_choose_version')} text field`}
                                >

                                    <MenuItem value={packageVersion?.id}>
                                        {(packageVersion?.customVersion ? packageVersion.customVersion : packageVersion?.version) + '.' + packageVersion?.trainingVersion}
                                    </MenuItem>
                                </Field>

                                <Label value={t('uipath_package_description')} />
                                <Field
                                    name="packageDescription"
                                    placeholder={t('mlpkg_create_pkgDesc_ph')}
                                    aria-label={`${t('mlpkg_create_pkgDesc_ph')} text field`}
                                    {...textProps}
                                />

                                <Label value={t('uipath_package_input_description')} />
                                <Field
                                    name="inputDescription"
                                    aria-label={`${t('mlpkg_create_inputDesc_ph')} text field`}
                                    placeholder={t('mlpkg_create_inputDesc_ph')}
                                    {...textProps}
                                />

                                <Label value={t('uipath_package_output_description')} />
                                <Field
                                    name="outputDescription"
                                    placeholder={t('mlpkg_create_outputDesc_ph')}
                                    aria-label={`${t('mlpkg_create_outputDesc_ph')} text field`}
                                    {...textProps}
                                />
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </UiPathPackageCloneContainer>
    );
};

export default UiPathPackageClone;
