import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    styled,
    Typography,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BaseTableIcons } from '../enums/BaseTableIcons';
import type { BreadcrumbProps } from './Breadcrumbs';
import { PageBreadCrumbs } from './Breadcrumbs';

const SectionContainer = styled('div')({
    marginTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',

    '& .section-children': { marginTop: '8px' },
});

const TitleBar = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& .title-tooltip-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

        '& .title': {
            lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
            margin: '0 0 6px 0',
            fontWeight: 700,
            fontSize: Tokens.FontFamily.FontHeader4Size,
            fontFamily: Tokens.FontFamily.FontNormal,
            color: theme.palette.semantic.colorForegroundEmp,
        },
    },
}));

const NavigationLink = styled(Link)({
    textDecoration: 'none',
    '&:focus, &:hover': { textDecoration: 'underline' },
});

const StyledIconButton = styled('div')({
    marginTop: '6px',
    marginLeft: '10px',
});

const TextButtons = styled('div')<{ iconButtons: boolean }>(({ iconButtons }) => ({
    ...(iconButtons && {
        fontSize: Tokens.FontFamily.FontMSize,
        fontWeight: Tokens.FontFamily.FontWeightDefault,
    }),
}));

interface SectionProps {
    title?: string;
    breadcrumProps?: BreadcrumbProps;
    className?: string;
    iconsButtons?: IconsButtons[];
    buttonItems?: ButtonItem[];
    titleNavigationLink?: string;
    dropDownButtonItems?: ButtonItemDropDown[];
    tooltipText?: string;
    children?: ReactNode;
}

export interface ButtonItemDropDown {
    label: string;
    variant: 'text' | 'outlined' | 'contained';
    items: ButtonItem[];
}

export interface ButtonItem {
    buttonText: string;
    buttonCallback: () => void;
    customClassName?: string;
    disabled?: boolean;
    startIcon?: string;
    variant?: 'text' | 'outlined' | 'contained';
}

interface IconsButtons {
    type: BaseTableIcons;
    disabled?: boolean;
    click: () => void;
}

const Section: React.FC<SectionProps> = props => {

    const { t } = useTranslation();

    return (
        <SectionContainer
            aria-label={t('a11y_pages_section')}>
            <TitleBar>
                <div className="title-tooltip-container">
                    {props.breadcrumProps ? (
                        <PageBreadCrumbs
                            pageTitle={props.breadcrumProps.pageTitle}
                            key={props.breadcrumProps.pageTitle}
                            crumbs={props.breadcrumProps.crumbs}
                        />) :
                        (<div
                            id='page-title'
                            tabIndex={0}>
                            {props.titleNavigationLink ? (<div
                                role="heading"
                                aria-level={2}
                            >
                                <NavigationLink
                                    className="title"
                                    tabIndex={0}
                                    aria-label={props.title}
                                    role="navigation"
                                    to={props.titleNavigationLink}>
                                    {props.title}
                                </NavigationLink>
                            </div>) :
                                (<>
                                    <h2
                                        className="title"
                                        aria-label={props.title}>
                                        {props.title}
                                    </h2>
                                </>
                                )}
                        </div>)}
                    {props.tooltipText && (
                        <div>
                            <Typography>
                                {props.tooltipText}
                            </Typography>
                        </div>
                    )}
                </div>
                {
                    props.iconsButtons ? (
                        <StyledIconButton
                            aria-label={t('a11y_page_icon_buttons')}>
                            {props.iconsButtons.map((item: IconsButtons, index) => <GetIconByType
                                item={item}
                                index={index}
                                key={index} />)}
                        </StyledIconButton>) : null
                }

                {
                    props.buttonItems ?
                        (
                            <TextButtons
                                aria-label={t('a11y_page_text_buttons')}
                                iconButtons={!!props.iconsButtons}>
                                {
                                    props.buttonItems.map((item: ButtonItem, index) => (
                                        <Button
                                            aria-label={t(item.buttonText)}
                                            style={{ marginLeft: '8px' }}
                                            disableElevation
                                            disableRipple
                                            variant={item.variant ? item.variant : 'outlined'}
                                            key={index}
                                            disabled={item.disabled === true}
                                            onClick={item.buttonCallback}
                                            startIcon={item.startIcon ? getButtonIcon(item.startIcon) : ''}
                                            data-testid={'button' + index}
                                            data-cy={t(item.buttonText) + '_button'}>
                                            {t(item.buttonText)}
                                        </Button>
                                    ))
                                }
                            </TextButtons>
                        ) : null
                }

                {
                    props.dropDownButtonItems ?
                        (
                            <TextButtons
                                aria-label={t('a11y_page_text_buttons')}
                                iconButtons={!!props.iconsButtons}>
                                {
                                    props.dropDownButtonItems.map((bd: ButtonItemDropDown) =>
                                        (<DropDown
                                            variant={bd.variant}
                                            items={bd.items}
                                            label={bd.label} />),
                                    )
                                }
                            </TextButtons>
                        ) : null
                }
            </TitleBar>

            <div
                aria-label={t('a11y_page_section_content')}
                className="section-children">
                {props.children}
            </div>
        </SectionContainer>
    );
};

const DropDown: React.FC<ButtonItemDropDown> = (props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant={props.variant || 'contained'}
                data-testid={props.label}
                onClick={handleClick}>
                {props.label}
            </Button>
            <Menu
                autoFocus={false}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    props.items.map(b => (
                        <MenuItem
                            data-testid={b.buttonText}
                            onClick={() => {
                                b.buttonCallback();
                                handleClose();
                            }}>
                            {b.buttonText}
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
};

interface GetIconByTypeProps {
    item: IconsButtons;
    index: number;
}

const GetIconByType: React.FC<GetIconByTypeProps> = ({
    item, index,
}) => {
    const { t } = useTranslation();
    if (item.type === BaseTableIcons.DOWNLOAD) {
        return (
            <IconButton
                style={{ padding: '4px' }}
                key={index}
                disabled={item.disabled ? item.disabled : false}
                onClick={item.click}
                data-testid={'Iconbutton' + index}
                aria-label={t('a11y_download')}
                data-cy={'Iconbutton' + index}>
                <GetAppSharpIcon />
            </IconButton>
        );
    }

    if (item.type === BaseTableIcons.REFRESH) {
        return (
            <IconButton
                style={{ padding: '4px' }}
                key={index}
                disabled={item.disabled ? item.disabled : false}
                onClick={item.click}
                data-testid="Iconbutton-refresh"
                aria-label={t('a11y_refresh_logs')}
                data-cy={'Iconbutton' + index}>
                <RefreshIcon />
            </IconButton>
        );
    }

    return null;
};

/**
 * Extract button icon by key
 * @param iconKey
 */
const getButtonIcon = (iconKey: string): React.ReactElement | string => {
    if (iconKey === 'ArrowRightAltIcon') {
        return (<ArrowRightAltIcon />);
    }
    return '';
};

export default Section;
