import type { URLs } from '../state-management/states';
import { store } from '../state-management/store';

let urls: URLs;

const unsubscribe = store.subscribe(() => {
    const { config } = store.getState();
    if (config.paths.apiPkgManager) {
        urls = config.paths;
        unsubscribe();
    }
});

const URLManager = { url: (): URLs => urls };

export default URLManager;
