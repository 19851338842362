// eslint-disable-next-line simple-import-sort/imports
import randomBytes from 'randombytes';
import type {
    AxiosError,
    AxiosInstance,
    AxiosResponse,
    CancelToken,
    InternalAxiosRequestConfig,
} from 'axios';
import { jwtDecode } from 'jwt-decode';

import type { Store } from '@reduxjs/toolkit';
import { TOKEN_EXPIRED } from '../constants/AiappConstants';
import { PERMISSIONS_FAILED_MSG } from '../constants/TelemetryConstants';
import {
    Origin,
    Scope,
    Service,
} from '../enums/ClientErrorStrings';
import { Configuration } from '../enums/Configuration';
import { getAccountAndTenantFromCannonicalPath } from '../route/routeHelper';
import type { ActionType } from '../state-management/Actions';
import { ConfigurationActions } from '../state-management/Actions';
import type { AppStore } from '../state-management/store';
import { store as originalStore } from '../state-management/store';
import { getDisplayErrorCode } from '../utils/CommonUtils';
import logger from '../utils/Logging';
import type { CustomHttpError } from './types';
import { http } from './index';

const SPAN_ID_BYTES = 8;
const {
    account, tenant,
} = getAccountAndTenantFromCannonicalPath();

export const captureIfCloudFlare = (body: string, status: any) => {
    if (body.toLowerCase().indexOf('cloudflare') > -1) {
        logger.error({
            identifier: 'Core',
            message: PERMISSIONS_FAILED_MSG,
            error: status,
            backendCode: getDisplayErrorCode(Scope.Http, Service.CLOUDFLARE, Origin.HTTP_INTERCEPTOR, null, status),
        });
        return true;
    }
    return false;
};

export const markNetworkError = (store: Store<AppStore, ActionType>) => {
    store.dispatch({
        type: ConfigurationActions.NETWORK_ISSUE,
        payload: null,
    });
};

const axiosInterceptorResSuccess = (response: AxiosResponse) => {
    const state = originalStore.getState();
    if (state.config.state == Configuration.NETWORK_ISSUE_UNAUTHORIZED) {
        markAllOK(originalStore);
    }
    return response;
};

const axiosInterceptorResError = (error: AxiosError) => {
    const customError: CustomHttpError = error;
    const response = error.response;
    if (response) {
        const request = response.request;
        const respText = request?.responseText;
        if (response.status) {
            if (response.status >= 500) {
                // @ts-ignore
                customError.isCloudFlareError = captureIfCloudFlare(respText, response.status);
            } else if (response.status === 401) {
                markUnauthorized(originalStore);
            } else {
                // this there was no server or auth failure, all systems can be marked ready
                markAllOK(originalStore);
            }
        }
    /* Request is being sent but response did not come */
    } else if (error.request) {
        customError.isNetworkError = true;
        markNetworkError(originalStore);
    }
    return Promise.reject(customError);
};

const checkIfTokenExpired = (authHeader?: string): (CancelToken | void) => {
    if (authHeader) {
        const tokens = authHeader.split(' ');
        const decodedToken: any = jwtDecode(tokens[1]);
        const expiryTime = parseInt(decodedToken.exp, 10);
        const secondsSinceEpoch = Math.round((new Date()).getTime() / 1000);
        if (expiryTime < secondsSinceEpoch) {
            const cancelToken = http.CancelToken;
            const source = cancelToken.source();
            setTimeout(() => {
                source.cancel(TOKEN_EXPIRED);
            });
            return source.token;
        }
    }
};

const axiosInterceptorReqSuccess = (request: InternalAxiosRequestConfig) => {
    const cancelToken = checkIfTokenExpired(request.headers['Authorization'] as string);
    if (cancelToken) {
        request.cancelToken = cancelToken;
    }
    if (request.headers) {
        request.headers['X-B3-TraceId'] = randomSpanId();
        request.headers['X-B3-SpanId'] = randomTraceId();
    }
    return request;
};

const axiosInterceptorReqError = (error: CustomHttpError) => Promise.reject(error);

export const addInterceptors = (instance: AxiosInstance) => {
    instance.interceptors.response.use(
        axiosInterceptorResSuccess,
        axiosInterceptorResError,
    );
    instance.interceptors.request.use(
        axiosInterceptorReqSuccess,
        axiosInterceptorReqError,
    );
    return instance;
};

export const markUnauthorized = (store: Store<AppStore, ActionType>) => {
    logger.error({
        identifier: 'Core',
        message: PERMISSIONS_FAILED_MSG,
        error: 401,
        backendCode: getDisplayErrorCode(Scope.Http, Service.JS, Origin.HTTP_INTERCEPTOR, null, 401),
    });
    store.dispatch({
        type: ConfigurationActions.NETWORK_ISSUE_UNAUTHORIZED,
        payload: null,
    });
};

export const markAllOK = (store: Store<AppStore, ActionType>) => {
    store.dispatch({
        type: ConfigurationActions.APPREADY,
        payload: null,
    });
};

/**
 * Returns a random 16-byte trace ID formatted/encoded as a 32 lowercase hex
 * characters corresponding to 128 bits.
 */
function randomTraceId(): string {
    return randomSpanId() + randomSpanId();
}

/**
 * Returns a random 8-byte span ID formatted/encoded as a 16 lowercase hex
 * characters corresponding to 64 bits.
 */
function randomSpanId(): string {
    return randomBytes(SPAN_ID_BYTES).toString('hex');
}

export const urlFiller = (url: string): string => url.replace(':account', account).replace(':tenant', tenant);
