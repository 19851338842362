import { styled } from '@mui/material';
import type {
    ProjectDto,
    PublicTenantDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useParams,
} from 'react-router-dom';

import {
    getPublicProjects,
    getPublicTenants,
} from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import extractionPkgImg from '../../../assets/extract-pkg.svg';
import { ActionCard } from '../../../components/ActionCard';
import { PageBreadCrumbs } from '../../../components/Breadcrumbs';
import LoadingDots from '../../../components/loadingDots/LoadingDots';
import Section from '../../../components/Section';
import { RoutePath } from '../../../route/routeMap';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';

const ProjectsCardContainer = styled('div')({
    width: '100%',
    fontSize: Tokens.FontFamily.FontMSize,
    overflowY: 'auto',

    '& .space-after': { marginRight: 24 },
});

interface ProjectCardProps {
    projectDtos: ProjectDto[];
}

const ProjectCards: React.FC<ProjectCardProps> = (props) => {
    const { projectName } = useParams<{
        projectName: string;
    }>();

    const projectCards = props.projectDtos.map((projectDto, index) =>
        <div
            className="action-card space-after"
            key={index}>
            <ActionCard
                title={projectDto.name ? projectDto.name : ''}
                cardContent={projectDto.description ? projectDto.description : ''}
                route={generatePath(RoutePath.UIPATH_PACKAGES, {
                    projectName,
                    uiPathProjectName: projectDto.name || '',
                })}
                data={{
                    mlPackageOwnedByAccountId: projectDto?.accountId,
                    mlPackageOwnedByTenantId: projectDto?.tenantId,
                    mlPackageOwnedByProjectId: projectDto?.id,
                }}
                imgUrl={extractionPkgImg}
            />
        </div>,
    );

    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
        }}>
            {projectCards}
        </div>
    );
};

const UiPathProjects: React.FC = () => {
    const { t } = useTranslation();
    const [ publicTenants, setPublicTenants ] = useState<PublicTenantDto[]>();
    const [ loading, setLoading ] = useState(false);
    const { projectName } = useParams<{ projectName: string }>();
    const feedback = useFeedback();

    React.useEffect(() => {
        setLoading(true);
        getPublicTenants()
            .then((publicTenantsResponse) => {
                if (publicTenantsResponse) {
                    // eslint-disable-next-line promise/no-nesting
                    getPublicProjects().then((projectDtos) => {
                        if (projectDtos) {
                            publicTenantsResponse.forEach(publicTenant => {
                                projectDtos.forEach(publicProject => {
                                    if (publicTenant.tenantId === publicProject.tenantId) {
                                        if (publicTenant.projectDtos === undefined) {
                                            publicTenant.projectDtos = [];
                                        }
                                        publicTenant.projectDtos.push(publicProject);
                                    }
                                });
                            });
                            setPublicTenants(publicTenantsResponse);
                        }
                        setLoading(false);
                        return true;
                    })
                        .catch((error: Error) => {
                            logger.error({
                                identifier: 'Public Projects',
                                message: 'Error getting public projects',
                                error,
                            });
                        });
                }
                return true;
            })
            .catch(error => {
                logger.error({
                    identifier: 'Public Projects',
                    message: 'Error getting public tenants',
                    error,
                });
                setLoading(false);
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_error_loading_public_projects'),
                    { 20107: {} },
                ));
            });
    }, []);

    const uipathProjects = publicTenants?.map((publicTenant, index) =>
        <Section
            key={index}
            title={publicTenant?.displayName === undefined ? '' : publicTenant.displayName}>
            <ProjectCards projectDtos={publicTenant.projectDtos ? publicTenant.projectDtos : []} />
        </Section>,
    );

    return (
        <ProjectsCardContainer>
            <Section title="">
                <PageBreadCrumbs
                    crumbs={[
                        {
                            title: t('ml_packages_title'),
                            link: generatePath(RoutePath.MLPACKAGES, { projectName }),
                        },
                    ]}
                    pageTitle={t('out_of_the_box_packages')}
                />
            </Section>
            {loading ? <LoadingDots /> : uipathProjects}
        </ProjectsCardContainer>
    );
};

export default UiPathProjects;
