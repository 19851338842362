import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { ReactNode } from 'react';
import React from 'react';

const TableHeaderRoot = styled('h4')(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontHeader4Size,
    lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
    color: theme.palette.semantic.colorForeground,
}));

interface TableHeadingProps {
    children: ReactNode;
}

export const TableHeading: React.FC<TableHeadingProps> = ({ children }) => <TableHeaderRoot>
    {children}
</TableHeaderRoot>;
