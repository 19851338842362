import FeatureFlagManager from './FeatureFlagManager';

export default class OnPremFeatureFlagManager extends FeatureFlagManager {
    private flags: string[];

    private constructor(flags: string[]) {
        super();
        this.flags = flags;
    }

    static initialize(flags: string | undefined): FeatureFlagManager | null {
        let flagList: string[] = [];
        if (flags !== null && flags !== undefined) {
            flagList = flags.replace(' ', '').split(',');
        }
        FeatureFlagManager.instance = new OnPremFeatureFlagManager(flagList);
        return FeatureFlagManager.instance;
    }

    isEnabled(flag: string): boolean {
        return Boolean(this.flags.find(f => f === flag));
    }
}
