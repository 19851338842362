import {
    Menu,
    styled,
    Table as MatTable,
    TableRow as MatTableRow,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';

export const StyledTableRow = styled(MatTableRow, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{ isSelected?: boolean }>(({
    theme, isSelected,
}) => ({
    ...(isSelected
        ? { background: theme.palette.semantic.colorBackgroundDisabled }
        : {
            '&:hover': { background: theme.palette.semantic.colorHover },
            '&:focus': { background: theme.palette.semantic.colorHover },
        }
    ),
}));

export const StyledActionRow = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
});

export const StyledContextPopupMenu = styled(Menu)({
    marginLeft: '15px',
    border: 'none',
    margin: 0,
    padding: 0,

    '& .MuiPopover-paper': {
        minWidth: '250px',
        width: 'auto',
    },
});

// Table Styles
export const StyledTable = styled(MatTable, { shouldForwardProp: (prop) => prop !== 'cellClicked' })<{ cellClicked?: any }>(({
    theme, cellClicked,
}) => ({
    position: 'relative',
    minWidth: 650,

    '& tbody': {
        borderTop: `solid 1px #e0e0e0`,
        background: theme.palette.background.paper,
    },

    '& .MuiTableCell-body': {
        fontWeight: Tokens.FontFamily.FontWeightDefault,
        fontFamily: Tokens.FontFamily.FontNormal,
        fontStyle: 'normal',
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForeground,
    },

    '& .tableHead': {
        background: theme.palette.semantic.colorBackgroundSecondary,
        color: theme.palette.semantic.colorForeground,
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,

        '& .MuiTableCell-head': {
            fontWeight: 600,
            padding: '0 16px',
            height: '52px',

            '&:hover': { background: theme.palette.semantic.colorHover },

            '&:focus': { background: theme.palette.semantic.colorHover },

        },

        '& .sortBlock': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '2em',

            '& svg': {
                fontSize: Tokens.FontFamily.FontHeader4Size,
                margin: '-6px',
            },
        },

        '& .disabled': { fill: 'gray' },

        '& .filter': {
            display: 'flex',
            paddingLeft: '4px',

            '& .MuiButton-text': { fontSize: Tokens.FontFamily.FontMSize },
        },
    },

    '& th, td': {
        fontSize: Tokens.FontFamily.FontSSize,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 200,
        whiteSpace: 'nowrap',
    },

    ...(cellClicked && { cursor: 'pointer' }),
}));

export const StyledTableSelectionContainer = styled('div')({
    display: 'flex',

    '& .selection-btn': {
        padding: '8px 16px',
        color: '#0067DF',
    },
});
