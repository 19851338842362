import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface CronDialogFooterProps {
    callback: () => void;
    closeDialog: () => void;
}

export const CronDialogFooter: React.FC<CronDialogFooterProps> = ({
    callback, closeDialog,
}) => {
    const { t } = useTranslation();
    return (<div className="buttonControls">
        <Button
            data-testid="submit-cron"
            aria-label={t('a11y_submit')}
            variant="contained"
            style={{ minWidth: '88px' }}
            onClick={callback}
            color="secondary">
            {t('label_cron_done_btn')}
        </Button>
        <Button
            variant="outlined"
            data-testid="cancel-cron"
            aria-label={t('a11y_cancel')}
            style={{
                marginLeft: '8px',
                minWidth: '88px',
            }}
            onClick={closeDialog}>
            {t('label_cron_cancel_btn')}
        </Button>
    </div>);
};
