import {
    Dialog,
    DialogContent,
    styled,
} from '@mui/material';
import { ApLoader } from '@uipath/portal-shell-react';
import React from 'react';

interface DialogProps {
    open: boolean;
    transparent?: boolean;
}

const FullPageLoaderContainer = styled(Dialog, { shouldForwardProp: (prop) => prop !== 'transparent' })<{ transparent?: boolean }>(({ transparent }) => ({
    '& .MuiPaper-root': {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
    },

    ...(transparent && {
        '& .MuiBackdrop-root': {
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
        },
    }),
}));

const FullPageLoader: React.FC<DialogProps> = ({
    open, transparent,
}) => (
    <FullPageLoaderContainer
        transparent={transparent}
        open={open}>
        <DialogContent>
            <ApLoader />
        </DialogContent>
    </FullPageLoaderContainer>
);

export default FullPageLoader;
